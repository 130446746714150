import {useEtherClient, useWalletAccount} from "@app/share/ethers";
import {makeLendOrderAgreement, makeRentOrderAgreement, makeSellOrderAgreement} from "@com/agreement";
import {Approve, SetApprovalForAll} from "@query/config";
import * as React from "react";

import {IOrderSigner} from "./Maker/Action";

export function useSellSigner(): IOrderSigner {
    const client = useEtherClient();
    const wallet = useWalletAccount();

    return React.useMemo(
        () => ({
            wallet,
            async sign(value) {
                const {item, payload} = value;
                const {address, blockchain} = wallet;
                const {blockchain: {setApprovalForAll: operator}} = await SetApprovalForAll.mutate({blockchain});
                const tx = await client.setApprovalForAll(address, operator, item.tokenAddress);
                const text = makeSellOrderAgreement(address, {
                    expire: payload.expire,
                    royalty: payload.royalty,
                    tokenAddress: item.tokenAddress,
                    tokenId: item.tokenId,
                });

                const sign = await client.sign(text);

                const hash = typeof tx === "object" ? tx.hash : void 0;

                return {agreement: {sign, text}, hash};
            },
        }),
        [wallet],
    );
}

export function useRentSigner(): IOrderSigner {
    const client = useEtherClient();
    const wallet = useWalletAccount();

    return React.useMemo(
        () => ({
            wallet,
            async sign(value) {
                const {item, payload} = value;
                const text = makeRentOrderAgreement(wallet.address, {
                    expire: payload.expire,
                    royalty: payload.royalty,
                    tokenAddress: item.tokenAddress,
                    tokenId: item.tokenId,
                });

                const sign = await client.sign(text);

                return {agreement: {sign, text}};
            },
        }),
        [wallet],
    );
}

export function useLendSigner(): IOrderSigner {
    const client = useEtherClient();
    const wallet = useWalletAccount();

    return React.useMemo(
        () => ({
            wallet,
            async sign(value) {
                const {address: maker, blockchain} = wallet;
                const {item, payload} = value;
                const {blockchain: {approve: operator}} = await Approve.mutate({blockchain});
                const tx = await client.approve(operator, item.tokenAddress, item.tokenId);
                const text = makeLendOrderAgreement(maker, {
                    expire: payload.expire,
                    royalty: payload.royalty,
                    tokenAddress: item.tokenAddress,
                    tokenId: item.tokenId,
                });

                const sign = await client.sign(text);
                const hash = typeof tx === "object" ? tx.hash : void 0;

                return {agreement: {sign, text}, hash};
            },
        }),
        [wallet],
    );
}
