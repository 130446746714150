import {NotFoundException} from "@app/com/errors";
import {interrupt} from "@app/com/fn";
import {GameNode} from "@query/config";
import * as React from "react";
import {useParams} from "react-router-dom";

import {Header} from "./com/Header";

export const Collection: React.FC = () => {
    const {uri} = useParams<"uri">();
    interrupt(uri, () => new NotFoundException("Unknown game ID"));

    const {game: {node: game}} = GameNode.query({uri});
    interrupt(game, () => new NotFoundException("Not found"));

    return (
        <div className="market-body">
            <Header data={game}/>
        </div>
    );
};
