import {useOperation} from "@app/com/endpoint";
import {useEtherClient, useWalletAccount} from "@app/share/ethers";
import {useNotify} from "@app/share/notify";
import {takeBuyOrderAgreement} from "@com/agreement";
import {useCatalogAtom} from "@com/catalog";
import {PopupTransaction} from "@com/popup";
import Btn from "@com/UI/Btn";
import {BuyOrder, SignForSale} from "@query/config";
import {ExecuteOrderInput, OrderExtendedFragment} from "@query/schema";
import {usePopUp} from "@state";
import * as React from "react";

export const BuyAction: React.FC<{order: OrderExtendedFragment}> = ({order}) => {
    const wallet = useWalletAccount();
    const {triggerWipe} = useCatalogAtom("order");
    const client = useEtherClient();
    const notify = useNotify();
    const transaction = usePopUp(PopupTransaction, {});
    const [state, {run}] = useOperation({
        start: () => transaction.open(true),
        error: (error) => transaction.update({error}),
        success: () => {
            notify("Purchase completed");
            transaction.close(true);
        },
    });

    const callback = React.useCallback(
        async (e: React.MouseEvent) => run(
            async () => {
                e.preventDefault();
                const text = takeBuyOrderAgreement(
                    wallet.address,
                    {
                        tokenId: order.token.tokenId,
                        tokenAddress: order.token.tokenAddress,
                        royalty: order.royalties[0].value,
                    },
                );

                const agreement = {text, sign: await client.sign(text)};
                const {order: {signForSell}} = await SignForSale.mutate({
                    id: order.id,
                    address: wallet.address,
                });

                const {hash: sign, nonce, storeAddress, itemId} = signForSell;
                const response = await client.purchase({
                    sign,
                    nonce,
                    itemId,
                    storeAddress,
                    seller: order.royalties[0].address,
                    tokenId: order.tokenId,
                    tokenAddress: order.tokenAddress,
                    price: order.royalties[0].value,
                });

                const payload: ExecuteOrderInput = {
                    hash: response.hash,
                    id: order.id,
                    royalties: [],
                    taker: {
                        address: wallet.address,
                        agreement,
                    },
                };

                await BuyOrder.mutate({payload});
                triggerWipe();
            },
        ),
        [order, wallet],
    );

    return (
        <>
            <div className="block">
                {order.royalties[0].value} ETH
            </div>
            <Btn
                loading={state.pending}
                disabled={!wallet.connected}
                onClick={callback}
            >
                Buy
            </Btn>
        </>
    );
};
