import {useContext} from "react";

import {SearchProviderContext} from "./SearchProvider";
import {SearchState} from "./SearchState";

export function useSearch<F extends Record<string, any>>(): SearchState<F> {
    const search = useSearchContext<F>();

    return search.watch();
}

export function useSearchContext<F extends Record<string, any>>(): SearchState<F> {
    return useContext(SearchProviderContext) as SearchState<F>;
}
