import "./Header.scss";

import {cssx} from "@app/com/css";
import {useAtomState, useScrollLock} from "@app/com/hooks";
import {Support, SupportAtom} from "@app/share/support";
import * as React from "react";
import {Link, Route, Routes} from "react-router-dom";

import {Wallet} from "../Wallet";
import {INavSelector} from "./Selector/interfaces";
import {NavSelector} from "./Selector/NavSelector";

const MarketSelectors: INavSelector[] = [
    {
        path: "/buy",
        title: "Buy / Sell",
        routes: ["/buy/*"],
    },
    {
        path: "/rent",
        title: "Rent / Lend",
        routes: ["/rent/*", "/lend/*"],
    },
];

const ProfileSelectors: INavSelector[] = [
    {
        path: "/profile/sell",
        title: "Buy / Sell",
        routes: ["/profile/sell/*"],
    },
    {
        path: "/profile/lend",
        title: "Rent / Lend",
        routes: ["/profile/rent/*", "/profile/lend/*"],
    },
];

const MyOrdersSelectors: INavSelector[] = [
    {
        path: "/history/buy",
        title: "Buy / Sell",
        routes: ["/history/buy/*", "/history/sell/*"],
    },
    {
        path: "/history/rent",
        title: "Rent / Lend",
        routes: ["/history/rent/*", "/history/lend/*"],
    },
];

export const Header: React.FC = () => {
    const [active, toggle, set] = useScrollLock();
    const showSupport = useAtomState(SupportAtom);

    const closeMenu = (e: any): void => {
        const target = e.target;
        if (!target.dataset.close) {
            set(false);
        }
    };

    return (
        <header className={cssx({active}, "header-market")} onClick={closeMenu}>
            <div className="container">
                <Link to={"/buy"} className="logo">
                    <img src="./assets/img/modules/header/logo.svg" alt="" />
                </Link>
                <div className="menu">
                    <Routes>
                        <Route
                            path="/history/:type/*"
                            element={<NavSelector selectors={MyOrdersSelectors}/>}/>
                        <Route
                            path="/profile/:type/*"
                            element={<NavSelector selectors={ProfileSelectors}/>}/>
                        <Route
                            path="/:type/*"
                            element={<NavSelector selectors={MarketSelectors}/>}
                        />
                    </Routes>

                    <div className="search-container">
                        {/* there is nothing to search throught this */}
                        {/* <Search
                            defaultValue={defaultValues.search || ""}
                            control={control}
                            name="search"
                        /> */}
                    </div>
                    <Wallet/>
                    {/* <Notifications/> */}
                </div>
                <div className="burger" onClick={(e) => {
                    e.stopPropagation();
                    toggle();
                }}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            {showSupport && <Support/>}
        </header>
    );
};
