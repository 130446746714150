import {Redirect} from "@app/com/route";
import {useWalletAccount} from "@app/share/ethers";
import * as React from "react";
import {Route, Routes} from "react-router-dom";

import {Games} from "./Market/Games";
import {Header} from "./Profile/Header";
import {Lend} from "./Profile/Lend";
import {Sell} from "./Profile/Sell";

export const Profile: React.FC = () => {
    const {address} = useWalletAccount();

    return (
        <main>
            <Header/>
            <Routes>
                <Route index element={<Redirect uri="sell"/>}/>
                <Route path="sell">
                    <Route index element={
                        <div className="container">
                            <Games address={address}/>
                        </div>
                    }/>
                    <Route path="all" element={<Sell/>}/>
                    <Route path=":uri/:address" element={<Sell/>}/>
                </Route>
                <Route path="lend">
                    <Route index element={
                        <div className="container">
                            <Games address={address}/>
                        </div>
                    }/>
                    <Route path="all" element={<Lend/>}/>
                    <Route path=":uri/:address" element={<Lend/>}/>
                </Route>
            </Routes>
        </main>
    );
};
