import {createAtom} from "@reatom/core";

type LoaderState = {
    loading: boolean;
    timeout?: any;
};

export const LoaderAtom = createAtom(
    {
        suspense: (): void => {
            const node = document.querySelector(".loader");
            if (!node) {
                return;
            }

            node.classList.remove("hide");
            node.classList.add("start");
        },
        finish: (): any | undefined => {
            const node = document.querySelector(".loader");
            if (!node) {
                return;
            }

            let timeout: any | undefined;

            return setTimeout(
                () => {
                    if (timeout) {
                        clearTimeout(timeout);
                    }

                    node.classList.add("end");

                    timeout = window.setTimeout(
                        () => {
                            node.classList.add("hide");
                            node.classList.remove("end");
                            node.classList.remove("start");
                        },
                        1000,
                    );
                },
                100,
            );
        },
    },
    ({onAction}, state: LoaderState = {loading: true}) => {
        onAction("suspense", () => {
            if (state.timeout) {
                clearTimeout(state.timeout);
            }

            state = {loading: true, timeout: void 0};
        });

        onAction("finish", (timeout) => {
            state = {loading: false, timeout};
        });

        return state;
    },
);
