import {useCallback} from "react";

export type Fn<A extends any[], R> = (...args: A) => R;

declare global {
    interface Window {
        dataLayer: any[];
    }
}

if (!window.dataLayer) {
    window.dataLayer = [];
}

export function dataLayerPush(...args: any[]): void {
    window.dataLayer.push(...args);
}

export function useDataLayerEvent<A extends any[], R>(name: string, fn: Fn<A, Promise<R>>): Fn<A, Promise<R>> {
    return useCallback(
        async (...args: A) => fn(...args)
            .then((result) => {
                window.dataLayer.push({
                    form_name: name,
                    event: "valid_form_send",
                });

                return result;
            }),
        [name, fn],
    );
}
