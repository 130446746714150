import {useOperation} from "@app/com/endpoint";
import {useAtomState} from "@app/com/hooks";
import {PopupTransaction} from "@com/popup";
import Btn from "@com/UI/Btn";
import {usePopUp} from "@state";
import * as React from "react";

import {IOrderSigner, useERC721OrderRequest} from "../Action";
import {useMakerActions} from "../hooks";
import {MakerAtomType} from "../interfaces";

type Props = {
    atom: MakerAtomType;
    signer: IOrderSigner;
};

export const MakerOrderRequest: React.FC<Props> = ({atom, signer}) => {
    const state = useAtomState(atom);
    const action = useMakerActions(atom);
    const transaction = usePopUp(PopupTransaction, {});
    const request = useERC721OrderRequest(signer);

    const [operation, {run}] = useOperation({
        finish: action.finish,
        error: (error) => transaction.update({error}),
        start: () => {
            action.start();
            transaction.open(true);
        },
        success: () => {
            transaction.close(true);
            action.wipe();
        },
    });

    const onClick = React.useCallback(
        () => run(async () => {
            for await (const processed of request(state.action, state.pending)) {
                action.commit(processed.item);
            }
        }),
        [],
    );

    return (
        <Btn onClick={onClick} loading={operation.pending}>{state.title}</Btn>
    );
};
