import {
    IconArrowBorrow,
    IconArrowDown,
    IconArrowLend,
    IconClose,
    IconCopy,
    IconFilter,
    IconPercent,
    IconSearch,
} from "./icons";

export const Assets = {
    IconCopy,
    IconPercent,
    IconArrowLend,
    IconArrowBorrow,
    IconSearch,
    IconArrowDown,
    IconClose,
    IconFilter,
};
