import "./MakerProgress.scss";

import {cssx} from "@app/com/css";
import * as React from "react";

import {useMakerState} from "./hooks";

export const MakerProgress: React.FC = () => {
    const {processing, processed, size} = useMakerState();

    return (
        <div className={cssx({active: processing}, "transaction-loader")}>
            <div className="content">
                <img className="one" src="/assets/img/loader/1.svg" alt=""/>
                <img className="two" src="/assets/img/loader/2.svg" alt=""/>
                <img className="three" src="/assets/img/loader/3.svg" alt=""/>
                <img className="four" src="/assets/img/loader/4.svg" alt=""/>
                <img className="five" src="/assets/img/loader/5.svg" alt=""/>
                <img className="six" src="/assets/img/loader/6.svg" alt=""/>
                <img className="seven" src="/assets/img/loader/7.svg" alt=""/>
            </div>
            <div className="count">
                {processed + 1}/{size}
            </div>
        </div>
    );
};
