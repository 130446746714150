import * as React from "react";

import {isDisabledComponent} from "../fn";

type Props = {
    children: React.ReactNode | React.ReactNode[];
    component: string;
    test?: unknown;
};

export const ActiveComponent: React.FC<Props> = ({children, component, ...p}) => {
    const pass = !Reflect.has(p, "test") || !!p.test;
    if (isDisabledComponent(component) || !pass) {
        return null;
    }

    return <>{children}</>;
};
