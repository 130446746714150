import {useMemo} from "react";

type Options = {
    srcSet?: string;
    fallback?: string;
};

const cache = new Map<string, string | undefined>();

export function useAsyncImageLoader(src?: string, options: Options = {}): string {
    const id = JSON.stringify({src, options});

    const pending = useMemo(
        () => {
            if (!src && options.fallback) {
                cache.set(id, options.fallback);

                return;
            }

            const image = new Image();

            return new Promise<void>((resolve) => {
                const load = (): void => {
                    cache.set(id, src);
                    image.removeEventListener("error", fallback);
                    requestAnimationFrame(() => resolve());
                };

                const fallback = (): void => {
                    cache.set(id, options.fallback ?? src);
                    image.removeEventListener("load", load);
                    requestAnimationFrame(() => resolve());
                };

                image.addEventListener("error", fallback, {once: true});
                image.addEventListener("load", load, {once: true});

                if (options.srcSet) {
                    image.setAttribute("srcSet", options.srcSet);
                }

                image.setAttribute("src", src ?? "");
            });
        },
        [id],
    );

    const exists = cache.get(id);
    if (!exists) {
        throw pending;
    }

    return exists;
}
