import {ElementProps} from "@app/com/interfaces";
import * as React from "react";

type Props = ElementProps<"div"> & {assertion: unknown};

function isFallbackElement(child: React.ReactNode): boolean {
    return React.isValidElement<{isFallbackElement?: boolean}>(child)
        && child.props.isFallbackElement === true;
}

export const ListLayout: React.FC<Props> = ({assertion, children, ...props}) => {
    const elements = React.Children.toArray(children);
    const Fallback = elements.find(isFallbackElement);
    const filtered = elements.filter((child) => child !== Fallback);

    if (assertion) {
        return (
            <div style={{height: "100%"}} {...props}>{filtered}</div>
        );
    }

    return <>{Fallback}</>;
};
