import "./CookieAccept.scss";

import {cssx} from "@app/com/css";
import {useAtomState} from "@app/com/hooks";
import {createAtom} from "@reatom/core";
import * as React from "react";

const defaultAccept = localStorage.getItem("accept") === "true";

const AcceptAtom = createAtom(
    {set: (value: boolean) => value},
    (track, state = defaultAccept) => {
        track.onAction("set", (value) => state = value);
        track.onAction("set", (value) => {
            localStorage.setItem("accept", JSON.stringify(value));
        });

        return state;
    },
);

export const CookieAccept: React.FC = () => {
    const state = useAtomState(AcceptAtom);
    const accept = React.useCallback(() => AcceptAtom.set.dispatch(!AcceptAtom.getState()), []);

    return (
        <div className={cssx({active: !state}, "cookie")}>
            <div className="cookie-text">
                <div className="cookie-text__head">This website uses cookies</div>
                <div className="cookie-text__descr">
                        We use cookies to improve user experience.
                        By using our website you consent to all cookies
                        in accordance with our <a href={`https://${DOMAIN}/policy`}>Cookie Policy</a>
                </div>
            </div>
            <div className="cookie-buttons">
                <button className="component-btn fill cookie__accept" onClick={accept}>Accept</button>
                {/* <button className="component-btn border cookie__decline">Decline</button> */}
            </div>
        </div>
    );
};
