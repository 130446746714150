import * as React from "react";

import {MakerFormContext} from "../Maker/context";
import {FormProps} from "../Maker/Forms/interfaces";

export const Form: React.FC<FormProps> = ({state}) => {
    const {component: Form} = React.useContext(MakerFormContext);

    return <Form state={state}/>;
};
