import {useEffect, useState} from "react";

type Data<T> = {
    offset: number;
    count: number;
    node: Readonly<T[]>;
};

export const PaginationLimit = 12;

export function usePaginatedData<Fragment>(
    data: Data<Fragment>,
    wipeDeps: Record<string, any>,
): Fragment[] {
    const [fragments, setFragment] = useState<Fragment[]>([]);

    useEffect(() => {
        setFragment([]);
    }, [JSON.stringify(wipeDeps)]);

    useEffect(() => {
        setFragment((prev) => [...prev, ...data.node]);
    }, [JSON.stringify(data)]);

    return fragments;
}
