import "./MakerCart.scss";

import {cssx} from "@app/com/css";
import {Image} from "@app/com/image";
import Btn from "@com/UI/Btn";
import {Images} from "@config";
import {usePopUp, usePopUpState} from "@state";
import * as React from "react";

import {IOrderSigner} from "./Action";
import {FormPopup} from "./Cart/FormPopup";
import {useMakerActions, useMakerAtom, useMakerState} from "./hooks";

type Props = {
    signer: IOrderSigner;
};

export const MakerCart: React.FC<Props> = ({signer}) => {
    const atom = useMakerAtom();
    const state = useMakerState();
    const action = useMakerActions();
    const popup = usePopUp(FormPopup, {atom, signer});
    const touchable = !usePopUpState();

    const scrollRef = React.useRef<HTMLDivElement>(null);

    const [activeSlide, setActiveSlide] = React.useState(0);

    const onScrollSlide = (index: number): void => {
        if (scrollRef?.current?.firstChild) {

            setActiveSlide(index);
            const {clientHeight} = scrollRef.current.firstChild as HTMLDivElement;
            const top = (clientHeight / 5.333 + clientHeight) * index;
            scrollRef.current.scrollTo({
                top,
                behavior: "smooth",
            });
        }
    };

    const scrollCart = (offset: number): void => {
        if (scrollRef?.current?.firstChild) {
            const {clientHeight} = scrollRef.current.firstChild as HTMLDivElement;
            const index = Math.round(offset / clientHeight);
            setActiveSlide(index);
        }
    };

    React.useEffect(() => onScrollSlide(state.dotsKeys.length - 1), [state.dotsKeys.length]);

    return (
        <div className={cssx({"empty": !state.size}, "orders-foot")}>
            <div className="container">
                <div className={cssx({slider: state.pending.length > 8}, "list-wrap")}>
                    <div ref={scrollRef} onScroll={(e) => scrollCart(e.currentTarget.scrollTop)} className="list">
                        {state.pending.map(({item}) => (
                            <div
                                key={item.id}
                                className="item">
                                <Image
                                    className="ic_nft"
                                    alt={item.name}
                                    file={item.image}
                                    size={Images.SM}/>
                                {touchable && <img
                                    className="ic_del"
                                    src="./assets/img/preview/ic_delete.png"
                                    onClick={() => action.remove(item)}
                                />}
                            </div>
                        ))}
                        {state.freeSlotKeys.map((fsk) => <div key={fsk} className="item"/>)}
                        <div className="dots">
                            {state.dotsKeys.map((dk, i) => (
                                <div
                                    onClick={() => onScrollSlide(i)}
                                    key={dk}
                                    className={cssx({active: activeSlide === i})}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                {state.removable && (
                    <button disabled={!touchable}
                        className="remove-btn com-btn border"
                        onClick={action.wipe}>
                        <span>Remove All</span>
                    </button>
                )}
                <div className="create">
                    <div className="count">
                        {!state.full ?
                            <strong>Selected</strong>
                            :
                            <strong className="red">MAX Selected</strong>
                        }

                        <span>{state.sizeRest}</span>
                    </div>
                    <Btn disabled={!touchable || !state.size}
                        onClick={() => popup.open()}
                        loading={state.processing}>
                        Next
                    </Btn>
                </div>
            </div>
            <div className="container mob">
                <div className="name_mob">
                    <b>Basket</b>
                    <span>{!state.full ? state.sizeRest : "MAX Selected"}  items</span>
                </div>
                <Btn
                    className="btn_mob"
                    disabled={!touchable || !state.size}
                    onClick={() => popup.open()}
                    loading={state.processing}>
                    Next
                </Btn>
            </div>
        </div>
    );
};
