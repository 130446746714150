import {SelectOption} from "@com/UI/Select";
import {
    MarketOrderStatus,
    MyOrderFilterInput,
    NftFilterInput,
    OrderFilterInput,
    OrderRequestAction,
    RoyaltyType,
} from "@query/schema";

export type FilterNFTFields = {
    [K in keyof NftFilterInput]?: NftFilterInput[K]
};
export type FilterOrderFields = {
    [K in keyof OrderFilterInput]?: OrderFilterInput[K]
};
export type FilterMyOrderFields = {
    [K in keyof MyOrderFilterInput]?: MyOrderFilterInput[K]
};

export const MyOrdersTabsMarket = [
    {
        path: "/history/buy",
        title: "Buy",
    },
    {
        path: "/history/sell",
        title: "Sell",
        re: /^\/history\/sell(\/?.*)/,
    },
];

export const MyOrdersTabsRental = [
    {
        path: "/history/rent",
        title: OrderRequestAction.Rent,
    },
    {
        path: "/history/lend",
        title: "Lend",
    },
];

export const DefaultFilterOption = {value: undefined, name: "All"};
export const DefaultSortOption = {value: "updatedAt", name: "Date"};
export const DefaultNFTFilter = {
    search: "",
    sort: DefaultSortOption.value,
    blockchain: DefaultFilterOption.value,
};
export const DefaultOrdersFilter = {
    search: "",
    sort: DefaultSortOption.value,
    blockchain: DefaultFilterOption.value,
    status: DefaultFilterOption.value,
    address: DefaultFilterOption.value,
    favoriteAddress: DefaultFilterOption.value,
    priceFrom: DefaultFilterOption.value,
    priceTo: DefaultFilterOption.value,
};
export const DefaultMyOrdersFilter: FilterMyOrderFields = {
    search: "",
    sort: DefaultSortOption.value,
    blockchain: DefaultFilterOption.value,
    status: DefaultFilterOption.value,
    type: DefaultFilterOption.value,
    action: DefaultFilterOption.value,
};

const MyOrdersTypeVars: SelectOption[] = [
    {value: RoyaltyType.Customer, name: "Rent"},
    {value: RoyaltyType.Agent, name: "As Guild"},
    {value: RoyaltyType.Owner, name: "Lend"},
];
const MyOrdersStatusVars: SelectOption[] = [
    {value: MarketOrderStatus.Created, name: "Created"},
    {value: MarketOrderStatus.Executing, name: "Active"},
    {value: MarketOrderStatus.Completed, name: "Completed"},
];
const MyOrdersCurrentStatusVars: SelectOption[] = [
    {value: MarketOrderStatus.Created, name: "Created"},
    {value: MarketOrderStatus.Executing, name: "Active"},
];
const OrderTypes: SelectOption[] = [
    {value: "favorite", name: "Favorites"},
    {value: "mynft", name: "For my NFTs"},
];
const OrdersSortVars: SelectOption[] = [
    {value: "profit", name: "Profit"},
];
const MyOrdersSortVars: SelectOption[] = [
    {value: "profit", name: "Profit"},
];
export const FILTER_OPTIONS = {
    orderType: [
        DefaultFilterOption,
        ...OrderTypes,
    ],
    myOrdersStatus: [
        DefaultFilterOption,
        ...MyOrdersStatusVars,
    ],
    myOrdersCurrentStatus: [
        DefaultFilterOption,
        ...MyOrdersCurrentStatusVars,
    ],
    myOrdersType: [
        DefaultFilterOption,
        ...MyOrdersTypeVars,
    ],
};
export const SORT_OPTIONS = {
    orders: [
        DefaultSortOption,
        ...OrdersSortVars,
    ],
    nfts: [
        DefaultSortOption,
        ...OrdersSortVars,
    ],
    myOrders: [
        DefaultSortOption,
        ...MyOrdersSortVars,
    ],
};
