import "./SkeletonGames.scss";

import {IconSkeletonImg} from "@app/share/icons";
import * as React from "react";

export const SkeletonGames: React.FC = () => {
    return (
        <div className="skeleton-item games skeleton">
            <div className="skeleton-item__img">
                <IconSkeletonImg/>
            </div>
            <div className="skeleton-item__info">
                <div className="skeleton_dark" />
                <div className="skeleton_dark" />
            </div>
        </div>
    );
};


