export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  DomainName: { input: any; output: any; }
  EmailAddress: { input: string; output: string; }
  IPv4Address: { input: any; output: any; }
  Object: { input: any; output: any; }
  Phone: { input: any; output: any; }
};

export enum BlockchainAssetType {
  Empty = 'EMPTY',
  Erc20 = 'ERC20',
  Erc721 = 'ERC721',
  Erc1155 = 'ERC1155',
  NativeToken = 'NATIVE_TOKEN'
}

export type BlockchainContractConfigType = {
  readonly contract: Scalars['String']['output'];
  readonly marketplace?: Maybe<BlockchainContractMarketplaceConfigType>;
  readonly network: Scalars['String']['output'];
  readonly symbol?: Maybe<Scalars['String']['output']>;
  readonly type: Scalars['String']['output'];
};

export type BlockchainContractMarketplaceConfigType = {
  readonly cover?: Maybe<FileType>;
  readonly enabled: Scalars['Boolean']['output'];
  readonly icon?: Maybe<FileType>;
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type BlockchainMutation = {
  readonly approve: Scalars['String']['output'];
  readonly setApprovalForAll: Scalars['String']['output'];
};


export type BlockchainMutationApproveArgs = {
  blockchain: Scalars['String']['input'];
};


export type BlockchainMutationSetApprovalForAllArgs = {
  blockchain: Scalars['String']['input'];
};

export type BlockchainQuery = {
  readonly supportedNetworks: ReadonlyArray<Scalars['String']['output']>;
};

export type BlockchainTokenConfigType = {
  readonly address: Scalars['String']['output'];
  readonly blockchain: Scalars['String']['output'];
  readonly symbol: Scalars['String']['output'];
};

export enum BlockchainType {
  Eos = 'EOS',
  Evm = 'EVM',
  Imx = 'IMX',
  Sol = 'SOL'
}

export type ConfigType = {
  readonly hub?: Maybe<CountersType>;
  readonly landing?: Maybe<CountersType>;
  readonly marketplace?: Maybe<CountersType>;
  readonly routes?: Maybe<ReadonlyArray<RouteMetadataType>>;
};

export enum ContactKind {
  Phone = 'phone',
  Telegram = 'telegram'
}

export type CounterConfigInput = {
  readonly code?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CounterConfigType = {
  readonly code?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['Boolean']['output']>;
};

export type CountersInput = {
  readonly analytics?: InputMaybe<CounterConfigInput>;
  readonly metrika?: InputMaybe<CounterConfigInput>;
};

export type CountersType = {
  readonly analytics?: Maybe<CounterConfigType>;
  readonly metrika?: Maybe<CounterConfigType>;
};

export type CreateOrderInput = {
  readonly action: OrderRequestAction;
  readonly blockchain: Scalars['String']['input'];
  readonly expire?: InputMaybe<Scalars['String']['input']>;
  readonly hash?: InputMaybe<Scalars['String']['input']>;
  readonly maker: OrderMakerInput;
  readonly tokenAddress: Scalars['String']['input'];
  readonly tokenId: Scalars['String']['input'];
};

export enum DataChartRange {
  Day = 'day',
  Month = 'month',
  Week = 'week'
}

export type DemoMutation = {
  readonly pourGeth: Scalars['Boolean']['output'];
};


export type DemoMutationPourGethArgs = {
  address: Scalars['String']['input'];
};

export type DemoQuery = {
  readonly sinkLimit: Scalars['Float']['output'];
};


export type DemoQuerySinkLimitArgs = {
  address: Scalars['String']['input'];
};

export enum Devices {
  Android = 'Android',
  Web = 'Web',
  Windows = 'Windows',
  IOs = 'iOS'
}

export enum EvmFlags {
  ProofOfStake = 'ProofOfStake',
  ProofOfWork = 'ProofOfWork'
}

export type EntityBlockchainConfigType = {
  readonly contracts?: Maybe<ReadonlyArray<BlockchainContractConfigType>>;
  readonly networks: ReadonlyArray<Scalars['String']['output']>;
  readonly token?: Maybe<BlockchainTokenConfigType>;
};

export type ExecuteOrderInput = {
  readonly hash: Scalars['String']['input'];
  readonly id: Scalars['ID']['input'];
  readonly royalties: ReadonlyArray<NftRoyaltyTargetInput>;
  readonly taker: OrderTakerInput;
};

export type FileInput = {
  readonly bucket: Scalars['String']['input'];
  readonly id: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly size: Scalars['Int']['input'];
  readonly type: Scalars['String']['input'];
};

export type FileType = {
  readonly bucket: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly size: Scalars['Int']['output'];
  readonly type: Scalars['String']['output'];
};

export type GameBaseType = {
  readonly blockchain?: Maybe<EntityBlockchainConfigType>;
  readonly brief?: Maybe<Scalars['String']['output']>;
  readonly cover?: Maybe<FileType>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly devices: ReadonlyArray<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly images?: Maybe<ReadonlyArray<FileType>>;
  readonly integrations?: Maybe<ReadonlyArray<Scalars['ID']['output']>>;
  readonly investment?: Maybe<GameInvestmentsType>;
  readonly isMetaverse?: Maybe<Scalars['Boolean']['output']>;
  readonly launchAt?: Maybe<Scalars['DateTime']['output']>;
  readonly likes?: Maybe<Scalars['Int']['output']>;
  readonly links?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly market?: Maybe<MarketConnectType>;
  readonly marketplace?: Maybe<GameMarketplaceType>;
  readonly metadata?: Maybe<PageMetadataType>;
  readonly onTop?: Maybe<Scalars['Boolean']['output']>;
  readonly status: Scalars['Boolean']['output'];
  readonly symbol?: Maybe<Scalars['String']['output']>;
  readonly tags?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly title: Scalars['String']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly uri: Scalars['String']['output'];
  readonly website?: Maybe<Scalars['String']['output']>;
  readonly whitepaper?: Maybe<Scalars['String']['output']>;
};

export type GameChartType = {
  readonly changes: Scalars['Float']['output'];
  readonly dataset: ReadonlyArray<Scalars['Float']['output']>;
  readonly type: Scalars['String']['output'];
  readonly value: Scalars['Float']['output'];
};

export type GameInvestmentsType = {
  readonly announced: Scalars['DateTime']['output'];
  readonly investors: ReadonlyArray<Scalars['ID']['output']>;
  readonly raised: Scalars['Int']['output'];
  readonly roundName: Scalars['String']['output'];
};

export type GameMarketplaceType = {
  readonly enabled: Scalars['Boolean']['output'];
  readonly image?: Maybe<FileType>;
};

export type GameQuery = {
  readonly list: ReadonlyArray<GameType>;
  readonly node?: Maybe<GameType>;
};


export type GameQueryListArgs = {
  nftHolderAddress?: InputMaybe<Scalars['String']['input']>;
};


export type GameQueryNodeArgs = {
  uri: Scalars['ID']['input'];
};

export type GameType = {
  readonly blockchain?: Maybe<EntityBlockchainConfigType>;
  readonly brief?: Maybe<Scalars['String']['output']>;
  readonly cover?: Maybe<FileType>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly devices: ReadonlyArray<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly images?: Maybe<ReadonlyArray<FileType>>;
  readonly integrations?: Maybe<ReadonlyArray<Scalars['ID']['output']>>;
  readonly integrationsSearch: ReadonlyArray<IntegrationType>;
  readonly investment?: Maybe<GameInvestmentsType>;
  readonly isMetaverse?: Maybe<Scalars['Boolean']['output']>;
  readonly launchAt?: Maybe<Scalars['DateTime']['output']>;
  readonly likes?: Maybe<Scalars['Int']['output']>;
  readonly links?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly market?: Maybe<MarketConnectType>;
  readonly marketplace?: Maybe<GameMarketplaceType>;
  readonly metadata?: Maybe<PageMetadataType>;
  readonly onTop?: Maybe<Scalars['Boolean']['output']>;
  readonly ordersCount: Scalars['Int']['output'];
  readonly status: Scalars['Boolean']['output'];
  readonly symbol?: Maybe<Scalars['String']['output']>;
  readonly tags?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly title: Scalars['String']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly uri: Scalars['String']['output'];
  readonly website?: Maybe<Scalars['String']['output']>;
  readonly whitepaper?: Maybe<Scalars['String']['output']>;
};


export type GameTypeOrdersCountArgs = {
  action: OrderRequestAction;
};

export type IPaginateNftOwnedType = {
  readonly count: Scalars['Int']['output'];
  readonly limit: Scalars['Int']['output'];
  readonly node: ReadonlyArray<NftOwnedType>;
  readonly offset: Scalars['Int']['output'];
};

export type IPaginateOrderExtendedType = {
  readonly count: Scalars['Int']['output'];
  readonly limit: Scalars['Int']['output'];
  readonly node: ReadonlyArray<OrderExtendedType>;
  readonly offset: Scalars['Int']['output'];
};

export type IPaginateOrderType = {
  readonly count: Scalars['Int']['output'];
  readonly limit: Scalars['Int']['output'];
  readonly node: ReadonlyArray<OrderType>;
  readonly offset: Scalars['Int']['output'];
};

export enum IntegrationProvider {
  Discord = 'discord',
  Telegram = 'telegram',
  Twitter = 'twitter'
}

export enum IntegrationSyncStatus {
  Error = 'error',
  Never = 'never',
  Pending = 'pending',
  Updated = 'updated'
}

export type IntegrationType = {
  readonly data?: Maybe<Scalars['Object']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly link: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly provider: IntegrationProvider;
};

export enum JobState {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Processing = 'processing'
}

export type MarketConnectInput = {
  readonly symbol?: InputMaybe<Scalars['String']['input']>;
};

export type MarketConnectType = {
  readonly symbol?: Maybe<Scalars['String']['output']>;
};

export enum MarketOrderStatus {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Executing = 'EXECUTING',
  Pending = 'PENDING'
}

export type Mutation = {
  readonly blockchain: BlockchainMutation;
  readonly demo: DemoMutation;
  readonly nft: NftMutation;
  readonly order: OrderMutation;
  readonly storage: StorageMutation;
  readonly wallet: WalletMutation;
};

export type MyOrderFilterInput = {
  readonly action?: InputMaybe<OrderRequestAction>;
  readonly address: Scalars['String']['input'];
  readonly blockchain?: InputMaybe<Scalars['String']['input']>;
  readonly search?: InputMaybe<Scalars['String']['input']>;
  readonly sort?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<MarketOrderStatus>;
  readonly type?: InputMaybe<RoyaltyType>;
};

export type NftFilterInput = {
  readonly blockchain?: InputMaybe<Scalars['String']['input']>;
  readonly owner?: InputMaybe<Scalars['String']['input']>;
  readonly search?: InputMaybe<Scalars['String']['input']>;
  readonly sort?: InputMaybe<Scalars['String']['input']>;
  readonly tokenAddress?: InputMaybe<Scalars['String']['input']>;
};

export type NftMintResultType = {
  readonly minterAddress: Scalars['String']['output'];
  readonly uri: Scalars['String']['output'];
};

export type NftMutation = {
  readonly addReward: Scalars['String']['output'];
  readonly mint: NftMintResultType;
  readonly return: Scalars['Boolean']['output'];
  readonly returnRequest: Scalars['String']['output'];
};


export type NftMutationAddRewardArgs = {
  id: Scalars['ID']['input'];
};


export type NftMutationMintArgs = {
  blockchain: Scalars['String']['input'];
};


export type NftMutationReturnArgs = {
  hash: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type NftMutationReturnRequestArgs = {
  id: Scalars['ID']['input'];
};

export type NftOwnedType = {
  readonly blockchain: Scalars['String']['output'];
  readonly game: GameBaseType;
  readonly id: Scalars['ID']['output'];
  readonly image: FileType;
  readonly metadata: Scalars['Object']['output'];
  readonly metadataURI: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly order?: Maybe<OrderType>;
  readonly owner: Scalars['String']['output'];
  readonly symbol: Scalars['String']['output'];
  readonly tokenAddress: Scalars['String']['output'];
  readonly tokenId: Scalars['String']['output'];
};

export type NftQuery = {
  readonly myCollection: IPaginateNftOwnedType;
};


export type NftQueryMyCollectionArgs = {
  filter?: InputMaybe<NftFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type NftRoyaltyTargetInput = {
  readonly address: Scalars['String']['input'];
  readonly type: RoyaltyType;
  readonly value: Scalars['Float']['input'];
};

export type NftRoyaltyTargetType = {
  readonly address: Scalars['String']['output'];
  readonly type: RoyaltyType;
  readonly value: Scalars['Float']['output'];
};

export type OrderAgreementInput = {
  readonly sign: Scalars['String']['input'];
  readonly text: Scalars['String']['input'];
};

export type OrderAgreementType = {
  readonly sign: Scalars['String']['output'];
  readonly text: Scalars['String']['output'];
};

export type OrderExtendedType = {
  readonly action: OrderRequestAction;
  readonly blockchain: Scalars['String']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly expireAt?: Maybe<Scalars['DateTime']['output']>;
  readonly favorites?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly game: GameBaseType;
  readonly id: Scalars['ID']['output'];
  readonly maker: OrderMakerType;
  readonly royalties: ReadonlyArray<NftRoyaltyTargetType>;
  readonly status: MarketOrderStatus;
  readonly taker?: Maybe<OrderTakerType>;
  readonly token: NftOwnedType;
  readonly tokenAddress: Scalars['String']['output'];
  readonly tokenId: Scalars['String']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly user: WalletType;
  readonly wnft?: Maybe<WnftType>;
};

export type OrderFilterInput = {
  readonly action?: InputMaybe<OrderRequestAction>;
  readonly address?: InputMaybe<Scalars['String']['input']>;
  readonly blockchain?: InputMaybe<Scalars['String']['input']>;
  readonly favoriteAddress?: InputMaybe<Scalars['String']['input']>;
  readonly holderAddress?: InputMaybe<Scalars['String']['input']>;
  readonly priceFrom?: InputMaybe<Scalars['Float']['input']>;
  readonly priceTo?: InputMaybe<Scalars['Float']['input']>;
  readonly search?: InputMaybe<Scalars['String']['input']>;
  readonly sort?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<MarketOrderStatus>;
  readonly tokenAddress?: InputMaybe<Scalars['String']['input']>;
};

export type OrderMakerInput = {
  readonly address: Scalars['String']['input'];
  readonly agreement: OrderAgreementInput;
  readonly royalty: Scalars['Float']['input'];
};

export type OrderMakerType = {
  readonly address: Scalars['String']['output'];
  readonly agreement?: Maybe<OrderAgreementType>;
  readonly royalty: Scalars['Float']['output'];
};

export type OrderMutation = {
  readonly buy: Scalars['Boolean']['output'];
  readonly create: Scalars['Boolean']['output'];
  readonly lend: Scalars['Boolean']['output'];
  readonly remove: Scalars['Boolean']['output'];
  readonly rent: Scalars['Boolean']['output'];
  readonly signForRent: SignForRentType;
  readonly signForSell: SignType;
  readonly turnFavorites: Scalars['Boolean']['output'];
};


export type OrderMutationBuyArgs = {
  payload: ExecuteOrderInput;
};


export type OrderMutationCreateArgs = {
  payload: CreateOrderInput;
};


export type OrderMutationLendArgs = {
  payload: ExecuteOrderInput;
};


export type OrderMutationRemoveArgs = {
  id: Scalars['ID']['input'];
};


export type OrderMutationRentArgs = {
  payload: ExecuteOrderInput;
};


export type OrderMutationSignForRentArgs = {
  id: Scalars['ID']['input'];
  royaltyTargets: ReadonlyArray<NftRoyaltyTargetInput>;
  sender: Scalars['String']['input'];
};


export type OrderMutationSignForSellArgs = {
  address: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type OrderMutationTurnFavoritesArgs = {
  address: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};

export type OrderQuery = {
  readonly mySearch: IPaginateOrderType;
  readonly node: OrderType;
  readonly search: IPaginateOrderExtendedType;
};


export type OrderQueryMySearchArgs = {
  filter: MyOrderFilterInput;
  pagination?: InputMaybe<PaginationInput>;
};


export type OrderQueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type OrderQuerySearchArgs = {
  filter: OrderFilterInput;
  pagination?: InputMaybe<PaginationInput>;
};

export enum OrderRequestAction {
  Lend = 'LEND',
  Rent = 'RENT',
  Sell = 'SELL'
}

export type OrderTakerInput = {
  readonly address: Scalars['String']['input'];
  readonly agreement: OrderAgreementInput;
};

export type OrderTakerType = {
  readonly address: Scalars['String']['output'];
  readonly agreement?: Maybe<OrderAgreementType>;
};

export type OrderType = {
  readonly action: OrderRequestAction;
  readonly blockchain: Scalars['String']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly expireAt?: Maybe<Scalars['DateTime']['output']>;
  readonly favorites?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly id: Scalars['ID']['output'];
  readonly maker: OrderMakerType;
  readonly royalties: ReadonlyArray<NftRoyaltyTargetType>;
  readonly status: MarketOrderStatus;
  readonly taker?: Maybe<OrderTakerType>;
  readonly token: NftOwnedType;
  readonly tokenAddress: Scalars['String']['output'];
  readonly tokenId: Scalars['String']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly wnft?: Maybe<WnftType>;
};

export type PageMetadataType = {
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly keywords?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type PaginateInput = {
  readonly limit?: InputMaybe<Scalars['Int']['input']>;
  readonly offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginationInput = {
  readonly limit?: InputMaybe<Scalars['Int']['input']>;
  readonly offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum PreSaleInvestmentStatus {
  Pending = 'pending',
  Signed = 'signed',
  Unsigned = 'unsigned'
}

export enum PreSaleJoinStatus {
  Confirmed = 'confirmed',
  Denied = 'denied',
  Pending = 'pending'
}

export type Query = {
  readonly blockchain: BlockchainQuery;
  readonly config: ConfigType;
  readonly demo: DemoQuery;
  readonly game: GameQuery;
  readonly nft: NftQuery;
  readonly order: OrderQuery;
  readonly ticket: QueueTicketType;
  readonly version: Scalars['String']['output'];
  readonly wallet: WalletQuery;
};

export type QueueTicketType = {
  readonly approved: Scalars['Boolean']['output'];
  readonly approvedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly id: Scalars['String']['output'];
  readonly no: Scalars['Float']['output'];
};

export type RouteMetadataType = {
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly host: Scalars['String']['output'];
  readonly keywords?: Maybe<Scalars['String']['output']>;
  readonly route: Scalars['String']['output'];
  readonly title?: Maybe<Scalars['String']['output']>;
};

export enum RoyaltyType {
  Agent = 'AGENT',
  Customer = 'CUSTOMER',
  Owner = 'OWNER',
  System = 'SYSTEM'
}

export type SignForRentType = {
  readonly executiveContractAddress: Scalars['String']['output'];
  readonly hash: Scalars['String']['output'];
  readonly nonce: Scalars['Float']['output'];
  readonly royalties: ReadonlyArray<NftRoyaltyTargetType>;
  readonly treasuryContractAddress: Scalars['String']['output'];
  readonly wNFT721ContractAddress: Scalars['String']['output'];
};

export type SignType = {
  readonly hash: Scalars['String']['output'];
  readonly itemId: Scalars['Float']['output'];
  readonly nonce: Scalars['Float']['output'];
  readonly storeAddress: Scalars['String']['output'];
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum StorageBucket {
  Covers = 'COVERS',
  Images = 'IMAGES',
  Kyc = 'KYC',
  Nft = 'NFT',
  Users = 'USERS',
  Wallet = 'WALLET'
}

export type StorageMutation = {
  readonly presign: ReadonlyArray<UploadCredentialsType>;
};


export type StorageMutationPresignArgs = {
  bucket: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

export enum TaskState {
  Disabled = 'DISABLED',
  Done = 'DONE',
  Ready = 'READY',
  Running = 'RUNNING',
  Suspense = 'SUSPENSE'
}

export enum TokenStandard {
  Eos = 'EOS',
  EosNft = 'EOS_NFT',
  Erc20 = 'ERC20',
  Erc721 = 'ERC721',
  Erc1155 = 'ERC1155',
  Solana = 'SOLANA'
}

export enum TrackerTabName {
  All = 'All',
  Guild = 'Guild',
  Top = 'Top'
}

export type UploadCredentialsType = {
  readonly bucket: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly url: Scalars['String']['output'];
};

export enum UserAccountType {
  Business = 'business',
  Developer = 'developer',
  Guild = 'guild',
  Influencer = 'influencer',
  Marketing = 'marketing',
  Project = 'project',
  Researcher = 'researcher',
  Scholar = 'scholar',
  User = 'user'
}

export enum UserFlags {
  Contacts = 'CONTACTS',
  Name = 'NAME',
  Survey = 'SURVEY',
  Type = 'TYPE',
  Verified = 'VERIFIED'
}

export enum UserPasswordUpdateType {
  Code = 'code',
  OldPassword = 'oldPassword'
}

export enum UserRole {
  Admin = 'admin'
}

export enum UserVerifyState {
  Pending = 'pending',
  Verified = 'verified'
}

export type WnftType = {
  readonly tokenAddress: Scalars['String']['output'];
  readonly tokenId: Scalars['String']['output'];
};

export enum WaitListNomineeType {
  Game = 'GAME',
  Guild = 'GUILD',
  Investor = 'INVESTOR',
  Scholar = 'SCHOLAR'
}

export type WalletInput = {
  readonly id: Scalars['ID']['input'];
  readonly name: Scalars['String']['input'];
};

export type WalletMutation = {
  readonly save: WalletType;
  readonly uploadPicture: WalletType;
};


export type WalletMutationSaveArgs = {
  payload: WalletInput;
};


export type WalletMutationUploadPictureArgs = {
  file: Scalars['Object']['input'];
  id: Scalars['ID']['input'];
};

export type WalletQuery = {
  readonly node: WalletType;
};


export type WalletQueryNodeArgs = {
  id: Scalars['ID']['input'];
};

export type WalletType = {
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly picture?: Maybe<FileType>;
};

export type CountersFragment = { readonly analytics?: { readonly code?: string | undefined, readonly status?: boolean | undefined } | undefined, readonly metrika?: { readonly code?: string | undefined, readonly status?: boolean | undefined } | undefined };

export type GameFragment = { readonly id: string, readonly uri: string, readonly title: string, readonly status: boolean, readonly website?: string | undefined, readonly launchAt?: any | undefined, readonly lendCount: number, readonly rentCount: number, readonly sellCount: number, readonly integrationsSearch: ReadonlyArray<{ readonly data?: any | undefined, readonly link: string, readonly provider: IntegrationProvider, readonly id: string }>, readonly blockchain?: { readonly networks: ReadonlyArray<string>, readonly contracts?: ReadonlyArray<{ readonly contract: string, readonly network: string, readonly symbol?: string | undefined, readonly type: string, readonly marketplace?: { readonly enabled: boolean, readonly name?: string | undefined, readonly cover?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined, readonly icon?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined } | undefined }> | undefined, readonly token?: { readonly address: string, readonly blockchain: string, readonly symbol: string } | undefined } | undefined, readonly marketplace?: { readonly enabled: boolean, readonly image?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined } | undefined, readonly cover?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined };

export type GameCollectionFragment = { readonly contract: string, readonly network: string, readonly symbol?: string | undefined, readonly type: string, readonly marketplace?: { readonly enabled: boolean, readonly name?: string | undefined, readonly cover?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined, readonly icon?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined } | undefined };

export type GameCardFragment = { readonly id: string, readonly uri: string, readonly title: string, readonly cover?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined, readonly marketplace?: { readonly enabled: boolean, readonly image?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined } | undefined, readonly blockchain?: { readonly contracts?: ReadonlyArray<{ readonly contract: string, readonly marketplace?: { readonly enabled: boolean, readonly name?: string | undefined, readonly cover?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined, readonly icon?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined } | undefined }> | undefined } | undefined };

export type NftWithOrderFragment = { readonly id: string, readonly name: string, readonly symbol: string, readonly tokenId: string, readonly tokenAddress: string, readonly metadata: any, readonly blockchain: string, readonly owner: string, readonly game: { readonly title: string, readonly cover?: { readonly id: string, readonly bucket: string, readonly name: string } | undefined }, readonly order?: { readonly id: string, readonly tokenAddress: string, readonly tokenId: string, readonly status: MarketOrderStatus, readonly wnft?: { readonly tokenAddress: string, readonly tokenId: string } | undefined } | undefined, readonly image: { readonly id: string, readonly bucket: string, readonly name: string, readonly size: number, readonly type: string } };

export type NftFragment = { readonly id: string, readonly name: string, readonly symbol: string, readonly tokenId: string, readonly tokenAddress: string, readonly metadata: any, readonly blockchain: string, readonly owner: string, readonly image: { readonly id: string, readonly bucket: string, readonly name: string, readonly size: number, readonly type: string } };

export type TokenOrderFragment = { readonly id: string, readonly tokenAddress: string, readonly tokenId: string, readonly status: MarketOrderStatus };

export type OrderFragment = { readonly id: string, readonly tokenAddress: string, readonly tokenId: string, readonly status: MarketOrderStatus, readonly action: OrderRequestAction, readonly blockchain: string, readonly favorites?: ReadonlyArray<string> | undefined, readonly expireAt?: any | undefined, readonly createdAt: any, readonly updatedAt: any, readonly maker: { readonly address: string, readonly royalty: number }, readonly taker?: { readonly address: string } | undefined, readonly token: { readonly id: string, readonly name: string, readonly symbol: string, readonly tokenId: string, readonly tokenAddress: string, readonly metadata: any, readonly blockchain: string, readonly owner: string, readonly image: { readonly id: string, readonly bucket: string, readonly name: string, readonly size: number, readonly type: string } }, readonly royalties: ReadonlyArray<{ readonly address: string, readonly value: number, readonly type: RoyaltyType }>, readonly wnft?: { readonly tokenAddress: string, readonly tokenId: string } | undefined };

export type OrderExtendedFragment = { readonly id: string, readonly tokenAddress: string, readonly tokenId: string, readonly status: MarketOrderStatus, readonly action: OrderRequestAction, readonly blockchain: string, readonly favorites?: ReadonlyArray<string> | undefined, readonly expireAt?: any | undefined, readonly createdAt: any, readonly updatedAt: any, readonly user: { readonly id: string, readonly name: string, readonly picture?: { readonly bucket: string, readonly id: string, readonly name: string, readonly size: number, readonly type: string } | undefined }, readonly game: { readonly title: string, readonly cover?: { readonly id: string, readonly bucket: string, readonly name: string } | undefined }, readonly token: { readonly id: string, readonly name: string, readonly symbol: string, readonly tokenId: string, readonly tokenAddress: string, readonly metadata: any, readonly blockchain: string, readonly owner: string, readonly image: { readonly id: string, readonly bucket: string, readonly name: string, readonly size: number, readonly type: string } }, readonly royalties: ReadonlyArray<{ readonly address: string, readonly value: number, readonly type: RoyaltyType }>, readonly wnft?: { readonly tokenAddress: string, readonly tokenId: string } | undefined };

export type WalletProfileFragment = { readonly id: string, readonly name: string, readonly picture?: { readonly bucket: string, readonly id: string, readonly name: string, readonly size: number, readonly type: string } | undefined };

export type SinkLimitQueryVariables = Exact<{
  address: Scalars['String']['input'];
}>;


export type SinkLimitQuery = { readonly demo: { readonly sinkLimit: number } };

export type PourGethMutationVariables = Exact<{
  address: Scalars['String']['input'];
}>;


export type PourGethMutation = { readonly demo: { readonly pourGeth: boolean } };

export type StoragePresignMutationVariables = Exact<{
  bucket: Scalars['String']['input'];
  size: Scalars['Int']['input'];
}>;


export type StoragePresignMutation = { readonly storage: { readonly presign: ReadonlyArray<{ readonly id: string, readonly bucket: string, readonly url: string }> } };

export type MintMutationVariables = Exact<{
  blockchain: Scalars['String']['input'];
}>;


export type MintMutation = { readonly nft: { readonly mint: { readonly minterAddress: string, readonly uri: string } } };

export type AppStateQueryVariables = Exact<{ [key: string]: never; }>;


export type AppStateQuery = { readonly blockchain: { readonly supportedNetworks: ReadonlyArray<string> }, readonly config: { readonly counters?: { readonly analytics?: { readonly code?: string | undefined, readonly status?: boolean | undefined } | undefined, readonly metrika?: { readonly code?: string | undefined, readonly status?: boolean | undefined } | undefined } | undefined }, readonly ticket: { readonly id: string, readonly no: number, readonly approved: boolean, readonly approvedAt?: any | undefined } };

export type GameNodeQueryVariables = Exact<{
  uri: Scalars['ID']['input'];
}>;


export type GameNodeQuery = { readonly game: { readonly node?: { readonly id: string, readonly uri: string, readonly title: string, readonly status: boolean, readonly website?: string | undefined, readonly launchAt?: any | undefined, readonly lendCount: number, readonly rentCount: number, readonly sellCount: number, readonly integrationsSearch: ReadonlyArray<{ readonly data?: any | undefined, readonly link: string, readonly provider: IntegrationProvider, readonly id: string }>, readonly blockchain?: { readonly networks: ReadonlyArray<string>, readonly contracts?: ReadonlyArray<{ readonly contract: string, readonly network: string, readonly symbol?: string | undefined, readonly type: string, readonly marketplace?: { readonly enabled: boolean, readonly name?: string | undefined, readonly cover?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined, readonly icon?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined } | undefined }> | undefined, readonly token?: { readonly address: string, readonly blockchain: string, readonly symbol: string } | undefined } | undefined, readonly marketplace?: { readonly enabled: boolean, readonly image?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined } | undefined, readonly cover?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined } | undefined } };

export type GamesQueryVariables = Exact<{
  nftHolderAddress?: InputMaybe<Scalars['String']['input']>;
}>;


export type GamesQuery = { readonly game: { readonly list: ReadonlyArray<{ readonly id: string, readonly uri: string, readonly title: string, readonly cover?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined, readonly marketplace?: { readonly enabled: boolean, readonly image?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined } | undefined, readonly blockchain?: { readonly contracts?: ReadonlyArray<{ readonly contract: string, readonly marketplace?: { readonly enabled: boolean, readonly name?: string | undefined, readonly cover?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined, readonly icon?: { readonly id: string, readonly bucket: string, readonly type: string, readonly size: number, readonly name: string } | undefined } | undefined }> | undefined } | undefined }> } };

export type AddRewardMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AddRewardMutation = { readonly nft: { readonly addReward: string } };

export type ApproveMutationVariables = Exact<{
  blockchain: Scalars['String']['input'];
}>;


export type ApproveMutation = { readonly blockchain: { readonly approve: string } };

export type MyCollectionQueryVariables = Exact<{
  filter?: InputMaybe<NftFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type MyCollectionQuery = { readonly nft: { readonly myCollection: { readonly offset: number, readonly count: number, readonly node: ReadonlyArray<{ readonly id: string, readonly name: string, readonly symbol: string, readonly tokenId: string, readonly tokenAddress: string, readonly metadata: any, readonly blockchain: string, readonly owner: string, readonly image: { readonly id: string, readonly bucket: string, readonly name: string, readonly size: number, readonly type: string }, readonly game: { readonly title: string, readonly cover?: { readonly id: string, readonly bucket: string, readonly name: string } | undefined }, readonly order?: { readonly id: string, readonly tokenAddress: string, readonly tokenId: string, readonly status: MarketOrderStatus, readonly wnft?: { readonly tokenAddress: string, readonly tokenId: string } | undefined } | undefined }> } } };

export type ReturnMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  hash: Scalars['String']['input'];
}>;


export type ReturnMutation = { readonly nft: { readonly return: boolean } };

export type ReturnRequestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ReturnRequestMutation = { readonly nft: { readonly returnRequest: string } };

export type SetApprovalForAllMutationVariables = Exact<{
  blockchain: Scalars['String']['input'];
}>;


export type SetApprovalForAllMutation = { readonly blockchain: { readonly setApprovalForAll: string } };

export type BuyMutationVariables = Exact<{
  payload: ExecuteOrderInput;
}>;


export type BuyMutation = { readonly order: { readonly buy: boolean } };

export type CreateOrderMutationVariables = Exact<{
  payload: CreateOrderInput;
}>;


export type CreateOrderMutation = { readonly order: { readonly create: boolean } };

export type LendMutationVariables = Exact<{
  payload: ExecuteOrderInput;
}>;


export type LendMutation = { readonly order: { readonly lend: boolean } };

export type MyOrderListQueryVariables = Exact<{
  filter: MyOrderFilterInput;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type MyOrderListQuery = { readonly order: { readonly mySearch: { readonly offset: number, readonly count: number, readonly node: ReadonlyArray<{ readonly id: string, readonly tokenAddress: string, readonly tokenId: string, readonly status: MarketOrderStatus, readonly action: OrderRequestAction, readonly blockchain: string, readonly favorites?: ReadonlyArray<string> | undefined, readonly expireAt?: any | undefined, readonly createdAt: any, readonly updatedAt: any, readonly maker: { readonly address: string, readonly royalty: number }, readonly taker?: { readonly address: string } | undefined, readonly token: { readonly id: string, readonly name: string, readonly symbol: string, readonly tokenId: string, readonly tokenAddress: string, readonly metadata: any, readonly blockchain: string, readonly owner: string, readonly image: { readonly id: string, readonly bucket: string, readonly name: string, readonly size: number, readonly type: string } }, readonly royalties: ReadonlyArray<{ readonly address: string, readonly value: number, readonly type: RoyaltyType }>, readonly wnft?: { readonly tokenAddress: string, readonly tokenId: string } | undefined }> } } };

export type OrderListQueryVariables = Exact<{
  filter: OrderFilterInput;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type OrderListQuery = { readonly order: { readonly search: { readonly offset: number, readonly count: number, readonly node: ReadonlyArray<{ readonly id: string, readonly tokenAddress: string, readonly tokenId: string, readonly status: MarketOrderStatus, readonly action: OrderRequestAction, readonly blockchain: string, readonly favorites?: ReadonlyArray<string> | undefined, readonly expireAt?: any | undefined, readonly createdAt: any, readonly updatedAt: any, readonly user: { readonly id: string, readonly name: string, readonly picture?: { readonly bucket: string, readonly id: string, readonly name: string, readonly size: number, readonly type: string } | undefined }, readonly game: { readonly title: string, readonly cover?: { readonly id: string, readonly bucket: string, readonly name: string } | undefined }, readonly token: { readonly id: string, readonly name: string, readonly symbol: string, readonly tokenId: string, readonly tokenAddress: string, readonly metadata: any, readonly blockchain: string, readonly owner: string, readonly image: { readonly id: string, readonly bucket: string, readonly name: string, readonly size: number, readonly type: string } }, readonly royalties: ReadonlyArray<{ readonly address: string, readonly value: number, readonly type: RoyaltyType }>, readonly wnft?: { readonly tokenAddress: string, readonly tokenId: string } | undefined }> } } };

export type OrderNodeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type OrderNodeQuery = { readonly order: { readonly node: { readonly id: string, readonly tokenAddress: string, readonly tokenId: string, readonly status: MarketOrderStatus, readonly action: OrderRequestAction, readonly blockchain: string, readonly favorites?: ReadonlyArray<string> | undefined, readonly expireAt?: any | undefined, readonly createdAt: any, readonly updatedAt: any, readonly maker: { readonly address: string, readonly royalty: number }, readonly taker?: { readonly address: string } | undefined, readonly token: { readonly id: string, readonly name: string, readonly symbol: string, readonly tokenId: string, readonly tokenAddress: string, readonly metadata: any, readonly blockchain: string, readonly owner: string, readonly image: { readonly id: string, readonly bucket: string, readonly name: string, readonly size: number, readonly type: string } }, readonly royalties: ReadonlyArray<{ readonly address: string, readonly value: number, readonly type: RoyaltyType }>, readonly wnft?: { readonly tokenAddress: string, readonly tokenId: string } | undefined } } };

export type OrderTurnFavoritesMutationVariables = Exact<{
  address: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
}>;


export type OrderTurnFavoritesMutation = { readonly order: { readonly turnFavorites: boolean } };

export type RemoveOrderMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RemoveOrderMutation = { readonly order: { readonly remove: boolean } };

export type RentMutationVariables = Exact<{
  payload: ExecuteOrderInput;
}>;


export type RentMutation = { readonly order: { readonly rent: boolean } };

export type SignForRentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  sender: Scalars['String']['input'];
  royaltyTargets: ReadonlyArray<NftRoyaltyTargetInput> | NftRoyaltyTargetInput;
}>;


export type SignForRentMutation = { readonly order: { readonly signForRent: { readonly hash: string, readonly nonce: number, readonly executiveContractAddress: string, readonly wNFT721ContractAddress: string, readonly treasuryContractAddress: string, readonly royalties: ReadonlyArray<{ readonly address: string, readonly value: number, readonly type: RoyaltyType }> } } };

export type SignForSaleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  address: Scalars['String']['input'];
}>;


export type SignForSaleMutation = { readonly order: { readonly signForSell: { readonly hash: string, readonly nonce: number, readonly storeAddress: string, readonly itemId: number } } };

export type UpdateWalletMutationVariables = Exact<{
  payload: WalletInput;
}>;


export type UpdateWalletMutation = { readonly wallet: { readonly save: { readonly id: string, readonly name: string, readonly picture?: { readonly bucket: string, readonly id: string, readonly name: string, readonly size: number, readonly type: string } | undefined } } };

export type UploadWalletPictureMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  file: Scalars['Object']['input'];
}>;


export type UploadWalletPictureMutation = { readonly wallet: { readonly uploadPicture: { readonly id: string, readonly name: string, readonly picture?: { readonly bucket: string, readonly id: string, readonly name: string, readonly size: number, readonly type: string } | undefined } } };

export type WalletNodeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type WalletNodeQuery = { readonly wallet: { readonly node: { readonly id: string, readonly name: string, readonly picture?: { readonly bucket: string, readonly id: string, readonly name: string, readonly size: number, readonly type: string } | undefined } } };
