import {OrderRequestAction} from "@query/schema";
import * as React from "react";
import {createContext} from "react";

import {BuyAction} from "../order/action/BuyAction";
import {MarketContextState} from "./interfaces";

export const MarketContext = createContext<MarketContextState>({
    render: (order) => React.createElement(BuyAction, {order}),
    action: OrderRequestAction.Sell,
});
