import {noop} from "@app/com/fn";
import {useAtomState} from "@app/com/hooks";
import {useCallback, useContext, useMemo} from "react";

import {MakerContext} from "./context";
import {MakerAtomType, MakerItem, MakerItemId, MakerItemPayload, MakerUseActions} from "./interfaces";
import {MakerVirtualState} from "./MakerVirtualState";

export function useMakerState(): MakerVirtualState {
    return useAtomState(useMakerAtom());
}

export function useMakerAtom(): MakerAtomType {
    return useContext(MakerContext);
}

export function useMakerActions(use?: MakerAtomType): MakerUseActions {
    const atom = use ?? useMakerAtom();

    const add = useCallback(
        (item: MakerItem, payload: MakerItemPayload) => noop(atom.add.dispatch(item, payload)),
        [atom],
    );

    const remove = useCallback((id: MakerItemId) => noop(atom.remove.dispatch(id)), [atom]);
    const commit = useCallback((id: MakerItemId) => noop(atom.commit.dispatch(id)), [atom]);
    const wipe = useCallback(() => noop(atom.wipe.dispatch()), [atom]);
    const start = useCallback(() => noop(atom.start.dispatch()), [atom]);
    const finish = useCallback(() => noop(atom.finish.dispatch()), [atom]);

    return useMemo(() => ({add, remove, commit, start, finish, wipe}), [atom]);
}
