import {ElementProps} from "@app/com/interfaces";
import {Assets} from "@com/assets";
import * as React from "react";

type Props = ElementProps<"input">;

export const SearchInput: React.FC<Props> = ({placeholder, ...props}) => {
    return (
        <div className="com-input">
            <input
                type={"text"}
                placeholder={placeholder ?? "Search"}
                {...props}
            />
            {Assets.IconSearch()}
        </div>
    );
};
