import {Image} from "@app/com/image";
import {Images} from "@config";
import {WalletProfileFragment} from "@query/schema";
import * as React from "react";

export const ProfileImage: React.FC<{wallet?: WalletProfileFragment}> = ({wallet}) => {
    if (wallet?.picture) {
        return <Image file={wallet.picture} alt={wallet.name} size={Images.SM}/>;
    }

    return (
        <svg viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
            {/* eslint-disable-next-line max-len */}
            <path fillRule="evenodd" clipRule="evenodd" d="M11.6714 129.283V11.6714H140.057V50.4836L101.949 98.098C100.979 99.3037 99.5693 100.074 98.0305 100.241C96.4918 100.407 94.95 99.9554 93.7443 98.985L71.3353 80.9061L70.9968 80.6376C69.1714 79.2397 67.0885 78.215 64.8671 77.6221C62.6456 77.0292 60.3291 76.8796 58.0499 77.182C55.7706 77.4844 53.5733 78.2327 51.5833 79.3844C49.5933 80.536 47.8496 82.0684 46.4519 83.8939L11.6714 129.283ZM11.6714 138.878V140.057H13.2106L11.6714 138.878ZM18.1202 140.057H140.057V69.1499L111.088 105.369L111.041 105.428C109.598 107.219 107.816 108.707 105.797 109.809C103.778 110.911 101.562 111.604 99.2746 111.849C96.9876 112.094 94.6747 111.886 92.4681 111.237C90.2616 110.588 88.2046 109.51 86.4147 108.065L64.0056 89.9981L63.8889 89.9047C62.6608 88.9673 61.1111 88.5551 59.5796 88.7586C58.048 88.9621 56.6597 89.7646 55.719 90.9901L18.1202 140.057ZM145.892 0H5.83569C4.28797 0 2.80364 0.61483 1.70923 1.70923C0.61483 2.80364 0 4.28797 0 5.83569V145.892C0 147.44 0.61483 148.924 1.70923 150.019C2.80364 151.113 4.28797 151.728 5.83569 151.728H145.892C147.44 151.728 148.924 151.113 150.019 150.019C151.113 148.924 151.728 147.44 151.728 145.892V5.83569C151.728 4.28797 151.113 2.80364 150.019 1.70923C148.924 0.61483 147.44 0 145.892 0ZM64.1924 46.6855C64.1924 38.9046 60.3019 35.0142 52.521 35.0142C44.7401 35.0142 40.8496 38.9046 40.8496 46.6855C40.8496 54.4665 44.7401 58.3569 52.521 58.3569C60.3019 58.3569 64.1924 54.4665 64.1924 46.6855Z" fill="#515ED1" fillOpacity="0.5"/>
        </svg>
    );
};
