import {
    AddRewardMutation,
    AddRewardMutationVariables,
    ApproveMutation,
    ApproveMutationVariables,
    BuyMutation,
    BuyMutationVariables,
    CreateOrderMutation,
    CreateOrderMutationVariables,
    GameNodeQuery,
    GameNodeQueryVariables,
    GamesQuery,
    GamesQueryVariables,
    LendMutation,
    LendMutationVariables,
    MyCollectionQuery,
    MyCollectionQueryVariables,
    MyOrderListQuery,
    MyOrderListQueryVariables,
    OrderListQuery,
    OrderListQueryVariables,
    OrderNodeQuery,
    OrderNodeQueryVariables,
    OrderTurnFavoritesMutation,
    OrderTurnFavoritesMutationVariables,
    RemoveOrderMutation,
    RemoveOrderMutationVariables,
    RentMutation,
    RentMutationVariables,
    ReturnMutation,
    ReturnMutationVariables,
    ReturnRequestMutation,
    ReturnRequestMutationVariables,
    SetApprovalForAllMutation,
    SetApprovalForAllMutationVariables,
    SignForRentMutation,
    SignForRentMutationVariables,
    SignForSaleMutation,
    SignForSaleMutationVariables,
    UpdateWalletMutation,
    UpdateWalletMutationVariables,
    UploadWalletPictureMutation,
    UploadWalletPictureMutationVariables,
    WalletNodeQuery,
    WalletNodeQueryVariables,
} from "@query/schema";

import GameNodeDoc from "./games/GameNode.graphql";
import GamesDoc from "./games/Games.graphql";
import {MPMutation} from "./MPMutation";
import {MPQuery} from "./MPQuery";
import AddRewardDoc from "./nft/AddReward.graphql";
import ApproveDoc from "./nft/Approve.graphql";
import MyCollectionDoc from "./nft/MyCollection.graphql";
import ReturnDoc from "./nft/Return.graphql";
import ReturnRequestDoc from "./nft/ReturnRequest.graphql";
import SetApprovalForAllDoc from "./nft/SetApprovalForAll.graphql";
import BuyDoc from "./orders/Buy.graphql";
import CreateOrderDoc from "./orders/CreateOrder.graphql";
import LendDoc from "./orders/Lend.graphql";
import MyOrderListDoc from "./orders/MyOrderList.graphql";
import OrderListDoc from "./orders/OrderList.graphql";
import OrderNodeDoc from "./orders/OrderNode.graphql";
import OrderTurnFavoritesDoc from "./orders/OrderTurnFavorites.graphql";
import RemoveOrderDoc from "./orders/RemoveOrder.graphql";
import RentDoc from "./orders/Rent.graphql";
import SignForRentDoc from "./orders/SignForRent.graphql";
import SignForSaleDoc from "./orders/SignForSale.graphql";
import UpdateWalletDoc from "./wallet/UpdateWallet.graphql";
import UploadWalletPictureDoc from "./wallet/UploadWalletPicture.graphql";
import WalletNodeDoc from "./wallet/WalletNode.graphql";

export const WalletNode = MPQuery.factory<WalletNodeQuery, WalletNodeQueryVariables>(
    WalletNodeDoc,
    {key: ({id}) => id},
);

export const UploadWalletPicture = MPMutation
    .factory<UploadWalletPictureMutation, UploadWalletPictureMutationVariables>(
    UploadWalletPictureDoc,
    {dependencies: [WalletNode]},
);

export const GamesList = MPQuery.factory<GamesQuery, GamesQueryVariables>(
    GamesDoc,
    {key: ({nftHolderAddress}) => nftHolderAddress ?? ""},
);

export const GameNode = MPQuery.factory<GameNodeQuery, GameNodeQueryVariables>(
    GameNodeDoc,
    {key: ({uri}) => uri},
);

export const MyCollection = MPQuery.factory<MyCollectionQuery, MyCollectionQueryVariables>(
    MyCollectionDoc,
    {key: ({filter, pagination}) => JSON.stringify({filter, pagination})},
)
    .as(({nft}) => nft.myCollection);

export const OrderList = MPQuery
    .factory<OrderListQuery, OrderListQueryVariables>(
    OrderListDoc,
    {key: (variables) => JSON.stringify(variables)},
)
    .as(({order}) => order.search);

export const MyOrderList = MPQuery.factory<MyOrderListQuery, MyOrderListQueryVariables>(
    MyOrderListDoc,
    {key: ({filter, pagination}) => JSON.stringify({filter, pagination})},
)
    .as(({order}) => order.mySearch);

export const OrderNode = MPQuery.factory<OrderNodeQuery, OrderNodeQueryVariables>(
    OrderNodeDoc,
    {key: ({id}) => id},
)
    .as(({order}) => order.node);

export const Approve = MPMutation.factory<ApproveMutation, ApproveMutationVariables>(ApproveDoc);

export const UpdateWallet = MPMutation.factory<UpdateWalletMutation, UpdateWalletMutationVariables>(
    UpdateWalletDoc,
    {dependencies: [WalletNode]},
);

export const SetApprovalForAll = MPMutation.factory<SetApprovalForAllMutation, SetApprovalForAllMutationVariables>(
    SetApprovalForAllDoc,
);

export const SignForRent = MPMutation.factory<SignForRentMutation, SignForRentMutationVariables>(
    SignForRentDoc,
    {dependencies: [MyOrderList, OrderList]},
);

export const SignForSale = MPMutation.factory<SignForSaleMutation, SignForSaleMutationVariables>(
    SignForSaleDoc,
    {dependencies: [MyOrderList, OrderList]},
);

export const CreateOrder = MPMutation.factory<CreateOrderMutation, CreateOrderMutationVariables>(
    CreateOrderDoc,
    {
        dependencies: [MyCollection, MyOrderList, OrderList],
    },
);

export const LendOrder = MPMutation.factory<LendMutation, LendMutationVariables>(
    LendDoc,
    {dependencies: [MyOrderList, OrderList]},
);

export const RentOrder = MPMutation.factory<RentMutation, RentMutationVariables>(
    RentDoc,
    {dependencies: [MyOrderList, OrderList]},
);

export const BuyOrder = MPMutation.factory<BuyMutation, BuyMutationVariables>(
    BuyDoc,
    {dependencies: [MyOrderList, OrderList]},
);

export const Return = MPMutation.factory<ReturnMutation, ReturnMutationVariables>(
    ReturnDoc,
    {
        dependencies: [MyCollection, MyOrderList, OrderList],
    },
);

export const ReturnRequest = MPMutation
    .factory<ReturnRequestMutation, ReturnRequestMutationVariables>(ReturnRequestDoc);

export const AddReward = MPMutation.factory<AddRewardMutation, AddRewardMutationVariables>(
    AddRewardDoc,
    {dependencies: [MyOrderList, OrderList]},
);

export const RemoveOrder = MPMutation.factory<RemoveOrderMutation, RemoveOrderMutationVariables>(
    RemoveOrderDoc,
    {dependencies: [MyOrderList, OrderList]},
);

export const OrderTurnFavorites = MPMutation
    .factory<OrderTurnFavoritesMutation, OrderTurnFavoritesMutationVariables>(
    OrderTurnFavoritesDoc,
    {dependencies: [MyOrderList, OrderList]},
);
