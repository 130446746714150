import {OrderRequestAction} from "@query/schema";
import {createAtom} from "@reatom/core";

import {MakerActions, MakerAtomType, MakerItem, MakerItemId, MakerItemPayload, MarketStoreMap} from "./interfaces";
import {MakerVirtualState} from "./MakerVirtualState";

const AtomCache = new Map<OrderRequestAction, MakerAtomType>();

export const MAX_CART_CAPACITY = 32;

const titles: Record<OrderRequestAction, string> = {
    [OrderRequestAction.Lend]: "Lend",
    [OrderRequestAction.Rent]: "Rent",
    [OrderRequestAction.Sell]: "Sell",
};

export default function factory(action: OrderRequestAction): MakerAtomType {
    const exists = AtomCache.get(action);
    if (exists) {
        return exists;
    }

    const store: MarketStoreMap = new Map();
    const defaults = Object.assign(new MakerVirtualState(action, store), {
        title: titles[action],
    });

    const atom = createAtom<MakerVirtualState, MakerActions>(
        {
            add: (item: MakerItem, payload: MakerItemPayload) => [item, payload],
            remove: (id: MakerItemId) => id,
            commit: (id: MakerItemId) => id,
            start: () => void 0,
            finish: () => void 0,
            wipe: () => void 0,
        },
        ({onAction}, state = defaults) => {
            onAction("start", () => state = state.clone({processing: true}));
            onAction("finish", () => state = state.clone({processing: false}));

            onAction("add", ([item, payload]) => {
                if (store.size < MAX_CART_CAPACITY) {
                    store.set(item.id, {item, payload});

                    return state = state.clone();
                }
            });

            onAction("remove", (id) => {
                store.delete(state.id(id));

                return state = state.clone();
            });

            onAction("commit", (id) => {
                const item = store.get(state.id(id));
                if (item) {
                    item.done = true;

                    return state = state.clone();
                }
            });

            onAction("wipe", () => {
                store.clear();

                return state = state.clone();
            });

            return state;
        },
    );

    AtomCache.set(action, atom);

    return atom;
}
