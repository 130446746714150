import {BlockchainNetworkList} from "@app/types";
import * as React from "react";

import {dataUrl} from "../image";

type Props = {
    network: string;
    symbol?: boolean;
    title?: boolean;
    icon?: boolean;
};

export const BlockchainNetworkIcon: React.FC<Props> = ({network, icon = true, title = false, symbol = false}) => {
    const current = BlockchainNetworkList.find((net) => net.id === network);

    if (current) {
        const chain = [];
        if (icon && current.icon) {
            chain.push(<><img src={dataUrl("image/svg+xml", current.icon)} alt={current.name}/>&nbsp;</>);
        }

        if (symbol) {
            chain.push(current.symbol);
        }

        if (title) {
            chain.push(current.name);
        }

        return (
            <>
                {chain.map((c, k) => <React.Fragment key={k}>{c}</React.Fragment>)}
            </>
        );
    }

    return null;
};
