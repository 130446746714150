import "./MarketItem.scss";

import * as React from "react";

type Props = {
    children: React.ReactNode;
    fallback?: React.ReactNode;
};

let timeout: number | undefined = undefined;
const pending = new Map();
const callback: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => pending.set(entry.target, entry));

    window.clearTimeout(timeout);
    timeout = window.setTimeout(
        () => {
            for (const entry of pending.values()) {
                if (entry.target instanceof HTMLDivElement) {
                    const opacity = entry.isIntersecting ? 1 : 0;
                    entry.target.style.opacity = opacity.toString();
                }
            }

            pending.clear();
        },
        100,
    );
};

const observer = new IntersectionObserver(callback, {});

export const MarketItem: React.FC<Props> = ({children, fallback}) => {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(
        () => {
            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            };
        },
        [],
    );

    return (
        <div className="market-item-wrap" ref={ref}>
            <div className="market-item">
                <React.Suspense fallback={fallback}>
                    {children}
                </React.Suspense>
            </div>
        </div>
    );
};
