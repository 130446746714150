import "./Header.scss";

import {BlockchainNetworkIcon} from "@app/com/blockchain";
import {cssx} from "@app/com/css";
import {DateFormat} from "@app/com/date";
import {NotFoundException} from "@app/com/errors";
import {interrupt} from "@app/com/fn";
import {NumberFormat} from "@app/com/number";
import {ExternalLink} from "@app/share/com/Links";
import {IconDiscord, IconPlay, IconTelegram, IconTwitter, IconWebSite} from "@app/share/icons";
import {CheckupImg} from "@com/checkup-image/CheckupImg";
import {GameFragment, IntegrationProvider, OrderRequestAction} from "@query/schema";
import * as React from "react";
import {useParams} from "react-router-dom";

import {useMarketContext} from "../context";

type Props = {
    data: GameFragment;
    mini?: boolean;
};

const icons: Record<IntegrationProvider, () => React.ReactNode> = {
    twitter: () => <IconTwitter/>,
    discord: () => <IconDiscord/>,
    telegram: () => <IconTelegram/>,
};

export const Header: React.FC<Props> = ({data, mini}) => {
    const {action} = useMarketContext();
    const {address} = useParams<"address">();
    const collection = (data.blockchain?.contracts?.filter((value) => value.marketplace?.enabled) ?? [])
        .find(({contract}) => contract === address);

    interrupt(collection, () => new NotFoundException("No collection found"));

    const counts: Record<OrderRequestAction, number> = {
        [OrderRequestAction.Lend]: data.lendCount,
        [OrderRequestAction.Rent]: data.rentCount,
        [OrderRequestAction.Sell]: data.sellCount,
    };

    const onClickBack = (e: React.MouseEvent): void => {
        e.preventDefault();
        history.back();
    };

    return (
        <div className={cssx({createorder: mini}, "header-game")}>
            {!mini &&
                <div className="thumb">
                    <CheckupImg file={collection.marketplace?.cover} size={1440}/>
                </div>
            }
            <div className="content">
                <div className="head">
                    <div className="logo">
                        <CheckupImg file={collection.marketplace?.icon}/>
                    </div>
                    <div className="name">
                        <a href="#" onClick={onClickBack}>
                            <svg className="arrow" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.24628 9L0 4.5L4.24628 0L5 0.79875L1.50743 4.5L5 8.20125L4.24628 9Z"/>
                            </svg>
                            Games
                        </a>
                        <b>{data.title}</b>
                    </div>
                    <ExternalLink
                        target="_blank"
                        address="https://hub.qubixinfinity.io/"
                        className="com-btn primary play__btn"
                        rel="noopener">
                        <span><IconPlay/> Play</span>
                    </ExternalLink>
                    <ul className="socials">
                        {data.website && <li>
                            <a href={data.website} target="_blank" rel="noopener"><IconWebSite/> Site</a>
                        </li>}
                        {data.integrationsSearch.map((item) => (
                            <li key={item.id}>
                                <a href={item.link} target="_blank" rel="noopener">
                                    {icons[item.provider]()}<NumberFormat
                                        value={item?.data?.count ?? 0}
                                        compact/></a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="wrap">
                    <ul className="stats">
                        {/* <li>Items<b><NumberFormat compact value={counts[action]}/></b></li> */}
                        <li>Created<b><DateFormat value={data.launchAt}/></b></li>
                        <li>Chain<b>
                            {data.blockchain?.networks.map((id) => (
                                <BlockchainNetworkIcon icon={false} title network={id} key={id}/>
                            ))}
                        </b></li>
                    </ul>
                    {/* <ul className="info">
                        <li><b>27K ETH</b>total volume</li>
                        <li><b>0.52 ETH</b>floor price</li>
                        <li><b>64%</b>listed</li>
                        <li><b>45 275</b>owners</li>
                        <li><b>16%</b>unique owners</li>
                    </ul> */}
                </div>
            </div>
        </div>
    );
};
