import "./Footer.scss";

import {cssx} from "@app/com/css";
import {SOCIALS} from "@app/share/social";
import * as React from "react";
import {Link} from "react-router-dom";

import {HeaderConfig, HostType, NavType} from "../Header";
import {Nav} from "../Header/Nav";
import {Links} from "../Links";
import {LinkMail} from "../Links/LinkMail";
import {ArrowDownIcon} from "../popup/Icons";
import {Banner} from "./Banner";
import {links} from "./config";

type Props = {
    type?: HostType;
    banner?: boolean;
};

const {getLogo, base} = HeaderConfig;
const navs: NavType[] = [
    {
        name: "Add Project",
        links: [
            {
                name: "Game",
                path: "https://forms.gle/XCxizWKMvr9cZT8bA",
            },
            {
                name: "Metaverses",
                path: "https://forms.gle/Emu53vMcJKfHzTYq5",
            },
            {
                name: "Guilds",
                path: "https://forms.gle/Ebt2psHn5xvvHbxB9",
            },
            {
                name: "Launchpads",
                path: "https://forms.gle/LTsRPcQkpWazYYQP9",
            },
            {
                name: "NFT Marketplaces",
                path: "https://forms.gle/bsdKa39yhACkwVdj8",
            },
        ],
    },
    {
        name: "Company",
        path: "/",
        links: [
            {
                name: "Main",
                path: "/",
            },
            {
                name: "Privacy policy",
                path: "/policy",
            },
            {
                name: "Terms",
                path: "/terms",
            },
            {
                name: "Careers",
                path: "/careers",
            },
        ],
    },
    {
        base: base("hub"),
        name: "UNITBOX Hub",
        path: "/",
        links: [
            {
                base: base("hub"),
                name: "Rankings",
                path: "/",
            },
            {
                base: base("hub"),
                name: "Academy",
                path: "/academy",
            },
            {
                base: base("hub"),
                name: "Profile",
                path: "/profile",
            },
        ],
    },
    {
        base: base("marketplace"),
        name: "Marketplace",
        path: "/",
        links: [
            {
                base: "marketplace.unitbox.io",
                name: "Rent",
                path: "/rent",
            },
            // {
            //     base: "marketplace.unitbox.io",
            //     name: "Lend",
            //     path: "/lend",
            // },
            {
                base: "marketplace.unitbox.io",
                name: "Buy / Sell",
                path: "/buy",
            },
            {
                base: "unitbox.gitbook.io",
                name: "Whitepaper",
                path: "/unitbox_whitepaper",
            },
        ],
    },
];

export const Footer: React.FC<Props> = (props) => {
    const {type, banner = false} = props;

    return (
        <footer className="footer action">
            <div className="footer__wrapper container">
                <div className="col">
                    <Link className="footer__logo" to={type === "marketplace" ? "/buy" : "/"}>
                        <img src={getLogo(type)} alt="UNITBOX" />
                    </Link>
                    <div className="footer__c">
                        © UNITBOX {new Date().getFullYear()} <br />
                        All rights reserved
                    </div>
                    <div className="socials">
                        <div className="label">Follow us:</div>
                        <Links links={links}/>
                    </div>
                    <LinkMail address={SOCIALS.mail.hello} className="footer__mail"/>
                </div>
                <nav>
                    {navs.map((group, index) => (
                        <NavList key={index} group={group}/>
                    ))}
                </nav>
            </div>
            {banner && <Banner />}
        </footer>
    );
};


type NavProps = {
    group: NavType;
};

const NavList: React.FC<NavProps> = ({group}) => {
    const [isOpenMenu, setOpenMenu] = React.useState(false);

    return (
        <div className={cssx({"active": isOpenMenu}, "list")}>
            <span
                onClick={() => setOpenMenu((state) => !state)}
                className="label"
            >
                {group.name}
                <ArrowDownIcon/>
            </span>
            <ul>
                {group.links?.map((nav, i) => (
                    <li key={`link_${i}`}>
                        <Nav nav={nav}/>
                    </li>
                ))}
            </ul>
        </div>
    );
};
