
import {Rent} from "@pages/Profile/Rent";
import {MarketOrderStatus, OrderExtendedFragment, OrderRequestAction} from "@query/schema";
import * as React from "react";
import {Route, Routes} from "react-router-dom";

import {Collection} from "./Collection";
import {CreateOrder} from "./com/CreateOrder";
import {MarketContext} from "./context/MarketContext";
import {Games} from "./Games";
import {Orders} from "./Orders";

type Props = {
    children: (data: OrderExtendedFragment) => React.ReactNode;
    action: OrderRequestAction;
    status?: MarketOrderStatus;
};

const {Provider} = MarketContext;

export const Market: React.FC<Props> = (props) => {
    const render = props.children;
    const {action, status = MarketOrderStatus.Created} = props;
    const value = React.useMemo(() => ({render, status, action}), [action]);

    return (
        <Provider value={value}>
            <div className="container">
                <Routes>
                    <Route path="request/*" element={null}/>
                    <Route path=":uri/:address" element={<Collection/>}/>
                </Routes>
                <Routes>
                    <Route index element={<Games/>}/>
                    <Route path="all" element={<Orders/>}/>
                    <Route path=":uri/:address" element={<Orders/>}/>
                    <Route path="request" element={<CreateOrder/>}>
                        <Route index element={<Games/>}/>
                        <Route path="all" element={<Rent/>}/>
                        <Route path=":uri/:address" element={<Rent/>}/>
                    </Route>
                </Routes>
            </div>
        </Provider>
    );
};
