import * as React from "react";

import {PayloadProps, RoyaltyInputProps} from "./interfaces";


type Props = PayloadProps & {
    form: React.FC<RoyaltyInputProps>;
};

export const FormControl: React.FC<Props> = ({payload, update, form}) => {
    const [[valid, rawValue], set] = React.useState([true, payload.royalty.toString()]);
    const onChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            set([e.target.reportValidity(), e.target.value]);
            if (e.target.validity.valid) {
                update(e.target.valueAsNumber);
            }
        },
        [],
    );

    const onBlur = React.useCallback(
        () => {
            if (!valid) {
                set(() => [true, payload.royalty.toString()]);
            }
        },
        [valid],
    );

    return React.createElement(form, {value: rawValue, onChange, onBlur});
};
