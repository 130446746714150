import "./NotFound.scss";

import * as React from "react";

import {dataLayerPush} from "../GTM";

export const NotFound: React.FC = () => {
    React.useEffect(
        () => dataLayerPush("event", "404_page"),
        [],
    );

    return (
        <div className="page-error">
            <div className="code">
                <b>404</b>
                <span>Oops</span>
            </div>
            <div className="title">Something went wrong</div>
            <div className="descr">Please try later</div>
            <a href="/" className="btn">Back To Homepage</a>
        </div>
    );
};

export default NotFound;
