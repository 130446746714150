import "./NotFound.scss";

import * as React from "react";

export const ErrorFound: React.FC = () => {
    return (
        <div className="page-error">
            <div className="title">Error</div>
            <div className="descr">Something went wrong</div>
            <a href={location.href} className="btn">Try to reload</a><br/><br/>
            or <a href="/">go back to homepage</a>
        </div>
    );
};

export default ErrorFound;
