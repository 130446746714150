import {ImageSizeProp} from "@app/com/image";

export const ENDPOINT = `https://api.${DOMAIN}`;
export const STORAGE_URL = `https://fs.${DOMAIN}`;

type ImageKey = "XSM" | "SM" | "MD" | "LG";

export const Images: Record<ImageKey, ImageSizeProp> = {
    LG: 500,
    MD: 360,
    SM: 160,
    XSM: 64,
};
