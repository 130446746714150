import "./index.scss";

import {OrderRequestAction} from "@query/schema";

import {MakerItemPayload} from "../interfaces";
import {FormFC} from "./interfaces";
import {Price} from "./Price";
import {LendProfit, RentProfit} from "./ProfitDistribution";

const forms: Record<OrderRequestAction, [FormFC, MakerItemPayload]> = {
    [OrderRequestAction.Rent]: [
        RentProfit,
        {
            expire: "1w",
            royalty: 60,
        },
    ],
    [OrderRequestAction.Lend]: [
        LendProfit,
        {
            expire: "1w",
            royalty: 60,
        },
    ],
    [OrderRequestAction.Sell]: [
        Price,
        {
            expire: "1m",
            royalty: 0.1,
        },
    ],
};

export default function factory(action: OrderRequestAction): [FormFC, MakerItemPayload] {
    return forms[action];
}
