import {MakeOrderAgreementArgs, TakeOrderAgreementArgs} from "./interfaces";

export const makeSellOrderAgreement = (maker: string, value: MakeOrderAgreementArgs): string =>
    `You agree to place sell order:
Your address: ${maker}
Token ID: ${value.tokenId}
Token address: ${value.tokenAddress}
Token Price: ${value.royalty}
Order expire in: ${value.expire}

Nonce ${Date.now()}
`;

export const makeBuyAgreement = (maker: string, value: MakeOrderAgreementArgs): string =>
    `You agree to buy:
Your address: ${maker}
Token ID: ${value.tokenId}
Token address: ${value.tokenAddress}
Token Price: ${value.royalty}
Order expire in: ${value.expire}

Nonce ${Date.now()}
`;

export const makeRentOrderAgreement = (maker: string, value: MakeOrderAgreementArgs): string =>
    `You agree to place rent order:
Your address: ${maker}
Token ID: ${value.tokenId}
Token address: ${value.tokenAddress}
Your profit: ${value.royalty}%
Holder profit: ${100 - value.royalty}%
Order expire in: ${value.expire}
System total fee: 15%

Nonce ${Date.now()}
`;

export const makeRentAgreement = (maker: string, value: MakeOrderAgreementArgs): string =>
    `You agree to rent:
Your address: ${maker}
Token ID: ${value.tokenId}
Token address: ${value.tokenAddress}
Your profit: ${value.royalty}%
Holder profit: ${100 - value.royalty}%
Order expire in: ${value.expire}
System total fee: 15%

Nonce ${Date.now()}
`;

export const makeLendOrderAgreement = (maker: string, value: MakeOrderAgreementArgs): string =>
    `You agree to place lend order:
Your address: ${maker}
Token ID: ${value.tokenId}
Token address: ${value.tokenAddress}
Your profit: ${value.royalty}%
Scholar profit: ${100 - value.royalty}%
Order expire in: ${value.expire}
System total fee: 15%

Nonce ${Date.now()}
`;

export const makeLendAgreement = (maker: string, value: MakeOrderAgreementArgs): string =>
    `You agree to lend:
Your address: ${maker}
Token ID: ${value.tokenId}
Token address: ${value.tokenAddress}
Your profit: ${value.royalty}%
Scholar profit: ${100 - value.royalty}%
Order expire in: ${value.expire}
System total fee: 15%

Nonce ${Date.now()}
`;

export const takeRentOrderAgreement = (maker: string, value: TakeOrderAgreementArgs): string =>
    `You agree to rent:
Your address: ${maker}
Token ID: ${value.tokenId}
Token address: ${value.tokenAddress}
Your profit: ${100 - value.royalty}%
Holder profit: ${value.royalty}%
System total fee: 15%

Nonce ${Date.now()}
`;

export const takeLendOrderAgreement = (maker: string, value: TakeOrderAgreementArgs): string =>
    `You agree to lend:
Your address: ${maker}
Token ID: ${value.tokenId}
Token address: ${value.tokenAddress}
Your profit: ${100 - value.royalty}%
Scholar profit: ${value.royalty}%
System total fee: 15%

Nonce ${Date.now()}
`;

export const takeBuyOrderAgreement = (maker: string, value: TakeOrderAgreementArgs): string =>
    `You agree to buy:
Your address: ${maker}
Token ID: ${value.tokenId}
Token address: ${value.tokenAddress}
Price: ${value.royalty} ETH

Nonce ${Date.now()}
`;
