import {cssm} from "@app/com/css";
import {CheckupImg} from "@com/checkup-image/CheckupImg";
import {GameCardFragment} from "@query/schema";
import * as React from "react";
import {Link} from "react-router-dom";


type Props = {
    data: GameCardFragment;
};

export const GameCard: React.FC<Props> = ({data}) => {
    const contracts = data.blockchain?.contracts
        ?.filter(({marketplace}) => marketplace?.enabled)
        .map((contract) => contract) ?? [];

    const address = contracts[0]?.contract;

    return (
        <Link to={`${data.uri}/${address}`} className="item item-game">
            <div className="item__cover">
                <CheckupImg file={data.marketplace?.image} size={400}/>
            </div>
            <div className="item__name">
                <div className="item__ava">
                    <CheckupImg file={data.cover}/>
                </div>
                <h5>{data.title}</h5>
            </div>
            {/* add class item__hover_one for item__hover if only one collection */}
            <div className={cssm(contracts.length === 1 ? "item__hover_one" : "", "item__hover")}>
                {contracts.map((item) => (
                    <div className="item__col" key={item.contract}>
                        <div className="item__thumb">
                            <CheckupImg file={item.marketplace?.icon}/>
                        </div>
                        <div className="item__info">
                            <CheckupImg file={item.marketplace?.cover} size={400}/>
                            <strong>{item.marketplace?.name}</strong>
                            <ul>
                                <li>
                                    <span>Rent</span>
                                    <b>746</b>
                                </li>
                                <li>
                                    <span>NFTs</span>
                                    <b>9780</b>
                                </li>
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </Link>
    );
};
