import {cssx} from "@app/com/css";
import {DropdownSelect} from "@com/UI/Select";
import * as React from "react";

import {MakerFormContext} from "../context";
import {FormProps} from "./interfaces";

const durationOptions = [
    {value: "1w", name: "1W"},
    {value: "2w", name: "2W"},
    {value: "1m", name: "1M"},
];

const validate = (value: any): boolean => (
    !isNaN(value) && value > 0.000001 && value < Number.MAX_SAFE_INTEGER && String(value).length < 9
);

const setValueAs = (value: any): number => isNaN(parseFloat(value)) ? 0 : parseFloat(value);

export const Price: React.FC<FormProps> = () => {
    const {form, locked} = React.useContext(MakerFormContext);
    const {register, getValues} = form;

    return (
        <>
            <div className="item">
                <span className="label">Price</span>
                <div className="com-input no_margins">
                    <input
                        className={cssx({locked: !locked})}
                        value={getValues("royalty")}
                        readOnly
                    />

                    <input
                        className={cssx({locked})}
                        {...register("royalty", {
                            required: true,
                            min: 0.000001,
                            max: Number.MAX_SAFE_INTEGER,
                            setValueAs,
                            validate,
                        })}
                        step={0.000001}
                        readOnly={locked}
                        type="number"
                    />

                    <span className="currency">ETH</span>
                </div>
            </div>
            <div className="item inactive">
                <span className="label">Duration</span>
                <DropdownSelect
                    disabled={locked}
                    options={durationOptions}
                    control={form.control}
                    name="expire"
                />
            </div>
        </>
    );
};
