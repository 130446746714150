import "./Overlay.scss";

import * as React from "react";
import {TransitionStatus} from "react-transition-group";

type Props = {
    overlayState: TransitionStatus;
    onClick: () => void;
};

export const Overlay: React.FC<Props> = ({overlayState, onClick}) => {
    return <div onClick={onClick} className={`com-overlay ${overlayState}`}/>;
};
