import {useSearchContext} from "@com/search";
import {SearchInput} from "@com/UI/Search";
import {OrderFilterInput} from "@query/schema";
import * as React from "react";

import {MarketFilter} from "./Bar/MarketFilter";

export const Bar: React.FC = () => {
    const search = useSearchContext<OrderFilterInput>();

    return (
        <div className="market-head">
            <div className="row">
                {false && <MarketFilter/>}
                <div className="input__wrap">
                    <SearchInput
                        placeholder={"NFT name or ID"}
                        {...search.useInput("search", (v) => v)}
                    />
                </div>
            </div>
        </div>
    );
};
