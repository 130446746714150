import {useContext} from "react";

import {MarketContextState} from "./interfaces";
import {MarketContext} from "./MarketContext";

export * from "./interfaces";
export * from "./MarketContext";

export function useMarketContext(): MarketContextState {
    return useContext(MarketContext);
}
