import "./Loader.scss";

import {effect} from "@app/com/fn";
import * as React from "react";

import {LoaderAtom} from "./LoaderAtom";

export const Loader: React.FC = () => {
    React.useLayoutEffect(
        effect(() => LoaderAtom.suspense.dispatch(), () => LoaderAtom.finish.dispatch()),
        [],
    );

    return null;
};

export default Loader;
