import "./WalletProfile.scss";

import {MultipartVariables} from "@app/com/query";
import {IconPhoto} from "@app/share/icons";
import {UploadWalletPicture} from "@query/config";
import {WalletProfileFragment} from "@query/schema";
import * as React from "react";

import {ProfileImage} from "./ProfileImage";

type Props = {
    wallet: WalletProfileFragment;
};

export const WalletProfile: React.FC<Props> = ({wallet}) => {
    const upload = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files?.length === 1) {
                const file = e.target.files.item(0);
                UploadWalletPicture.mutate(new MultipartVariables({id: wallet.id, file}));
            }
        },
        [],
    );

    return (
        <form>
            <ProfileImage wallet={wallet}/>
            <div className="hover">
                <IconPhoto/>
                <input
                    className="overlay-upload"
                    onChange={upload}
                    accept={".png,.jpg,.jpeg,.webp"}
                    type="file" />
            </div>
        </form>
    );
};
