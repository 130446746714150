import {Loader} from "@app/share/com/Loader";
import {useEtherState} from "@app/share/ethers";
import * as React from "react";
import {BrowserRouter} from "react-router-dom";

import Application from "./Application";
import {ErrorBoundary} from "./ErrorBoundary";

export const Bootstrap: React.FC = () => {
    useEtherState();

    return (
        <React.StrictMode>
            <React.Suspense fallback={<Loader/>}>
                <BrowserRouter>
                    <ErrorBoundary>
                        <Application/>
                    </ErrorBoundary>
                </BrowserRouter>
            </React.Suspense>
        </React.StrictMode>
    );
};
