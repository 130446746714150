import {Assets} from "@com/assets";
import * as React from "react";

import {FilterContext} from "./FilterArea";

export const FilterBtn: React.FC = () => {
    const state = React.useContext(FilterContext);

    return (
        <div onClick={state.toggle} className="com-filter_btn">
            {Assets.IconFilter()}
            <span>Filters</span>
        </div>
    );
};
