import {Approve, LendOrder, RentOrder, SignForRent} from "@query/config";
import {OrderRequestAction, RoyaltyType} from "@query/schema";

import {ApproveAssetType, RentLendRequest} from "./types";

export const approveAsset: ApproveAssetType = async (client, {tokenId, tokenAddress, blockchain}) => {
    const {blockchain: {approve}} = await Approve.mutate({blockchain});
    const approveResult = await client.approve(approve, tokenAddress, tokenId);

    if (typeof approveResult === "object") {
        await approveResult.wait();
    }
};

export async function executeOrderAction(request: RentLendRequest): Promise<void> {
    const {
        client,
        orderTokenAddress,
        orderTokenId,
        orderId,
        taker,
        takerAgreement,
        royaltyTargets,
        action,
    } = request;

    const {order: {signForRent}} = await SignForRent.mutate({
        id: orderId,
        sender: taker,
        royaltyTargets,
    });

    const {
        hash,
        nonce,
        royalties,
        executiveContractAddress,
        treasuryContractAddress,
        wNFT721ContractAddress,
    } = signForRent;

    const holderAddress = royalties.find((r) => r.type === RoyaltyType.Owner)?.address;
    if (!holderAddress) {
        throw new Error("Order execution failed");
    }

    const response = await client.borrow({
        executiveContractAddress,
        treasuryContractAddress,
        wNFT721ContractAddress,
        holder: holderAddress,
        tokenId: orderTokenId,
        signature: {hash, nonce},
        tokenAddress: orderTokenAddress,
        royalties: royalties.map(({address, value}) => ({address, value})),
    });

    const payload = {
        id: orderId,
        royalties: royaltyTargets,
        taker: {
            address: taker,
            agreement: takerAgreement,
        },
        hash: response.hash,
    };


    if (action === OrderRequestAction.Lend) {
        await RentOrder.mutate({payload});
    }

    if (action === OrderRequestAction.Rent) {
        await LendOrder.mutate({payload});
    }
}
