import {OrderRequestAction} from "@query/schema";
import * as React from "react";

import {useMarketContext} from "../../context";
import {BuyFilter} from "./BuyFilter";
import {LendFilter} from "./LendFilter";
import {RentFilter} from "./RentFilter";

export const MarketFilter: React.FC = () => {
    const {action} = useMarketContext();

    switch (action) {
        case OrderRequestAction.Lend:
            return <RentFilter/>;

        case OrderRequestAction.Rent:
            return <LendFilter/>;

        case OrderRequestAction.Sell:
            return <BuyFilter/>;
    }

    return null;
};
