import * as React from "react";

import {lang} from "../config";

export type FormatterProps = {
    round?: true | number | [min: number, max: number];
    compact?: boolean;
    style?: string;
    value?: number | string;
    na?: string | number;
    zeroAs?: string;
};

function useFormatter(props: FormatterProps): Intl.NumberFormat {
    const {round, compact, style} = props;

    return React.useMemo(
        () => {
            const config: Intl.NumberFormatOptions = {};
            if (props.round === true) {
                config.maximumFractionDigits = 0;
            }

            if (props.round && props.round !== true) {
                const [min, max] = Array.isArray(props.round)
                    ? props.round
                    : [props.round, props.round];

                config.minimumFractionDigits = min;
                config.maximumFractionDigits = max;
            }

            if (props.compact) {
                config.notation = "compact";
            }

            if (props.style) {
                config.style = props.style;
                config.currency = "usd";
            }

            return new Intl.NumberFormat(lang, config);
        },
        [lang, round, compact, style],
    );
}

export const Formatter: React.FC<FormatterProps> = (props) => {
    const formatter = useFormatter(props);
    const {value = 0, na = "N/A", zeroAs} = props;
    const parsed = +value;

    if (isNaN(parsed)) {
        return <>{na}</>;
    }

    if (zeroAs && parsed === 0) {
        return <>{zeroAs}</>;
    }

    return <>{formatter.format(parsed)}</>;
};
