import * as React from "react";

type Props = {
    size: number;
    skeleton: React.ReactNode;
    container?: React.ReactElement;
};

type PropsList = Props & {children: React.ReactNode};

export const SkeletonList: React.FC<PropsList> = ({children, ...props}) => {
    return (
        <React.Suspense fallback={<SkeletonListFallback {...props}/>}>
            {children}
        </React.Suspense>
    );
};

export const SkeletonListFallback: React.FC<Props> = ({size, skeleton, container = <React.Fragment/>}) => {
    const nodes = new Array(size).fill(skeleton).map((node, key) => (
        <React.Fragment key={key}>{node}</React.Fragment>
    ));

    return React.cloneElement(container, {children: nodes});
};
