import {MarketItem} from "@com/layout";
import {SkeletonNFTItem} from "@com/skeleton";
import {MyCollection} from "@query/config";
import {NftFilterInput, PaginateInput} from "@query/schema";
import * as React from "react";

import {useMakerState} from "./Maker/hooks";
import {MakerFormProvider} from "./Maker/MakerFormProvider";
import {Card} from "./NFT/Card";

type Props = {
    filter?: NftFilterInput;
    pagination: PaginateInput;
};

export const NFTSearchPage: React.FC<Props> = (variables) => {
    const state = useMakerState();
    const {node} = MyCollection.watch(variables);

    return (
        <>
            {node.map((nft) => (
                <MakerFormProvider
                    id={nft.id}
                    action={state.action}
                    locked={state.has(nft)}
                    done={state.get(nft)?.done}
                    key={nft.id}>
                    <MarketItem fallback={<SkeletonNFTItem/>}>
                        <Card nft={nft} />
                    </MarketItem>
                </MakerFormProvider>
            ))}
        </>
    );
};
