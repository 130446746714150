import {Dispatcher} from "@app/com/event";
import {ElementProps} from "@app/com/interfaces";
import {KeyOf} from "@app/types";
import {ChangeEvent, startTransition, useCallback, useEffect, useState} from "react";

import {ISearchPagination, ISearchState} from "./interfaces";

export class SearchState<F extends Record<string, any>> {
    readonly #state: ISearchState<F>;
    readonly #defaultState: ISearchState<F>;
    readonly #dispatcher = new Dispatcher<{state: []; filter: []}>();

    constructor(defaultState: ISearchState<F>) {
        this.#state = this.#clone(defaultState);
        this.#defaultState = defaultState;
    }

    public get filter(): F {
        return this.#state.filters;
    }

    public get pagination(): ISearchPagination {
        const {limit, offset} = this.#state;

        return {limit, offset};
    }

    public get page(): number {
        const {limit, offset} = this.pagination;

        return (offset + limit) / limit;
    }

    public get pages(): number[] {
        const {limit, offset} = this.pagination;

        return new Array((offset + limit) / limit)
            .fill(0)
            .map((_, index) => index + 1);
    }

    public get offsets(): number[] {
        const {limit, offset} = this.pagination;

        return new Array((offset + limit) / limit)
            .fill(0)
            .map((_, index) => index * limit);
    }

    public watch(): this {
        const [version, set] = useState(1);

        useEffect(() => this.#dispatcher.listen("state", () => set(version + 1)));

        return this;
    }

    public more = (): void => {
        this.#state.offset += this.#state.limit;
        this.#dispatcher.fire("state");
    };

    public reset = (): void => {
        Object.assign(this.#state, this.#clone(this.#defaultState));
        this.#dispatcher.fire("state");
        this.#dispatcher.fire("filter");
    };

    public set<K extends KeyOf<F>>(key: K, value: F[K]): void {
        this.#state.offset = 0;
        this.#state.filters[key] = value;
        this.#dispatcher.fire("state");
        this.#dispatcher.fire("filter");
    }

    public useInput<K extends KeyOf<F>>(key: K,
        transform: (value: string) => F[K],
        defaultValue?: string): ElementProps<"input"> {
        const [value, set] = useState(defaultValue ?? "");
        const onChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                const {value} = e.currentTarget;
                set(value);
                startTransition(() => {
                    set(value);
                    this.set(key, transform(value));
                });
            },
            [key],
        );

        return {
            value,
            onChange,
        };
    }

    #clone(state: ISearchState<F>): ISearchState<F> {
        const {filters, limit, offset} = state;

        return {limit, offset, filters: {...filters}};
    }
}
