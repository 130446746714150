import AppStateDoc from "@query/AppState.graphql";
import {MPQuery} from "@query/MPQuery";
import {AppStateQuery} from "@query/schema";

export const AppState = MPQuery.factory<AppStateQuery>(AppStateDoc)
    .as((data) => data);

export enum PATHS {
    BUY = "/buy",
    SELL = "/sell",
    RENT = "/rent",
    LEND = "/lend",
}
