import {NftFragment} from "@query/schema";
import * as React from "react";

type Props = {
    token: NftFragment;
};

export const TokenId: React.FC<Props> = ({token}) => (
    <>#{token.tokenId}</>
);
