import {OrderRequestAction} from "@query/schema";

import {PayloadProps, RoyaltyInputProps} from "./interfaces";
import {PricePayload} from "./PricePayload";
import {ProfitDistributionPayload} from "./ProfitDistributionPayload";

export const PayloadForms: Record<OrderRequestAction, React.FC<RoyaltyInputProps>> = {
    [OrderRequestAction.Sell]: PricePayload,
    [OrderRequestAction.Lend]: ProfitDistributionPayload,
    [OrderRequestAction.Rent]: ProfitDistributionPayload,
};

export {PayloadProps, PricePayload, ProfitDistributionPayload};
