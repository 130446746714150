import {useAtomState} from "@app/com/hooks";
import {DefaultMyOrdersFilter, DefaultNFTFilter, DefaultOrdersFilter} from "@com/bar";
import {createAtom} from "@reatom/core";
import {useCallback, useEffect} from "react";
import {useLocation} from "react-router-dom";

import {CatalogAtomHook, CatalogAtomState, CatalogType} from "./config";

const CatalogAtomDefaultState: CatalogAtomState = {
    order: {
        filter: DefaultOrdersFilter,
        offset: 0,
        wipeCount: 0,
    },
    myOrder: {
        filter: DefaultMyOrdersFilter,
        offset: 0,
        wipeCount: 0,
    },
    NFT: {
        filter: DefaultNFTFilter,
        offset: 0,
        wipeCount: 0,
    },
};

const CatalogAtom = createAtom(
    {
        pickFilter: (filter: CatalogAtomState[CatalogType]["filter"], type: CatalogType) => ({filter, type}),
        pickOffset: (offset: CatalogAtomState[CatalogType]["offset"], type: CatalogType) => ({offset, type}),
        triggerWipe: (type: CatalogType) => type,
        wipeAll: () => void 0,
    },
    ({onAction}, state = CatalogAtomDefaultState) => {
        onAction("pickFilter", ({filter, type}) => {
            state = {
                ...state,
                [type]: {
                    ...state[type],
                    filter,
                    offset: 0,
                },
            };
        });
        onAction("pickOffset", ({offset, type}) => {
            state = {
                ...state,
                [type]: {
                    ...state[type],
                    offset,
                },
            };
        });
        onAction("triggerWipe", (type) => {
            state = {
                ...state,
                [type]: {
                    ...state[type],
                    wipeCount: (state[type].wipeCount || 0) + 1,
                    offset: 0,
                },
            };
        });
        onAction("wipeAll", () => {
            state = CatalogAtomDefaultState;
        });

        return state;
    },
);

export const useCatalogAtom: CatalogAtomHook = (type) => {
    const state = useAtomState(CatalogAtom);

    const pickFilter = useCallback((filter: CatalogAtomState[CatalogType]["filter"]) => {
        CatalogAtom.pickFilter.dispatch(filter, type);
    }, []);
    const pickOffset = useCallback((offset: CatalogAtomState[CatalogType]["offset"]) => {
        CatalogAtom.pickOffset.dispatch(offset, type);
    }, []);
    const triggerWipe = useCallback(() => {
        CatalogAtom.triggerWipe.dispatch(type);
    }, []);

    return {
        ...state[type],
        pickFilter,
        pickOffset,
        triggerWipe,
    };
};

export const useCatalogLayoutEffect = (): void => {
    const {pathname} = useLocation();

    useEffect(() => {
        CatalogAtom.wipeAll.dispatch();
    }, [pathname]);
};
