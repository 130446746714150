import {Filter} from "@com/bar/filter";
import {BlockchainFilter} from "@com/bar/filter/BlockchainFilter";
import {useSearch} from "@com/search";
import {InputRange} from "@com/UI/InputRange";
import {SelectValue} from "@com/UI/Select";
import {OrderFilterInput} from "@query/schema";
import * as React from "react";

function range(v: SelectValue): number | undefined {
    if (typeof v !== "undefined") {
        return isNaN(+v) ? undefined : +v;
    }

    return v;
}

export const BuyFilter: React.FC = () => {
    const search = useSearch<OrderFilterInput>();
    const {filter} = search.watch();

    return (
        <Filter>
            {!filter.tokenAddress && <div className="item">
                <BlockchainFilter
                    value={filter.blockchain}
                    onChange={(v) => search.set("blockchain", v)}
                />
            </div>}
            <div className="item">
                <span className="label">
                    <img src="./assets/img/components/filter/price-icon.svg" alt="Price range" />
                        Price range
                </span>
                <InputRange
                    from={search.useInput("priceFrom", range)}
                    to={search.useInput("priceTo", range)}/>
            </div>
        </Filter>
    );
};
