import {Redirect} from "@app/com/route";
import {GoogleTagManager} from "@app/share/com/GoogleTagManager";
import NotFound from "@app/share/com/NotFound";
import {Buy} from "@pages/Buy";
import {History} from "@pages/History";
import {Lend} from "@pages/Lend";
import {Profile} from "@pages/Profile";
import {Queue, QueueUpdate} from "@pages/Queue/Queue";
import {Rent} from "@pages/Rent";
import {SwitchNetwork} from "@pages/SwitchNetwork/SwitchNetwork";
import {MarketConfigContext} from "@state";
import * as React from "react";
import {Route, Routes} from "react-router-dom";

import {AppState, PATHS} from "./config";
import {Layout} from "./Layout";

const {Provider} = MarketConfigContext;

const Application: React.FC = () => {
    const data = AppState.query();

    if (!data.ticket.approved) {
        return <Queue no={data.ticket.no}/>;
    }

    return (
        <Provider value={data.blockchain}>
            <QueueUpdate/>
            <GoogleTagManager counter={data.config.counters?.analytics}/>
            <Routes>
                <Route path="/" element={<Redirect uri={PATHS.BUY} replace/>}/>
                <Route path="*" element={<Layout/>}>
                    <Route path="profile/*" element={<Profile/>} />
                    <Route path="history/*" element={<History/>} />
                    {/* <Route path="/create-order/*" element={<CreateOrder/>} /> */}
                    {/* <Route path="order/:id" element={<OrderPage/>} /> */}
                    {/* <Route path="dashboard" element={<Dashboard/>} /> */}
                    <Route path="buy/*" element={<Buy/>} />
                    <Route path="lend/*" element={<Lend/>} />
                    <Route path="rent/*" element={<Rent/>} />
                    <Route path="switch" element={<SwitchNetwork/>} />
                    <Route path="*" element={<NotFound/>}/>
                </Route>
            </Routes>
        </Provider>
    );
};

export default Application;
