import "@scss/index.scss";
import "../../share/com/Loader.scss"; // @todo

import * as React from "react";
import {createRoot} from "react-dom/client";

import {Bootstrap} from "./app/Bootstrap";

const container = document.getElementById("app");
if (container) {
    const root = createRoot(container);
    root.render(<Bootstrap/>);
}
