import "../../com/catalog/com/Catalog.scss";

import {SkeletonGames, SkeletonList} from "@com/skeleton";
import * as React from "react";
import {Link} from "react-router-dom";

import {GameList} from "./GameList";

type Props = {address?: string};

export const Games: React.FC<Props> = ({address}) => {
    return (
        <div className="catalog">
            <div className="head">
                Choose a game:
            </div>
            <div className="catalog-list">
                <Link to="all" className="item item__all">
                    All Games
                </Link>

                <SkeletonList size={3} skeleton={<SkeletonGames/>}>
                    <GameList address={address}/>
                </SkeletonList>
            </div>
        </div>
    );
};
