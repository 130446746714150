import {when} from "@app/com/fn";
import {useAtomState} from "@app/com/hooks";
import {createAtom} from "@reatom/core";
import * as React from "react";

type PopupState = {
    node?: React.ReactNode;
    component?: React.FC<any>;
    props?: any;
    active: boolean;
    timeout: number;
    type?: "simple";
    locked?: true;
};

export const PopupTimeout = 500;

export const PopUpAtom = createAtom(
    {
        show: (component: React.FC<any>, props?: any, locked?: true, type?: "simple") => ({
            component,
            props,
            locked,
            type,
        }),
        fade: (unlock?: true) => unlock,
        unlock: () => void 0,
        lock: () => void 0,
        rm: () => void 0,
    },
    ({onAction, schedule, create}, state: PopupState = {active: false, timeout: PopupTimeout}) => {
        onAction("show", (options) => {
            if (state.node && !state.active) {
                return state; // removing node is in process
            }

            const node = React.createElement(options.component, options.props);
            state = {...state, ...options, node, active: true};
            schedule(() => disableScroll());
        });

        onAction("lock", () => {
            if (state.active) {
                state = {...state, locked: true};
            }
        });

        onAction("unlock", () => {
            if (state.locked) {
                state = {...state, locked: undefined};
            }
        });

        onAction("rm", () => state = {...state, node: undefined});

        onAction("fade", (unlock) => {
            if (state.locked && !unlock) {
                return;
            }

            state = {...state, locked: undefined, active: false};
            schedule((dispatch) => {
                setTimeout(
                    () => {
                        enableScroll();
                        dispatch(create("rm"));
                    },
                    state.timeout,
                );
            });
        });

        return state;
    },
);

function disableScroll(): void {
    document.body.setAttribute("scroll", "no");
    document.body.style.overflow = "hidden";
}

function enableScroll(): void {
    document.body.removeAttribute("scroll");
    document.body.style.overflow = "";
}

export type PopUpFlow = {
    close(unlock?: true | React.MouseEvent<any>): void;
    lock(): void;
    unlock(): void;
};

type PopUpControl<P> = PopUpFlow & {
    update(props: P): void;
    open(locked?: true): void;
    toggle(): void;
};

export function usePopUp<T extends React.FunctionComponent<P>, P extends Record<string, any>>(
    component: T,
    props: P,
    type?: "simple",
): PopUpControl<P> {
    const flow = usePopUpFlow();

    const open = React.useCallback(
        (locked?: true) => {
            PopUpAtom.show.dispatch(component, props, locked, type);
        },
        [component],
    );

    const update = React.useCallback(
        (props: P) => {
            const state = PopUpAtom.getState();
            when(
                state.active && state.component === component,
                () => PopUpAtom.show.dispatch(component, props),
            );
        },
        [component],
    );

    const toggle = React.useCallback(
        () => {
            const state = PopUpAtom.getState();
            when(state.active && state.component === component, () => close());
            when(!state.active && state.component === component, () => open());
        },
        [],
    );

    return {
        open,
        update,
        toggle,
        ...flow,
    };
}

export function usePopUpFlow(unlockDefault?: true): PopUpFlow {
    const close = React.useCallback(
        (unlock: undefined | true | React.MouseEvent<any> = unlockDefault) => {
            const {active} = PopUpAtom.getState();
            when(active, () => PopUpAtom.fade.dispatch(unlock === true ? true : undefined));
        },
        [],
    );

    const lock = React.useCallback(
        () => PopUpAtom.lock.dispatch(),
        [],
    );

    const unlock = React.useCallback(
        () => PopUpAtom.unlock.dispatch(),
        [],
    );

    return {
        close,
        lock,
        unlock,
    };
}

export function usePopUpState(): boolean {
    return useAtomState(PopUpAtom).active;
}
