import {cssx} from "@app/com/css";
import {useClickOutside, useToggleState} from "@app/com/hooks";
import {Assets} from "@com/assets";
import * as React from "react";

import {SelectOption, SelectValue} from "./interfaces";

export type SelectProps2 = {
    onChange: (value: SelectValue) => void;
    options: SelectOption[];
    value?: SelectValue;
    disabled?: boolean;
    mini?: boolean;
    pre?: string;
};

export const DropdownSelect2: React.FC<SelectProps2> = (props) => {
    const ref = useClickOutside<HTMLDivElement>(() => setDrop(false));

    const [drop, toggle, setDrop] = useToggleState();
    const {options, pre, mini, disabled, value, onChange} = props;
    const label = options.find((option) => option.value === value)?.name;

    return (
        <div className={cssx({mini}, "com-select")}>
            <div className={cssx({active: drop}, "wrap")} ref={ref}>
                <span onClick={() => !disabled && setDrop(!drop)}>
                    {pre ? `${pre} ` : ""}{label}
                    {Assets.IconArrowDown()}
                </span>
                <ul>
                    {options.map((option, key) => (
                        <li
                            key={key}
                            className={cssx({active: option.value === value})}
                            onClick={() => {
                                onChange(option.value);
                                toggle();
                            }}
                        >
                            {option.name}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
