import {IconPercent} from "@com/assets/icons";
import * as React from "react";

import {RoyaltyInputProps} from "./interfaces";

export const ProfitDistributionPayload: React.FC<RoyaltyInputProps> = (props) => {
    return (
        <div className="profit">
            <span>Your profit</span>
            <b>
                <input
                    type="number"
                    min={10}
                    max={90}
                    step={1}
                    required
                    {...props}
                />
                <IconPercent/>
            </b>
        </div>
    );
};
