import * as React from "react";

import {RoyaltyInputProps} from "./interfaces";

export const PricePayload: React.FC<RoyaltyInputProps> = (props) => {
    return (
        <div className="profit">
            <span>Price</span>
            <b>
                <input
                    type="number"
                    step={0.000001}
                    min={0.000001}
                    required
                    {...props}
                />

                ETH
            </b>

        </div>
    );
};
