import "./GoerliHead.scss";

import {cssx} from "@app/com/css";
import {useOperation} from "@app/com/endpoint";
import {usePreventFn} from "@app/com/hooks";
import {ExternalLink} from "@app/share/com/Links";
import {useWalletAccount} from "@app/share/ethers";
import {IconClose, IconGoerli} from "@app/share/icons";
import {MPMutation} from "@query/MPMutation";
import {MPQuery} from "@query/MPQuery";
import {PourGethMutation, PourGethMutationVariables, SinkLimitQuery, SinkLimitQueryVariables} from "@query/schema";
import {gql} from "graphql-tag";
import * as React from "react";

const links = [
    {
        href: "https://goerlifaucet.com/",
        text: "goerlifaucet",
        primary: true,
    },
    {
        href: "https://faucet.paradigm.xyz/",
        text: "faucet.paradigm",
    },
    {
        href: "https://goerli-faucet.pk910.de/",
        text: "goerli-faucet",
    },
    {
        href: "https://faucet.quicknode.com/ethereum/goerli",
        text: "faucet.quicknode",
    },
    {
        href: "https://www.coinbase.com/how-to-buy/goerli-eth",
        text: "coinbase",
    },
    {
        href: "https://www.binance.com/en/how-to-buy/goerli-eth",
        text: "binance",
    },
    {
        href: "https://testnetbridge.com/",
        text: "testnetbridge",
    },
];

const SinkLimit = MPQuery.factory<SinkLimitQuery, SinkLimitQueryVariables>(gql`
query SinkLimit ($address: String!) {
    demo {
        sinkLimit(address: $address)
    }
}
`);

const PourGeth = MPMutation.factory<PourGethMutation, PourGethMutationVariables>(gql`
mutation PourGeth ($address: String!) {
    demo {
        pourGeth(address: $address)
    }
}
`);

export const GoerliHead: React.FC = () => {
    const {address, chainId} = useWalletAccount();
    const [isOpen, setIsOpen] = React.useState(false);
    const [locked, setLocked] = React.useState(false);
    const {demo: {sinkLimit}} = SinkLimit.query({address});
    const [, {run}] = useOperation({});
    const sendGeth = usePreventFn(
        () => run(async () => {
            if (sinkLimit > 0) {
                setLocked(true);
                await PourGeth.mutate({address});
            }
        }),
        [],
    );

    return (
        <>
            {chainId === 5 && (
                <div className="goerli-head">
                    <IconGoerli/>
                    You are in the testnet
                    <div
                        onClick={() => setIsOpen(true)}
                        className="button">
                        Get Goerli
                    </div>
                </div>
            )}
            {isOpen && (
                <>
                    <div className="goerli-overlay" onClick={() => setIsOpen(false)}/>
                    <div className="goerli-modal">
                        <div
                            onClick={() => setIsOpen(false)}
                            className="close">
                            <IconClose/>
                        </div>
                        <div className="title">Get Goerli</div>
                        <div className="ico">
                            <IconGoerli/>
                        </div>
                        <div className="descr">Resources to get:</div>
                        <div className="links">
                            {links.map(({href, text, primary}) => (
                                <ExternalLink
                                    key={href}
                                    address={href}
                                    target="_blank"
                                    className={cssx({"primary": primary})}>
                                    {text}
                                </ExternalLink>
                            ))}

                            <a
                                onClick={sendGeth}
                                href="#"
                                target="_blank"
                                className={cssx({primary: !locked && sinkLimit > 0})}>
                                {sinkLimit > 0 && !locked
                                    ? <>Get {sinkLimit}GETH</>
                                    : (locked ? <>Sent</> : <>Daily limited</>)
                                }
                            </a>
                        </div>
                    </div>
                </>
            )}

        </>
    );
};
