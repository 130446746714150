import {DropdownSelect2} from "@com/UI/Select";
import {SelectOption, SelectValue} from "@com/UI/Select";
import {useMarketConfig} from "hooks";
import * as React from "react";

type Props = {
    onChange: (value: string | undefined) => void;
    value: string | undefined;
};

export const BlockchainFilter: React.FC<Props> = ({onChange, value}) => {
    const {supportedNetworks} = useMarketConfig();
    const BlockchainOptions: SelectOption[] = [
        {value: undefined, name: "All"},
        ...supportedNetworks.map((n) => ({value: n, name: n})),
    ];

    const update = React.useCallback(
        (value: SelectValue) => onChange(typeof value === "number" ? value.toString() : value),
        [],
    );

    return (
        <div className="item">
            <span className="label">
                <img src="./assets/img/components/filter/blockchain-icon.svg" alt="Blockchain list" />
                Blockchains
            </span>
            <DropdownSelect2
                value={value}
                pre="Blockchain:"
                options={BlockchainOptions}
                onChange={update}
            />
        </div>
    );
};
