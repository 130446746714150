import "./SwitchNetwork.scss";

import {usePreventFn} from "@app/com/hooks";
import {useEtherClient, useWalletAccount} from "@app/share/ethers";
import Btn from "@com/UI/Btn";
import {switchChain} from "app/Layout/Wallet/fn";
import * as React from "react";

const m = new Map([
    ["bnb", "BNB Smart Chain"],
    ["homestead", "Ethereum"],
]);

export const SwitchNetwork: React.FC = () => {
    const client = useEtherClient();
    const wallet = useWalletAccount();
    const onSwitchNetwork = usePreventFn(() => switchChain(client));

    const chain = m.get(wallet.blockchain) ?? wallet.blockchain.toUpperCase();

    return (
        <main className="network-page">
            <div className="content">
                <div className="message">
                Collections will appear on the {chain} soon,
                you can test our product on the Goerli network.
                </div>
                <Btn onClick={onSwitchNetwork}>Switch network</Btn>
            </div>
        </main>
    );
};
