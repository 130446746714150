import {OrderRequestAction} from "@query/schema";
import * as React from "react";
import {useForm} from "react-hook-form";

import {MakerContext, MakerFormContext} from "./context";
import factory from "./Forms";
import {MakerItemPayload} from "./interfaces";

const {Provider} = MakerFormContext;

type Props = {
    id: string;
    action: OrderRequestAction;
    children: React.ReactNode;
    locked?: boolean;
    done?: boolean;
};

export const MakerFormProvider: React.FC<Props> = (props) => {
    const {id, action, children, done, locked} = props;

    const [component, defaultValues] = factory(action);
    const atom = React.useContext(MakerContext);
    const form = useForm<MakerItemPayload>({
        defaultValues,
        shouldUseNativeValidation: false,
        mode: "all",
    });

    const context = React.useMemo(() => ({component, form, locked}), [locked, form.formState]);

    React.useEffect(
        () => atom.subscribe((state) => {
            const payload = state.get(id)?.payload;
            if (payload) {
                const prevState = form.getValues();
                if (prevState.expire !== payload.expire) {
                    form.setValue("expire", payload.expire);
                }

                if (prevState.royalty !== payload.royalty) {
                    form.setValue("royalty", payload.royalty);
                }
            }
        }),
        [id],
    );

    if (done) {
        return null;
    }

    return <Provider value={context}>{children}</Provider>;
};
