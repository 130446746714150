import {useWalletAccount} from "@app/share/ethers";
import Btn from "@com/UI/Btn";
import {NftWithOrderFragment} from "@query/schema";
import * as React from "react";

import {MakerFormContext} from "./context";
import {useMakerActions, useMakerState} from "./hooks";

type Props = {
    nft: NftWithOrderFragment;
};

export const MakerAction: React.FC<Props> = ({nft}) => {
    const {full} = useMakerState();
    const {connected} = useWalletAccount();
    const {form, locked} = React.useContext(MakerFormContext);
    const {add, remove} = useMakerActions();
    const toggle = React.useCallback(
        () => {
            if (locked) {
                remove(nft);
            } else {
                add(nft, form.getValues());
            }
        },
        [locked, nft.id],
    );

    return (
        <Btn
            onClick={toggle}
            disabled={!connected || !form.formState.isValid || (!locked && full)}
            style={locked ? "secondary" : "primary"}
        >
            {locked ? "Remove" : "Add"}
        </Btn>
    );
};
