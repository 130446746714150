import {OrderFragment} from "@query/schema";
import * as React from "react";

import {OrderAction, OrderHead, OrderInfo} from ".";

type Props = {
    data: OrderFragment;
};

const Card: React.FC<Props> = ({data}) => {
    return (
        <div className="order-item-wrap">
            <div className="order-item">
                <OrderHead data={data}/>
                <OrderInfo data={data}/>
                <OrderAction data={data}/>
            </div>
        </div>
    );
};

export default Card;
