import * as React from "react";
import {useLocation} from "react-router-dom";

import {INavSelector} from "./interfaces";
import {SelectorLink} from "./SelectorLink";

type Props = {
    selectors: INavSelector[];
};

export const NavSelector: React.FC<Props> = ({selectors}) => {
    const location = useLocation();

    return (
        <div className="com-radio">
            {selectors.map((selector) => (
                <div key={selector.path} className="item">
                    <SelectorLink location={location} selector={selector}/>
                </div>
            ))}
        </div>
    );
};
