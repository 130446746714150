import * as React from "react";

import {Formatter} from "./Formatter";

type Props = {
    value?: number | string;
};

export const TokenPriceFormat: React.FC<Props> = ({value = 0}) => {
    return <Formatter value={value} zeroAs="N/A" round={[2, 10]} style="currency"/>;
};
