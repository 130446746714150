import {Image} from "@app/com/image";
import {Images} from "@config";
import {WalletProfileFragment} from "@query/schema";
import * as React from "react";

type Props = {
    readonly wallet?: WalletProfileFragment;
};

export const WalletUserPicture: React.FC<Props> = ({wallet}) => {
    if (wallet?.picture) {
        return <Image file={wallet.picture} alt={wallet.name} size={Images.SM}/>;
    }

    return (
        <svg width="24" height="24" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            {/* eslint-disable-next-line max-len */}
            <path d="M31.7638 27.403C29.7052 23.9151 26.5092 21.2117 22.6929 19.7301C24.5659 18.3498 25.9495 16.4254 26.6476 14.2295C27.3457 12.0335 27.3229 9.67746 26.5825 7.49495C25.8422 5.31245 24.4217 3.41417 22.5224 2.06901C20.623 0.723848 18.3411 0 15.9998 0C13.6586 0 11.3766 0.723848 9.47729 2.06901C7.57795 3.41417 6.15749 5.31245 5.41711 7.49495C4.67674 9.67746 4.65399 12.0335 5.35208 14.2295C6.05017 16.4254 7.43371 18.3498 9.30673 19.7301C5.49045 21.2117 2.29447 23.9151 0.235828 27.403C0.00225996 27.8008 -0.0609154 28.2735 0.0602002 28.717C0.181316 29.1606 0.476801 29.5388 0.881653 29.7683C1.2865 29.9978 1.76756 30.0599 2.21899 29.9408C2.67042 29.8218 3.05525 29.5315 3.28882 29.1337C4.57712 26.9413 6.42999 25.1208 8.66123 23.8551C10.8925 22.5894 13.4235 21.923 15.9998 21.923C18.5762 21.923 21.1072 22.5894 23.3384 23.8551C25.5697 25.1208 27.4225 26.9413 28.7108 29.1337C28.8666 29.396 29.0894 29.6138 29.3571 29.7655C29.6249 29.9173 29.9283 29.9978 30.2373 29.999C30.5467 30.0015 30.851 29.9217 31.118 29.7683C31.3186 29.6547 31.4944 29.5034 31.6354 29.3231C31.7764 29.1427 31.8799 28.9368 31.9398 28.7171C31.9998 28.4975 32.0151 28.2683 31.9849 28.0428C31.9547 27.8173 31.8796 27.5999 31.7638 27.403ZM8.36735 10.9612C8.36735 9.47787 8.81499 8.02787 9.65365 6.79454C10.4923 5.56121 11.6844 4.59995 13.079 4.03231C14.4737 3.46467 16.0083 3.31615 17.4888 3.60553C18.9694 3.89491 20.3294 4.60919 21.3968 5.65805C22.4642 6.70691 23.1911 8.04324 23.4856 9.49805C23.7801 10.9529 23.629 12.4608 23.0513 13.8312C22.4736 15.2016 21.4954 16.3729 20.2402 17.197C18.985 18.0211 17.5094 18.4609 15.9998 18.4609C13.9756 18.4609 12.0342 17.6708 10.6028 16.2643C9.17148 14.8578 8.36735 12.9502 8.36735 10.9612Z" fill="white" fillOpacity="0.3"/>
        </svg>
    );
};
