import {Filter} from "@com/bar/filter";
import {BlockchainFilter} from "@com/bar/filter/BlockchainFilter";
import {useSearch} from "@com/search";
import {OrderFilterInput} from "@query/schema";
import * as React from "react";

export const LendFilter: React.FC = () => {
    const search = useSearch<OrderFilterInput>();
    const {filter} = search.watch();

    return (
        <Filter>
            {!filter.tokenAddress && <div className="item">
                <BlockchainFilter
                    value={filter.blockchain}
                    onChange={(v) => search.set("blockchain", v)}
                />
            </div>}
        </Filter>
    );
};
