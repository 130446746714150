import {ActiveComponent} from "@app/share/com/ActiveComponent";
import {useWalletAccount} from "@app/share/ethers";
import {IconCheckWhite, IconEthTransparent, IconPencil} from "@app/share/icons";
import {MintButton} from "@com/testnet";
import {UpdateWallet, WalletNode} from "@query/config";
import * as React from "react";

import {WalletProfile} from "./Wallet/WalletProfile";

const NAME_PLACEHOLDER = "Enter name here";

export const Header: React.FC = () => {
    const [isNameEditable, setNameEdit] = React.useState(false);
    const {address} = useWalletAccount();
    const {wallet: {node: wallet}} = WalletNode.watch({id: address});
    const [name, setName] = React.useState(wallet?.name ?? NAME_PLACEHOLDER);

    const toggleName = React.useCallback(() => {
        if (isNameEditable
            && name !== wallet?.name
            && name !== NAME_PLACEHOLDER
            && name.length > 1
            // TODO: add some conditions here
        ) {
            UpdateWallet.mutate({payload: {id: address, name}});
        }
        setNameEdit(!isNameEditable);
    }, [isNameEditable, name, address, wallet?.name]);

    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.currentTarget.value;
        setName(value);
    };

    return (
        <div className="container">
            <div className="header-game">
                <div className="content content_profile">
                    <div className="head head_profile">
                        <div className="logo logo_profile">
                            <WalletProfile wallet={wallet}/>
                        </div>
                        <div className="name name_profile">
                            <b>
                                <input
                                    readOnly={!isNameEditable}
                                    type="text"
                                    maxLength={16}
                                    placeholder={NAME_PLACEHOLDER}
                                    value={name}
                                    onChange={onChangeName}
                                />
                                <div onClick={toggleName}>
                                    {!isNameEditable ? <IconPencil/> : <IconCheckWhite />}
                                </div>
                            </b>
                            <span>
                                <IconEthTransparent/>
                                <i>{`${address.slice(0, 8)  }...${address.slice(-6)}`}</i>
                            </span>
                        </div>
                        <ActiveComponent component="mp:mint">
                            <div className="mint_con">
                                <div className="mint_btn">
                                    <MintButton/>
                                </div>
                            </div>
                        </ActiveComponent>
                        {/* <ul className="socials">
                            <li><a href=""><IconEtherScan/></a></li>
                            <li><a href=""><IconWebSite/></a></li>
                            <li><a href=""><IconMedium/></a></li>
                            <li><a href=""><IconDiscord/></a></li>
                            <li><a href=""><IconTwitter/></a></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
