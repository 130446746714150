import {Fallback, ListLayout, MarketList} from "@com/layout";
import {Message} from "@com/message";
import {useSearch} from "@com/search";
import {SkeletonList, SkeletonNFTItem} from "@com/skeleton";
import {MyCollection} from "@query/config";
import {NftFilterInput} from "@query/schema";
import * as React from "react";

import {NFTSearchPage} from "./NFTSearchPage";

export const NFTSearch: React.FC<{owner?: string}> = () => {
    const search = useSearch<NftFilterInput>();
    const {pagination: {limit}, filter, offsets} = search;
    const {count} = MyCollection.watch({filter, pagination: {limit, offset: 0}});

    return (
        <ListLayout assertion={count > 0}>
            <MarketList count={count}>
                {offsets.map((offset) => (
                    <SkeletonList key={offset}
                        size={limit}
                        skeleton={<SkeletonNFTItem/>}>
                        <NFTSearchPage
                            filter={filter}
                            pagination={{limit, offset}}/>
                    </SkeletonList>
                ))}
            </MarketList>
            <Fallback>
                <Message>NFTs not found</Message>
            </Fallback>
        </ListLayout>
    );
};
