import "./ProfitDistribution.scss";

import * as React from "react";

type Props = {
    profit: number;
};

export const ProfitDistribution: React.FC<Props> = ({profit}) => (
    <div className="profit-percent">
        Profit
        <b>{profit}%</b>
    </div>
);
