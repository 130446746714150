import "./CreateOrder.scss";

import {GameNode} from "@query/config";
import * as React from "react";
import {Link, Outlet, useParams} from "react-router-dom";

import {Header} from "../com/Header";

export const CreateOrder: React.FC = () => {
    const {uri} = useParams<"uri">();

    return (
        <div className="create-order">
            <div className="container">
                <div className="head">
                    <div className="name">Create Rent Order</div>
                    <Link to="../">
                        <div className="button"><i>x</i> Request</div>
                    </Link>
                </div>
                <div className="main-wrapper">
                    {uri && <QueryHeader uri={uri}/>}
                    <Outlet/>
                </div>
            </div>
        </div>
    );
};

const QueryHeader: React.FC<{uri: string}> = ({uri}) => {
    const result = GameNode.query({uri});

    return <>{result.game.node && <Header data={result.game.node} mini={true}/>}</>;
};
