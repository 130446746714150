import {useOperation} from "@app/com/endpoint";
import {useEtherClient, useWalletAccount} from "@app/share/ethers";
import {useNotify} from "@app/share/notify";
import Btn from "@com/UI/Btn";
import {MPMutation} from "@query/MPMutation";
import {MintMutation, MintMutationVariables} from "@query/schema";
import {useCallback} from "react";
import * as React from "react";

import MintDoc from "./query/Mint.graphql";

const MintQuery = MPMutation.factory<MintMutation, MintMutationVariables>(MintDoc);

export const MintButton: React.FC = () => {
    const wallet = useWalletAccount();
    const [state, {run}] = useOperation();
    const client = useEtherClient();
    const notify = useNotify();
    const mint = useCallback(
        () => run(
            async () => {
                const {blockchain} = wallet;
                const {nft: {mint: {minterAddress, uri}}} = await MintQuery.mutate({blockchain});
                const transaction = await client.mint(minterAddress, uri);
                notify("We are minting your new NFT. Wait a bit.");

                await transaction.wait();
                notify("Your NFT will be synchronized shortly.");
            },
            (reason) => notify(reason),
        ),
        [wallet],
    );

    return (
        <Btn className="com-mint_btn" loading={state.pending} onClick={mint}>Mint NFT</Btn>
    );
};
