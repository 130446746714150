import "./SkeletonOrderRender.scss";

import * as React from "react";

export const SkeletonOrderRender: React.FC = () => {
    return (
        <div className="order-item-wrap">
            <div className="skeleton-order skeleton">
                <div className="skeleton-order__thumb">
                    <div className="skeleton-order__img skeleton_dark" />
                    <div className="skeleton-order__info">
                        <div className="skeleton_dark" />
                        <div className="skeleton_dark" />
                    </div>
                    <div className="skeleton-order__price skeleton_dark" />
                </div>
                <div className="skeleton-order__table">
                    <div className="skeleton_dark" />
                    <div className="skeleton_dark" />
                    <div className="skeleton_dark" />
                    <div className="skeleton_dark" />
                </div>
                <div className="skeleton-order__btn skeleton_dark" />
            </div>
        </div>
    );
};


