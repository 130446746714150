import {useOperation} from "@app/com/endpoint";
import {useEtherClient} from "@app/share/ethers";
import {useNotify} from "@app/share/notify";
import {useCatalogAtom} from "@com/catalog";
import {PopupTransaction} from "@com/popup";
import Btn from "@com/UI/Btn";
import {AddReward, Return, ReturnRequest} from "@query/config";
import {OrderFragment} from "@query/schema";
import {usePopUp} from "@state";
import * as React from "react";

type Props = {
    id: string;
    wNFT: Exclude<OrderFragment["wnft"], undefined>;
};

export const RewardAction: React.FC<Props> = ({wNFT, id}) => {
    const [amount, setAmount] = React.useState(-1);
    const {triggerWipe} = useCatalogAtom("myOrder");
    const notify = useNotify();
    const client = useEtherClient();
    const provider = client.getProvider();
    const popup = usePopUp(PopupTransaction, {});
    const [state, {useCallback}] = useOperation({
        start: () => popup.open(true),
        finish: () => popup.close(true),
        error: (reason) => notify(reason),
    });

    React.useEffect(
        () => {
            client.wNFTView(wNFT.tokenAddress, wNFT.tokenId)
                .then(setAmount);
        },
        [wNFT],
    );

    const onClaimClick = useCallback(async () => {
        const transaction = await client.claimAndSwap(wNFT.tokenAddress, wNFT.tokenId);
        await transaction.wait();
        await client.wNFTView(wNFT.tokenAddress, wNFT.tokenId)
            .then(setAmount);
        triggerWipe();
    });

    const onAddRewardClick = useCallback(async () => {
        const {nft: {addReward}} = await AddReward.mutate({id: id});
        await provider.waitForTransaction(addReward);
        await client.wNFTView(wNFT.tokenAddress, wNFT.tokenId)
            .then(setAmount);
        triggerWipe();
    });

    const onReturn = useCallback(async () => {
        const {nft: {returnRequest: executiveContractAddress}} = await ReturnRequest.mutate({id});
        const transaction = await client.return({wNFT, executiveContractAddress});
        await Return.mutate({id, hash: transaction.hash});
        triggerWipe();
    });

    return (
        <>
            {amount > 0 && <Btn
                loading={state.pending}
                onClick={onClaimClick}
                style="primary"
            >
                    Claim reward
            </Btn>}
            {amount === 0 && (
                <>
                    <Btn
                        loading={state.pending}
                        onClick={onAddRewardClick}
                        style="primary"
                    >
                        Add reward
                    </Btn>
                    <Btn
                        style="border"
                        onClick={onReturn}
                        loading={state.pending}
                    >
                        Callback NFT
                    </Btn>
                </>
            )}
        </>
    );
};
