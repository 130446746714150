import {cssx} from "@app/com/css";
import {ISearchPagination} from "@com/search";
import * as React from "react";

type Props = {
    count: number;
    pagination: ISearchPagination;
    onClick?: () => void;
};

const More: React.FC<Props> = ({pagination, count, onClick}) => {
    const {limit, offset} = pagination;
    const page = (offset + limit) / limit;
    const pages = Math.ceil(count / limit);

    return (
        <div className={cssx({"end": page === pages}, "com-more")} onClick={onClick}>
            {page < pages && <b>More</b>}
            <span>Page {page} of {pages}</span>
        </div>
    );
};

export default More;
