import {Dispatcher} from "@app/com/event";
import {useEffect, useState} from "react";

export class FilterState {
    #state = false;

    public readonly dispatcher = new Dispatcher<{state: [boolean]}>();

    public get state(): boolean {
        return this.#state;
    }

    public useState(): boolean {
        const [state, set] = useState(this.#state);

        useEffect(() => this.dispatcher.listen("state", set), []);

        return state;
    }

    public toggle = (): void => {
        this.#state = !this.#state;
        this.dispatcher.fire("state", this.#state);
    };
}
