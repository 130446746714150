import Btn from "@com/UI/Btn";
import {MarketOrderStatus, OrderFragment} from "@query/schema";
import * as React from "react";

import {RemoveOrderAction, RewardAction} from "./Action";
import {OrderComProps} from "./config";

const buttons: Partial<Record<MarketOrderStatus, (data: OrderFragment) => React.ReactElement>> = {
    [MarketOrderStatus.Created]: (data) => <RemoveOrderAction id={data.id}/>,
    [MarketOrderStatus.Executing]: (data) => (
        data.wnft
            ? <RewardAction id={data.id} wNFT={data.wnft}/>
            : <Btn style="completed" loading>Syncing...</Btn>
    ),
    [MarketOrderStatus.Completed]: () => <Btn style="completed">Completed</Btn>,
};

export const OrderAction: React.FC<OrderComProps> = ({data}) => {
    return (
        <div className="col list list-btns">
            {buttons[data.status]?.(data)}
        </div>
    );
};
