export const SOCIALS = {
    telegram: {
        unitbox: "https://t.me/unitbox_io",
        unitbox_en: "https://t.me/unitbox_en",
        rent2earn: "https://t.me/rent2earn",
    },
    twitter: {
        unitbox: "https://twitter.com/unitbox_io",
        rent2earn: "https://twitter.com/Rent2Earn",
    },
    discord: {
        unitbox: "https://discord.gg/TJv89tzsa8",
        marketplace: "https://discord.gg/TJv89tzsa8",
    },
    medium: {
        unitbox: "https://medium.com/@unitbox_io",
        rent2earn: "https://medium.com/@rent2earn",
    },
    mail: {
        hello: "aGVsbG9AdW5pdGJveC5pbw==",
        careers: "Y2FyZWVyc0B1bml0Ym94Lmlv",
        info: "aW5mb0B1bml0Ym94Lmlv",
    },
};
