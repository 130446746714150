import {OrderRequestAction} from "@query/schema";
import * as React from "react";

import {MakerContext} from "./context";
import factory from "./MakerAtomFactory";

type Props = {
    action: OrderRequestAction;
    children: React.ReactNode;
};

const {Provider} = MakerContext;

export const MakerProvider: React.FC<Props> = ({action, children}) => {
    return (
        <Provider value={factory(action)}>
            {children}
        </Provider>
    );
};
