import {MarketItem} from "@com/layout";
import {SkeletonItem} from "@com/skeleton";
import {useMarketContext} from "@pages/Market/context";
import {OrderList} from "@query/config";
import {OrderFilterInput, PaginateInput} from "@query/schema";
import * as React from "react";

import {OrderCard} from "./order/OrderCard";

type Props = {
    filter: OrderFilterInput;
    pagination: PaginateInput;
};

export const OrdersSearchPage: React.FC<Props> = (variables) => {
    const {render} = useMarketContext();
    const {node} = OrderList.watch(variables);

    return (
        <>
            {node.map((order) => (
                <MarketItem fallback={<SkeletonItem/>} key={order.id}>
                    <OrderCard order={order}>
                        {render(order)}
                    </OrderCard>
                </MarketItem>
            ))}
        </>
    );
};
