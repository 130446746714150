import {NftFragment} from "@query/schema";
import * as React from "react";


type Props = {
    token: NftFragment;
};

export const TokenName: React.FC<Props> = ({token}) => (
    <span title={token.metadata?.name ?? token.name}>{token.metadata?.name ?? token.name}</span>
);
