import * as React from "react";

import {SearchState} from "./SearchState";

export const SearchProviderContext = React.createContext(
    new SearchState<Record<string, any>>({limit: 12, offset: 0, filters: {}}),
);

const {Provider} = SearchProviderContext;

type Props = {
    children: React.ReactNode[] | React.ReactNode;
    filters?: Record<string, any>;
    limit?: number;
};

export const SearchProvider: React.FC<Props> = (props) => {
    const {limit = 12, children, filters = {}} = props;
    const search = React.useMemo(() => new SearchState({filters, limit, offset: 0}), [limit]);

    return (
        <Provider value={search}>
            {children}
        </Provider>
    );
};
