export const ND = "N/A";
export const VIEW = "View";
export const INFO = {
    iText: "Click on a scolar's profile to view his statistics in detail",
    title: "Info",
};
export const apr = {
    iText: "Click on a scolar's profile to view his statistics in detail",
    title: "APR",
};
export const collection = {
    iText: "Click on a scolar's profile to view his statistics in detail",
    title: "Collection",
};
export const nftid = {
    iText: "Click on a scolar's profile to view his statistics in detail",
    title: "NFT ID",
};
export const address = {
    iText: "Click on a scolar's profile to view his statistics in detail",
    title: "NFT address",
};
export const standart = {
    iText: "Click on a scolar's profile to view his statistics in detail",
    title: "NFT standart",
};

export const rentability = {
    iText: "Click on a scolar's profile to view his statistics in detail",
    title: "NFTs Rentability",
};

export const explorers: Record<string, string> = {
    "RIN": "https://rinkeby.etherscan.io/token",
    "ROP": "https://ropsten.etherscan.io/token",
    "GOR": "https://goerli.etherscan.io/token",
};
