import {Fallback, ListLayout, MarketList} from "@com/layout";
import {Message} from "@com/message";
import {useSearch} from "@com/search";
import {SkeletonItem, SkeletonList} from "@com/skeleton";
import {OrderList} from "@query/config";
import {OrderFilterInput} from "@query/schema";
import * as React from "react";

import {OrdersSearchPage} from "./OrdersSearchPage";

export const OrdersSearch: React.FC = () => {
    const search = useSearch<OrderFilterInput>();
    const {pagination: {limit}, filter, offsets} = search;
    const {count} = OrderList.watch({filter, pagination: {limit, offset: 0}});

    return (
        <>
            <ListLayout assertion={count > 0}>
                <MarketList count={count}>
                    {offsets.map((offset) => (
                        <SkeletonList size={8}
                            key={offset}
                            skeleton={<SkeletonItem/>}
                            container={<div className="market-list"/>}>
                            <OrdersSearchPage
                                filter={filter}
                                pagination={{limit, offset}}/>
                        </SkeletonList>
                    ))}
                </MarketList>
                <Fallback>
                    <Message>Orders not found</Message>
                </Fallback>
            </ListLayout>
        </>
    );
};
