import {DateTimeFormat} from "@app/com/date";
import * as React from "react";

import {getDatePrefix, OrderComProps} from "./config";

export const OrderInfo: React.FC<OrderComProps> = ({data}) => {
    return (
        <ul className="col list">
            <li>
                <span>{getDatePrefix(data)} on</span>
                <span><DateTimeFormat value={data.updatedAt} /></span>
            </li>
        </ul>
    );
};
