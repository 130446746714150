import {SearchProvider} from "@com/search";
import {SkeletonItem, SkeletonList} from "@com/skeleton";
import * as React from "react";
import {useParams} from "react-router-dom";

import {Bar} from "./com/Bar";
import {useMarketContext} from "./context";
import {OrdersSearch} from "./OrdersSearch";

export const Orders: React.FC = () => {
    const {address: tokenAddress} = useParams<"address">();
    const {status, action} = useMarketContext();

    return (
        <SearchProvider filters={{status, action, tokenAddress}}>
            <Bar/>
            <SkeletonList size={8}
                skeleton={<SkeletonItem/>}
                container={<div className="market-list"/>}>
                <OrdersSearch />
            </SkeletonList>
        </SearchProvider>
    );
};
