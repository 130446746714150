import {useWalletAccount} from "@app/share/ethers";
import {SearchProvider} from "@com/search";
import {SkeletonList, SkeletonNFTItem} from "@com/skeleton";
import {OrderRequestAction} from "@query/schema";
import * as React from "react";
import {useParams} from "react-router-dom";

import {Bar} from "./com";
import {useSellSigner} from "./hooks";
import {MakerCart} from "./Maker/MakerCart";
import {MakerProgress} from "./Maker/MakerProgress";
import {MakerProvider} from "./Maker/MakerProvider";
import {NFTSearch} from "./NFTSearch";

export const Sell: React.FC = () => {
    const signer = useSellSigner();
    const {address: owner} = useWalletAccount();
    const {address: tokenAddress} = useParams<"address">();

    return (
        <SearchProvider filters={{tokenAddress, owner}}>
            <Bar/>
            <MakerProvider action={OrderRequestAction.Sell}>
                <div className="container">
                    <SkeletonList size={8}
                        skeleton={<SkeletonNFTItem/>}
                        container={<div className="market-list" />}>
                        <NFTSearch owner={owner}/>
                    </SkeletonList>
                    <MakerCart signer={signer}/>
                </div>
                <MakerProgress/>
            </MakerProvider>
        </SearchProvider>
    );
};
