import * as React from "react";

type Props = {
    expire?: Date;
};

export const Expiration: React.FC<Props> = ({expire}) => {
    if (!expire) {
        return null;
    }

    const date = new Date(expire);
    const data = [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
    ];

    return <div className="date">
        <b>Ends</b>
        <i>|</i>
        <span>{data[0]}</span>
        <i>|</i>
        <span>{data[1]}</span>
        <i>|</i>
        <span>{data[2]}</span>
    </div>;
};
