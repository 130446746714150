import {BlockchainNetworkIcon} from "@app/com/blockchain";
import {cssx} from "@app/com/css";
import {Image} from "@app/com/image";
import {TokenId, TokenName} from "@com/NFT";
import {Images} from "@config";
import {NftWithOrderFragment} from "@query/schema";
import * as React from "react";

import {MakerFormContext} from "../Maker/context";
import {MakerAction} from "../Maker/MakerAction";
import {Form} from "./Form";

type Props = {
    nft: NftWithOrderFragment;
};

export const Card: React.FC<Props> = ({nft}) => {
    const {locked} = React.useContext(MakerFormContext);

    return (
        <>
            <div className="img">
                <Image file={nft.image} alt={nft.name} size={Images.MD}/>
            </div>
            <div className="content">
                <div className="row">
                    <div className={cssx({locked}, "block")}>
                        <div className="name single">
                            <u><TokenId token={nft}/></u> <TokenName token={nft}/>
                        </div>
                        <div className="tags">
                            <div className="tag">
                                <Image file={nft.game.cover} size={150}/>
                                <span>{nft.game.title}</span>
                            </div>
                            <div className="tag">
                                <BlockchainNetworkIcon network={nft.blockchain} symbol icon />
                            </div>
                        </div>
                        <div className="inputs">
                            <Form />
                        </div>
                    </div>
                </div>
                <div className="btns">
                    <MakerAction nft={nft}/>
                </div>
            </div>
        </>
    );
};
