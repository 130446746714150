import "./Tabs.scss";

import {cssx} from "@app/com/css";
import * as React from "react";
import {Link, To} from "react-router-dom";

type TabProps = {
    children: React.ReactNode;
    path?: To;
    active?: boolean;
    onClick?: React.MouseEventHandler;
    className?: string;
};

type Props = {
    className?: string;
    renderTabs: () => React.ReactElement<TabProps> | React.ReactElement<TabProps>[];
    children?: React.ReactNode;
};

export const Tabs: React.FC<Props> = ({children, className, renderTabs}) => {
    return (
        <div className={`subheader ${className}`}>
            <ul className="tabs">
                {renderTabs()}
            </ul>
            {children}
        </div>
    );
};

export const Tab: React.FC<TabProps> = ({children, path, active, onClick, className}) => {
    return (
        <li>
            {path
                ? <Link to={path} className={cssx({active}, className ?? "", "tab")} onClick={onClick}>
                    {children}
                </Link>
                : <a
                    role="link"
                    aria-disabled="true"
                    className={cssx({active}, "tab")}
                    onClick={onClick}
                >
                    {children}
                </a>
            }
        </li>
    );
};
