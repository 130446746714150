import {Fallback, ListLayout} from "@com/layout";
import More from "@com/layout/More";
import {Message} from "@com/message";
import {useSearch} from "@com/search";
import {SkeletonList, SkeletonOrderRender} from "@com/skeleton";
import {MyOrderList} from "@query/config";
import {MyOrderFilterInput} from "@query/schema";
import * as React from "react";

import {HistorySearchPage} from "./HistorySearchPage";

export const HistorySearch: React.FC = () => {
    const search = useSearch<MyOrderFilterInput>();
    const {filter, pagination: {limit}} = search;
    const {count} = MyOrderList.watch({filter, pagination: {limit, offset: 0}});

    return (
        <ListLayout assertion={count > 0}>
            <div className="order-list">
                {search.offsets.map((offset) => (
                    <SkeletonList
                        size={12}
                        skeleton={<SkeletonOrderRender/>}
                        key={offset}>
                        <HistorySearchPage
                            filter={filter}
                            limit={limit}
                            offset={offset}
                        />
                    </SkeletonList>
                ))}
                <More count={count} pagination={search.pagination} onClick={search.more}/>
            </div>
            <Fallback>
                <Message>Orders not found</Message>
            </Fallback>
        </ListLayout>
    );
};
