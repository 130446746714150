import {BlockchainNetwork, BlockchainNetworkList} from "@app/types";
import {useSearch} from "@com/search";
import {MarketOrderStatus, MyOrderFilterInput, OrderFragment, OrderRequestAction} from "@query/schema";

export type OrderComProps = {
    data: OrderFragment;
};

export type OrderWithImageProps = OrderComProps;

export const getOrderStatus = (data: OrderFragment): string => {
    return data.status === MarketOrderStatus.Created
        ? "Created"
        : data.status === MarketOrderStatus.Executing
            ? "Active"
            : "Completed";
};

export const getDatePrefix = (data: OrderFragment): string => {
    return data.status === MarketOrderStatus.Created
        ? "Created"
        : data.status === MarketOrderStatus.Completed
            ? (data.action === OrderRequestAction.Sell ? "Sold" : "Rented")
            : "Completed";
};

export const getBlockchain = (chain: string): BlockchainNetwork | undefined => BlockchainNetworkList
    .find((bchain) => bchain.id === chain);

export const getMyProfit = (address: string, data: OrderFragment): string => {
    const search = useSearch<MyOrderFilterInput>();

    switch (data.action) {
        case OrderRequestAction.Sell:
            return `${data.maker.royalty} ETH`;
        case OrderRequestAction.Lend:
            return search.filter.action == OrderRequestAction.Rent
                ? `${100 - data.maker.royalty}%`
                : `${data.maker.royalty}%`;
        case OrderRequestAction.Rent:
            return search.filter.action == OrderRequestAction.Lend
                ? `${100 - data.maker.royalty}%`
                : `${data.maker.royalty}%`;
    }
};
