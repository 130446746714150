import {OrderRequestAction} from "@query/schema";

import {MakerBaseState, MakerItem, MakerItemId, MakerStoreValue, MarketStoreMap} from "./interfaces";
import {MAX_CART_CAPACITY} from "./MakerAtomFactory";

export class MakerVirtualState implements MakerBaseState {
    readonly #store: MarketStoreMap;
    public processing = false;
    public readonly action: OrderRequestAction;
    public readonly title = "";

    constructor(action: OrderRequestAction, store: MarketStoreMap) {
        this.#store = store;
        this.action = action;
    }

    public clone(next: Partial<MakerBaseState> = {}): MakerVirtualState {
        return Object.assign(new MakerVirtualState(this.action, this.#store), {...this, ...next});
    }

    public has(id: MakerItemId): boolean {
        return this.#store.has(this.id(id));
    }

    public get(id: MakerItemId): MakerStoreValue | undefined {
        return this.#store.get(this.id(id));
    }

    public isPending(id: MakerItemId): boolean {
        return !this.#store.get(this.id(id))?.done;
    }

    public isProcessed(id: MakerItemId): boolean {
        return !!this.#store.get(this.id(id))?.done;
    }

    public get items(): MakerItem[] {
        return [...this.#store.values()]
            .map(({item}) => item);
    }

    public get pending(): MakerStoreValue[] {
        return [...this.#store.values()]
            .filter(({done}) => !done);
    }

    public get freeSlotKeys(): string[] {
        return new Array(this.size < 8 ? 8 - this.size : 0)
            .fill(null)
            .map((_, i) => `freeSlot_${i}`);
    }

    public get dotsKeys(): string[] {
        return new Array(Math.ceil(this.pending.length / 8))
            .fill(null)
            .map((_, i) => `dots_${i}`);
    }

    public get values(): MakerStoreValue[] {
        return [...this.#store.values()];
    }

    public get size(): number {
        return this.#store.size;
    }

    public get sizeRest(): number {
        return this.size - this.processed;
    }

    public get processed(): number {
        return [...this.#store.values()]
            .filter((id) => id.done)
            .length;
    }

    public get removable(): boolean {
        return this.size > 0;
    }

    public get full(): boolean {
        return this.size >= MAX_CART_CAPACITY;
    }

    public id(id: MakerItemId): string {
        return typeof id === "string" ? id : id.id;
    }
}
