import {MyOrderList} from "@query/config";
import {MyOrderFilterInput} from "@query/schema";
import * as React from "react";

import OrderRender from "./com/Card";

type Props = {
    limit: number;
    offset: number;
    filter: MyOrderFilterInput;
};

export const HistorySearchPage: React.FC<Props> = ({offset, filter, limit}) => {
    const {node} = MyOrderList.watch({filter, pagination: {limit, offset}});

    return (
        <>
            {node.map((data) => <OrderRender key={data.id} data={data}/>)}
        </>
    );
};
