import "./InputRange.module.scss";

import {ElementProps} from "@app/com/interfaces";
import * as React from "react";

type Props = {
    from: ElementProps<"input">;
    to: ElementProps<"input">;
};

export const InputRange: React.FC<Props> = (props) => {
    return (
        <div className="com-range">
            <input
                type="number"
                placeholder="From"
                step={0.1}
                {...props.from}
            />
            <input
                type="number"
                placeholder="To"
                step={0.1}
                {...props.to}
            />
        </div>
    );
};
