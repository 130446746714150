import {BlockchainNetworkIcon} from "@app/com/blockchain";
import {cssx} from "@app/com/css";
import {Image} from "@app/com/image";
import {ActiveComponent} from "@app/share/com/ActiveComponent";
import {IconLikeOrder, IconShareOrder} from "@app/share/icons";
import {TokenId, TokenName} from "@com/NFT";
import {WalletUserPicture} from "@com/wallet";
import {Images} from "@config";
import {OrderExtendedFragment} from "@query/schema";
import * as React from "react";

import {Expiration} from "./com/Expiration";

type Props = {
    order: OrderExtendedFragment;
    children: React.ReactNode;
};

export const OrderCard: React.FC<Props> = ({order, children}) => {
    const [royalty] = order.royalties;

    return (
        <>
            <div className="img">
                <div className="icons">
                    <ActiveComponent component="mp:share">
                        <div className="icon active">
                            <IconShareOrder/>
                        </div>
                    </ActiveComponent>
                    <ActiveComponent component="mp:favorites">
                        <div
                            className={cssx({active: false}, "icon")}
                        >
                            <IconLikeOrder/>
                        </div>
                    </ActiveComponent>
                </div>
                <Image file={order.token.image} alt={order.token.name} size={Images.MD}/>
                <Expiration expire={order.expireAt} />
            </div>
            <div className="content">
                <div className="head">
                    <div className="name single">
                        <u><TokenId token={order.token}/></u> <TokenName token={order.token}/>
                    </div>
                    <div className="tags">
                        <div className="tag">
                            <Image file={order.game.cover} size={150}/>
                            <span>{order.game.title}</span>
                        </div>
                        <div className="tag">
                            <BlockchainNetworkIcon network={order.blockchain} symbol icon />
                        </div>
                    </div>
                </div>
                <div className="user">
                    <div className="thumb">
                        <div className="img">
                            <WalletUserPicture wallet={order.user}/>
                        </div>
                        <div className="text">
                            <b>{order.user.name}</b>
                            <span>{royalty.type}</span>
                        </div>
                    </div>
                    {/* <div className="rating">
                            <div className="stars">
                                <div className="active" style={{width: "74%"}} />
                            </div>
                        </div> */}
                </div>
                <div className="foot">
                    {children}
                </div>
                {/* <Btn
                        loading={opened}
                        onClick={guildPopUp.toggle}
                    >
                    Appoint to scholar
                    </Btn> */}
            </div>
        </>
    );
};
