import {MyOrdersTabsMarket, MyOrdersTabsRental} from "@com/bar";
import {OrderRequestAction as Action, RoyaltyType} from "@query/schema";
import * as React from "react";
import {Route, Routes} from "react-router-dom";

import {SearchGroup} from "./History/SearchGroup";

export const History: React.FC = () => {
    const rent = MyOrdersTabsRental;
    const sell = MyOrdersTabsMarket;

    return (
        <main className="market-body">
            <Routes>
                <Route
                    path="lend"
                    element={<SearchGroup
                        key="lend"
                        tabs={rent}
                        action={Action.Lend}
                    />}
                />
                <Route
                    path="rent"
                    element={<SearchGroup
                        key="rent"
                        tabs={rent}
                        action={Action.Rent}
                    />}
                />
                <Route
                    path="buy"
                    element={<SearchGroup
                        key="buy"
                        tabs={sell}
                        type={RoyaltyType.Customer}
                        action={Action.Sell}
                    />}
                />
                <Route
                    path="sell"
                    element={<SearchGroup
                        key="sell"
                        tabs={sell}
                        type={RoyaltyType.Owner}
                        action={Action.Sell}
                    />}
                />
            </Routes>
        </main>
    );
};
