import * as React from "react";

import {Formatter} from "./Formatter";

type Props = {
    round?: true | number | [min: number, max: number];
    value?: number | string;
    compact?: boolean;
    na?: string | number;
    zeroAs?: string;
};

export const NumberFormat: React.FC<Props> = (props) => {
    return <Formatter {...props}/>;
};
