import {useEtherState} from "@app/share/ethers";
import * as React from "react";

const WalletMenu = React.lazy(() => import("./WalletMenu"));
const WalletSwitch = React.lazy(() => import("./WalletSwitch"));
const WalletConnect = React.lazy(() => import("./WalletConnect"));

export const Wallet: React.FC = () => {
    const state = useEtherState();

    if (!state.initialized) {
        return null;
    }

    return (
        <div className="cab">
            <React.Suspense>
                {!state.wallet.connected ? <WalletConnect/> : <WalletMenu/>}
                {/* {walletState === WalletStateType.Unsupported && <WalletSwitch/>} */}
            </React.Suspense>
        </div>
    );
};

