import {DropdownSelect2, SelectOption, SelectValue} from "@com/UI/Select";
import {MarketOrderStatus} from "@query/schema";
import * as React from "react";

type Props = {
    onChange: (value: MarketOrderStatus | undefined) => void;
    value: MarketOrderStatus | undefined;
};

const options: SelectOption[] = [
    {value: undefined, name: "All"},
    {value: MarketOrderStatus.Executing, name: "Active"},
    {value: MarketOrderStatus.Completed, name: "Completed"},
];

const keys: string[] = Object.values(MarketOrderStatus);

function is(value: SelectValue): value is MarketOrderStatus {
    return !!value && keys.includes(value.toString());
}

function validate(value: SelectValue): MarketOrderStatus | undefined {
    return is(value) ? value : undefined;
}

export const OrderStatusFilter: React.FC<Props> = ({value, onChange}) => {
    return (
        <DropdownSelect2
            pre="Status:"
            value={value}
            options={options}
            onChange={(next) => onChange(validate(next))}
        />
    );
};
