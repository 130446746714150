import * as React from "react";

import {ExternalLink} from "../Links";
import banner from "./assets/banner.svg";
import bannerMob from "./assets/banner_mob.svg";


export const Banner: React.FC = () => {

    return (
        <ExternalLink
            address="https://heatherglade.com/"
            target="_blank"
            rel="noopener"
            className="footer__banner">
            <img src={banner} alt="" />
            <img className="mobile" src={bannerMob} alt="" />
        </ExternalLink>
    );
};
