import "./SkeletonItem.scss";

import {IconSkeletonImg} from "@app/share/icons";
import * as React from "react";

export const SkeletonItem: React.FC = () => {

    return (
        <div className="skeleton-item skeleton">
            <div className="skeleton-item__img">
                <IconSkeletonImg/>
            </div>
            <div className="skeleton-item__name skeleton_dark" />
            <div className="skeleton-item__tags">
                <div className="skeleton_dark" />
                <div className="skeleton_dark" />
            </div>
            <div className="skeleton-item__info">
                <div className="skeleton_dark" />
                <div className="skeleton_dark" />
            </div>
            <div className="skeleton-item__foot">
                <div className="skeleton_dark" />
                <div className="skeleton_dark" />
            </div>
        </div>
    );
};

export const SkeletonNFTItem: React.FC = () => {
    return (
        <div className="skeleton-item skeleton">
            <div className="skeleton-item__img">
                <IconSkeletonImg/>
            </div>
            <div className="skeleton-item__name skeleton_dark" />
            <div className="skeleton-item__tags">
                <div className="skeleton_dark" />
                <div className="skeleton_dark" />
            </div>
            <div className="skeleton-item__inputs">
                <div className="skeleton_dark" />
                <div className="skeleton_dark" />
            </div>
            <div className="skeleton-item__range skeleton_dark" />
            <div className="skeleton-item__foot-nft">
                <div className="skeleton_dark" />
            </div>
        </div>
    );
};


