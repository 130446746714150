import {GamesList} from "@query/config";
import * as React from "react";

import {GameCard} from "./GameCard";

type Props = {address?: string};

export const GameList: React.FC<Props> = ({address}) => {
    const {game: {list: games}} = GamesList.query({nftHolderAddress: address});

    return (
        <>
            {games.map((game) => <GameCard key={game.id} data={game}/>)}
        </>
    );
};
