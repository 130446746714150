import {OrderRequestAction} from "@query/schema";
import {createContext} from "react";

import {MakerOrderFormState} from "./interfaces";
import {MakerAtomType} from "./interfaces";
import MakerAtomFactory from "./MakerAtomFactory";

export const MakerContext = createContext<MakerAtomType>(MakerAtomFactory(OrderRequestAction.Sell));

export const MakerFormContext = createContext<MakerOrderFormState>(null as any);
