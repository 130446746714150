import "./FormPopup.scss";

import {useAtomState} from "@app/com/hooks";
import Btn from "@com/UI/Btn";
import {usePopUpFlow} from "@state";
import * as React from "react";

import {IOrderSigner} from "../Action";
import {useMakerActions} from "../hooks";
import {MakerAtomType} from "../interfaces";
import {Element} from "./Element";
import {MakerOrderRequest} from "./MakerOrderRequest";

type Props = {
    atom: MakerAtomType;
    signer: IOrderSigner;
};

export const FormPopup: React.FC<Props> = (props) => {
    const {atom} = props;
    const control = usePopUpFlow();
    const {pending} = useAtomState(atom);
    const action = useMakerActions(atom);

    return (
        <div className="popup-create">
            <Btn onClick={control.close} style="border orders_btn">Back</Btn>
            <div className="wrapper">
                {pending.map(({item, payload}) => (
                    <Element action={action}
                        atom={atom}
                        item={item}
                        payload={payload}
                        key={item.id}/>
                ))}
            </div>
            <div className="buttons">
                <MakerOrderRequest {...props}/>
            </div>
        </div>
    );
};
