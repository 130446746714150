import {useOperation} from "@app/com/endpoint";
import {useEtherClient, useWalletAccount} from "@app/share/ethers";
import {useNotify} from "@app/share/notify";
import {takeLendOrderAgreement, TakeOrderAgreementArgs, takeRentOrderAgreement} from "@com/agreement";
import {useCatalogAtom} from "@com/catalog";
import {approveAsset, executeOrderAction} from "@com/fn";
import {PopupTransaction} from "@com/popup";
import Btn from "@com/UI/Btn";
import {OrderExtendedFragment, OrderRequestAction, RoyaltyType} from "@query/schema";
import {usePopUp} from "@state";
import * as React from "react";

import {ProfitDistribution} from "./com/ProfitDistribution";

export const RentLendAction: React.FC<{order: OrderExtendedFragment}> = ({order}) => {
    const {action, royalties: [royalty]} = order;
    const operation = action === OrderRequestAction.Lend ? "Rent" : "Lend";
    const {triggerWipe} = useCatalogAtom("order");
    const client = useEtherClient();
    const notify = useNotify();
    const transaction = usePopUp(PopupTransaction, {});
    const [state, {run}] = useOperation({
        start: () => transaction.open(true),
        error: (error) => transaction.update({error}),
        success: () => {
            notify(`${operation} has been started`);
            transaction.close(true);
        },
    });

    const wallet = useWalletAccount();
    const agreementArgs: TakeOrderAgreementArgs = {
        royalty: royalty.value,
        tokenAddress: order.tokenAddress,
        tokenId: order.tokenId,
    };

    const take = React.useCallback(
        async () => run(async () => {
            const {address} = wallet;
            if (royalty.type === RoyaltyType.Customer) {
                await approveAsset(
                    client,
                    {
                        tokenId: order.token.tokenId,
                        tokenAddress: order.token.tokenAddress,
                        blockchain: order.blockchain,
                    },
                );
            }

            const text = royalty.type === RoyaltyType.Owner
                ? takeRentOrderAgreement(address, agreementArgs)
                : takeLendOrderAgreement(address, agreementArgs);

            await executeOrderAction({
                action,
                client,
                taker: address,
                orderId: order.id,
                orderTokenAddress: order.tokenAddress,
                orderTokenId: order.tokenId,
                takerAgreement: {text, sign: await client.sign(text)},
                // for guilds we need to pass an agent royalty
                royaltyTargets: [{
                    address,
                    value: 100 - royalty.value,
                    type: royalty.type === RoyaltyType.Customer ? RoyaltyType.Owner : RoyaltyType.Customer,
                }],
            });
            triggerWipe();
        }),
        [order.id],
    );

    return (
        <>
            <div className="block">
                <ProfitDistribution profit={100 - royalty.value}/>
                <div className="item">$/day<b>{royalty.value}</b></div>
            </div>
            <Btn
                loading={state.pending}
                disabled={!wallet.connected}
                onClick={take}
            >
                {operation}
            </Btn>
        </>
    );
};
