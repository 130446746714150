import "./Queue.scss";

import {usePeriodicFn} from "@app/com/hooks";
import Btn from "@com/UI/Btn";
import {AppState} from "app/config";
import * as React from "react";

import QueueImg from "../../assets/img/modules/queue/queue-img.png";

type Props = {
    no: number;
};

export const Queue: React.FC<Props> = ({no}) => {
    usePeriodicFn(() => AppState.update(), 60000);

    return (
        <div className="queue-page">
            <div className="content">
                <img className="img" src={QueueImg} alt="" />
                <div className="place">
                    <span>Your place in the queue:</span>
                    <b>{no + 1}</b>
                </div>
                <div className="descr">If you have an invitation code, enter it below:</div>
                <input type="text" placeholder="Code" className="input" />
                <Btn disabled>Enter</Btn>
            </div>
        </div>
    );
};

export const QueueUpdate: React.FC = () => {
    usePeriodicFn(() => AppState.fetch(), 600000);

    return null;
};
