import * as React from "react";

import {FilterState} from "./FilterState";

export const FilterContext = React.createContext(new FilterState());

export const FilterArea: React.FC<{children: React.ReactNode}> = ({children}) => {
    return (
        <FilterContext.Provider value={React.useMemo(() => new FilterState(), [])}>
            {children}
        </FilterContext.Provider>
    );
};
