import {cssx} from "@app/com/css";
import {useClickOutside, useToggleState} from "@app/com/hooks";
import {Assets} from "@com/assets";
import * as React from "react";
import {useController} from "react-hook-form";

import {DropdownProps} from "./interfaces";

export const DropdownSelect: React.FC<DropdownProps> = (props) => {
    const {options, name, control, pre, mini, disabled, resetCount} = props;

    const [drop, toggle, setDrop] = useToggleState();
    const {field: {onChange, value}} = useController({name, control});
    const ref = useClickOutside<HTMLDivElement>(() => setDrop(false));
    const label = options.find((option) => option.value === value)?.value;

    React.useEffect(() => {
        if (resetCount && resetCount > 0) {
            onChange(undefined);
        }
    }, [resetCount]);

    return (
        <div className={cssx({mini}, "com-select")}>
            <div className={cssx({active: drop}, "wrap")} ref={ref}>
                <span onClick={() => !disabled && setDrop(!drop)}>
                    {pre ? `${pre} ` : ""}{label}
                    {Assets.IconArrowDown()}
                </span>
                <ul>
                    {options.map((option, key) => (
                        <li
                            key={key}
                            className={cssx({active: option.value === value})}
                            onClick={() => {
                                onChange(option.value);
                                toggle();
                            }}
                        >
                            {option.name}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
