import * as React from "react";

import {ElementProps} from "../interfaces";

type Props = ElementProps<"img"> & {
    url: string;
    fallback?: string;
};

export const ExternalImage: React.FC<Props> = ({url, fallback, ...props}) => {
    return fallback
        ? <ExternalImageFallback {...props} url={url} fallback={fallback}/>
        : <img {...props} src={url}/>;
};

type FallbackProps = ElementProps<"img"> & {
    url: string;
    fallback: string;
};

export const ExternalImageFallback: React.FC<FallbackProps> = ({url, fallback, ...props}) => {
    const onErrorFallback = React.useCallback(
        (e: React.SyntheticEvent<HTMLImageElement>) => {
            e.currentTarget.src = fallback;
        },
        [fallback],
    );

    return <img {...props} src={url} onError={onErrorFallback}/>;
};
