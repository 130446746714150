import Btn from "@com/UI/Btn";
import Overlay from "@com/UI/Overlay";
import * as React from "react";
import {Transition} from "react-transition-group";

import {FilterContext} from "./FilterArea";
import {FilterBtn} from "./FilterBtn";

type Props = {
    children?: React.ReactNode[] | React.ReactNode;
    reset?: () => void;
};

export const Filter: React.FC<Props> = ({children, reset}) => {
    const state = React.useContext(FilterContext);

    if (!React.Children.toArray(children).length) {
        return <div />;
    }

    return (
        <>
            <FilterBtn/>
            <Transition
                in={state.useState()}
                timeout={500}
                nodeRef={React.useRef(null)}
            >
                {(overlayState) => (
                    <>
                        <Overlay overlayState={overlayState} onClick={state.toggle}/>
                        <aside className={`filter ${overlayState}`}>
                            <div className="content">
                                <div className="name">
                                    <span>Filters</span>
                                    <i className="com-close close" onClick={state.toggle}/>
                                </div>
                                {children}
                                <div className="buttons">
                                    <Btn
                                        style="border"
                                        onClick={reset}
                                    >
                                        Reset all filters
                                    </Btn>
                                </div>
                            </div>
                        </aside>
                    </>
                )}
            </Transition>

        </>
    );
};

export default Filter;
