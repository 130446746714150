import {Dispatcher} from "@app/com/event";
import {useCallback, useEffect, useState} from "react";

const RentMode = {
    state: false,
    dispatcher: new Dispatcher<{change: [boolean]}>(),
    listen(fn: (state: boolean) => void) {
        this.dispatcher.listen("change", fn);
    },
    change(state: boolean) {
        this.dispatcher.fire("change", state);
    },
    useEffect(set: (state: boolean) => void): void {
        useEffect(() => this.listen(set), []);
    },
};

export function useRentMode(): [editable: boolean, toggle: () => void] {
    const [editable, setState] = useState(RentMode.state);
    const toggle = useCallback(
        () => setState((prev) => {
            RentMode.change(false);

            return !prev;
        }),
        [],
    );

    RentMode.useEffect(setState);

    return [
        editable,
        toggle,
    ];
}
