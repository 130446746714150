import {useWalletAccount} from "@app/share/ethers";
import {MyOrdersTabsRental} from "@com/bar";
import {SearchProvider} from "@com/search";
import {SkeletonList, SkeletonOrderRender} from "@com/skeleton";
import {MyOrderFilterInput, OrderRequestAction, RoyaltyType} from "@query/schema";
import * as React from "react";

import {Bar} from "./com/Bar";
import {HistorySearch} from "./HistorySearch";

type Props = {
    type?: RoyaltyType;
    action: OrderRequestAction;
    tabs: typeof MyOrdersTabsRental;
};

export const SearchGroup: React.FC<Props> = ({action, tabs, type}) => {
    const {address} = useWalletAccount();
    const filters: MyOrderFilterInput = {
        address,
        action,
        type,
    };

    return (
        <SearchProvider filters={filters}>
            <Bar tabs={tabs}/>
            <div className="container">
                <SkeletonList size={12}
                    skeleton={<SkeletonOrderRender/>}
                    container={<div className="order-list" />}>
                    <HistorySearch/>
                </SkeletonList>
            </div>
        </SearchProvider>
    );
};
