import {cssx} from "@app/com/css";
import * as React from "react";
import {Link, Location, matchPath} from "react-router-dom";

import {INavSelector} from "./interfaces";

type Props = {
    location: Location;
    selector: INavSelector;
};

export const SelectorLink: React.FC<Props> = ({selector, location}) => {
    const active = selector.routes
        .map((route) => matchPath(route, location.pathname))
        .some((result) => !!result);

    return (
        <Link to={selector.path}>
            <span className={cssx({active})}>
                {selector.title}
            </span>
        </Link>
    );
};
