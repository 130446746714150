import {Footer} from "@app/share/com/Footer";
import {Loader} from "@app/share/com/Loader";
import {useEtherState, useWalletAccount} from "@app/share/ethers";
import {Notify} from "@app/share/notify";
import {PopUpContainer} from "@app/share/popup";
import {CookieAccept} from "@com/cookies/CookieAccept";
import {GoerliHead} from "@com/GoerliHead/GoerliHead";
import {SwitchNetwork} from "@pages/SwitchNetwork/SwitchNetwork";
import {useMarketConfig} from "hooks";
import * as React from "react";
import {Suspense} from "react";
import {Outlet} from "react-router-dom";

import {Header} from "./Layout/Header/Header";
import {SubHeader} from "./Layout/SubHeader";

const CheckBc: React.FC<{children: React.ReactNode | React.ReactNode[]}> = ({children}) => {
    const state = useEtherState();
    const {supportedNetworks} = useMarketConfig();
    if (!state.initialized) {
        return null;
    }

    if (!state.wallet.connected || supportedNetworks.includes(state.wallet.blockchain)) {
        return <>{children}</>;
    }

    return <SwitchNetwork/>;
};

export const Layout: React.FC = () => {
    const {connected} = useWalletAccount();

    return (
        <Suspense fallback={<Loader/>}>
            {connected && <GoerliHead/>}
            <Header/>
            <CheckBc>
                <SubHeader/>
                <Suspense fallback={<Loader/>}>
                    <Outlet/>
                </Suspense>
            </CheckBc>

            <Footer type="marketplace"/>

            <Notify/>
            <CookieAccept/>
            <PopUpContainer/>
        </Suspense>
    );
};
