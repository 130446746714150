import {Market} from "@pages/Market/Market";
import {OrderRequestAction} from "@query/schema";
import * as React from "react";

import {BuyAction} from "./Market/order/action/BuyAction";

export const Buy: React.FC = () => {
    return (
        <Market action={OrderRequestAction.Sell}>
            {(order) => <BuyAction order={order}/>}
        </Market>
    );
};
