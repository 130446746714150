import {NotFoundException} from "@app/com/errors";
import ErrorFoundView from "@app/share/com/ErrorFound";
import NotFoundView from "@app/share/com/NotFound";
import {Nullable} from "@app/types";
import * as React from "react";

export class ErrorBoundary extends React.Component<Record<string, any>, {error: unknown}> {
    public state: {error: Nullable<Error>} = {error: null};

    static getDerivedStateFromError(error: unknown): {error: unknown} {
        return {error};
    }

    public componentDidCatch(_error: unknown, _errorInfo: any): void {
        // @todo
    }

    render(): React.ReactNode {
        const {error} = this.state;

        if (error) {
            if (error instanceof NotFoundException) {
                return <NotFoundView/>;
            }

            return <ErrorFoundView/>;
        }

        return this.props.children;
    }
}
