import {capitalize} from "@app/com/fn";
import {IconArrowOrders} from "@app/share/icons";
import {MyOrdersTabsRental} from "@com/bar";
import {OrderStatusFilter} from "@com/bar/filter/OrderStatusFilter";
import {useSearch} from "@com/search";
import {SearchInput} from "@com/UI/Search";
import {Tab, Tabs} from "@com/UI/Tabs";
import {MyOrderFilterInput} from "@query/schema";
import * as React from "react";
import {useLocation} from "react-router-dom";

type Props = {
    tabs: typeof MyOrdersTabsRental;
};

export const Bar: React.FC<Props> = ({tabs}) => {
    const {pathname} = useLocation();
    const search = useSearch<MyOrderFilterInput>();
    const {filter} = search.watch();

    return (
        <div className="history-head">
            <Tabs
                className="container"
                renderTabs={() => tabs.map((tab) => (
                    <Tab
                        key={tab.path}
                        path={tab.path}
                        active={tab.path === pathname}
                        className={capitalize(tab.title)}
                    >
                        {capitalize(tab.title)}
                        <IconArrowOrders/>
                    </Tab>
                ))}
            >
                <div className="row">
                    <div />
                    <div>
                        {/* <DropdownSelect2
                            pre="Type:"
                            onChange={() => void 0}
                            options={FILTER_OPTIONS.myOrdersType}
                            mini
                        /> */}
                        <OrderStatusFilter value={filter.status} onChange={(v) => search.set("status", v)}/>
                        {/* <DropdownSelect2
                            pre="Sort by:"
                            options={SORT_OPTIONS.myOrders}
                            onChange={() => void 0}
                        /> */}
                        <SearchInput
                            name="search"
                            placeholder={"NFT name or ID"}
                            {...search.useInput("search", (v) => v)}
                        />
                    </div>
                </div>
            </Tabs>
        </div>
    );
};
