import "./scss/Tabs.scss";

import {ActiveComponent} from "@app/share/com/ActiveComponent";
import {IconArrowOrders} from "@app/share/icons";
import Btn from "@com/UI/Btn";
import {Tab, Tabs} from "@com/UI/Tabs";
import * as React from "react";
import {Link, useLocation} from "react-router-dom";

const list = [
    {
        path: "/rent",
        re: /^\/rent(\/?.*)/,
        title: "Rent",
    },
    // {
    //     path: "/lend",
    //     title: "Lend",
    //     re: /^\/lend(\/?.*)/,
    // },
];

export const Rental: React.FC = () => {
    const {pathname} = useLocation();
    const isRent = React.useMemo(() => list[0].re.test(pathname), [pathname]);

    return (
        <Tabs
            className="container"
            renderTabs={() => list.map(({path, re, title}) => (
                <Tab key={path} path={path} active={re.test(pathname)}>
                    {title}
                    <IconArrowOrders/>
                </Tab>
            ))}
        >
            <ActiveComponent component="mp:request" test={isRent}>
                <Link to="request">
                    <Btn style="primary"><i>+</i> Request</Btn>
                </Link>
            </ActiveComponent>
        </Tabs>
    );
};
