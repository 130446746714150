import {cssx} from "@app/com/css";
import {IconPercent} from "@com/assets/icons";
import * as React from "react";

import {MakerFormContext} from "../context";
import {FormProps} from "./interfaces";

type Props = FormProps & {
    title: string;
};


const validate = (value: any): boolean => (
    parseInt(value.toString()) === parseFloat(value.toString()) && value >= 10 && value <= 90
);

const setValueAs = (value: any): number => isNaN(parseFloat(value)) ? 0 : parseFloat(value);

export const LendProfit: React.FC<FormProps> = ({state}) => {
    return <ProfitDistribution title="Holder" state={state}/>;
};

export const RentProfit: React.FC<FormProps> = ({state}) => {
    return <ProfitDistribution title="Scholar" state={state}/>;
};

export const ProfitDistribution: React.FC<Props> = ({title, state}) => {
    const {form, locked} = React.useContext(MakerFormContext);
    const {register, watch, setValue, formState} = form;

    const props = register("royalty", {
        required: true,
        pattern: /^\d+$/,
        setValueAs,
        validate,
    });

    const fields = watch();
    const royalty = state?.payload.royalty ?? (formState.isValid ? fields.royalty : 10);

    return (
        <>
            <div className="item">
                <span className="label">Your profit</span>
                <div className="com-input">
                    <input
                        className={cssx({locked: !locked})}
                        min={10}
                        max={90}
                        step={1}
                        type="number"
                        readOnly
                        value={royalty}
                    />

                    <input
                        className={cssx({locked})}
                        min={10}
                        max={90}
                        step={1}
                        type="number"
                        readOnly={locked}
                        {...props}
                    />
                    <IconPercent/>
                </div>
            </div>
            <div className="item inactive">
                <span className="label">{title}</span>
                <div className="com-input">
                    <input
                        readOnly
                        type="number"
                        value={100 - royalty}
                    />
                    <IconPercent/>
                </div>
            </div>
            <input
                className="range"
                type="range"
                min={10}
                max={90}
                step={5}
                readOnly={locked}
                value={royalty}
                onChange={(e) => setValue("royalty", e.target.valueAsNumber)}
            />
        </>
    );
};
