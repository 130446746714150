import {BlockchainNetworkIcon} from "@app/com/blockchain";
import {Image} from "@app/com/image";
import {useWalletAccount} from "@app/share/ethers";
import {TokenId, TokenName} from "@com/NFT";
import {Images} from "@config";
import {OrderRequestAction} from "@query/schema";
import * as React from "react";

import {getMyProfit, getOrderStatus, OrderWithImageProps} from "./config";

export const OrderHead: React.FC<OrderWithImageProps> = ({data}) => {
    const {address} = useWalletAccount();

    return (
        <div className="col">
            <div className="img">
                <Image file={data.token.image} alt={data.token.name} size={Images.SM}/>
            </div>
            <div className="wrap">
                <div className="thumb">
                    <div className="name">
                        <span>
                            <u><TokenId token={data.token}/></u> <TokenName token={data.token}/>
                        </span>
                        <ul>
                            <li>
                                <BlockchainNetworkIcon icon network={data.token.blockchain}/>
                                <span>
                                    <BlockchainNetworkIcon
                                        icon={false}
                                        symbol
                                        network={data.token.blockchain}
                                    />
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="profit">
                    <b>{getMyProfit(address, data)}</b>
                    <span>{data.action !== OrderRequestAction.Sell && "Your profit"}</span>
                    <i className={data.status}>{getOrderStatus(data)}</i>
                </div>
            </div>
        </div>
    );
};
