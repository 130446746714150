import {BlockchainNetworkIcon} from "@app/com/blockchain";
import {Image} from "@app/com/image";
import {IconDelete} from "@app/share/icons";
import {expires} from "@com/const";
import {TokenId, TokenName} from "@com/NFT";
import {Images} from "@config";
import {NftWithOrderFragment} from "@query/schema";
import {usePopUpFlow} from "@state";
import * as React from "react";

import {MakerAtomType, MakerItemPayload, MakerUseActions} from "../interfaces";
import {PayloadForms} from "./Forms";
import {FormControl} from "./Forms/FormControl";

type Props = {
    item: NftWithOrderFragment;
    payload: MakerItemPayload;
    action: MakerUseActions;
    atom: MakerAtomType;
};

export const Element: React.FC<Props> = ({item, payload, action, atom}) => {
    const state = atom.getState();
    const control = usePopUpFlow();
    const remove = React.useCallback(
        () => {
            const state = atom.getState();
            if (state.size === 1) {
                control.close();
            }

            action.remove(item);
        },
        [item.id],
    );

    const update = React.useCallback((royalty: number) => action.add(item, {...payload, royalty}), [payload]);

    return (
        <div key={item.id} className="item">
            <div className="img">
                <Image file={item.image} alt={item.name} size={Images.SM}/>
            </div>
            <div className="inf">
                <div className="name"><TokenId token={item}/> <TokenName token={item}/></div>
                <ul className="tags">
                    <li>
                        <Image file={item.game.cover} size={150}/>
                        <pre>{item.game.title}</pre>
                    </li>
                    <li>
                        <BlockchainNetworkIcon network={item.blockchain} symbol icon />
                    </li>
                </ul>
            </div>
            <div className="time">
                <span>Duration</span>
                <ul>
                    {expires.map((expire) => (
                        <li key={expire}>
                            <input id={`expire${expire}`}
                                type="radio"
                                name={`expire-${item.id}`}
                                value={expire}
                                onChange={() => action.add(item, {...payload, expire})}
                                defaultChecked={payload.expire === expire} />
                            <label htmlFor={`expire${expire}`}>{expire}</label>
                        </li>
                    ))}
                </ul>
            </div>

            <FormControl payload={payload} update={update} form={PayloadForms[state.action]}/>
            <div className="delete" onClick={remove}>
                <IconDelete/>
            </div>
        </div>
    );
};
