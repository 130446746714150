import {SearchProvider} from "@com/search";
import {SkeletonList, SkeletonNFTItem} from "@com/skeleton";
import {OrderRequestAction} from "@query/schema";
import * as React from "react";
import {useParams} from "react-router-dom";

import {Bar} from "./com";
import {useRentSigner} from "./hooks";
import {MakerCart} from "./Maker/MakerCart";
import {MakerProgress} from "./Maker/MakerProgress";
import {MakerProvider} from "./Maker/MakerProvider";
import {NFTSearch} from "./NFTSearch";

export const Rent: React.FC = () => {
    const signer = useRentSigner();
    const {address: tokenAddress} = useParams<"address">();

    return (
        <SearchProvider filters={{tokenAddress}}>
            <Bar/>
            <MakerProvider action={OrderRequestAction.Rent}>
                <SkeletonList size={8}
                    skeleton={<SkeletonNFTItem/>}
                    container={<div className="market-list" />}>
                    <NFTSearch/>
                </SkeletonList>
                <MakerCart signer={signer}/>
                <MakerProgress/>
            </MakerProvider>
        </SearchProvider>
    );
};
