import * as React from "react";

import {lang} from "../config";

const format = new Intl.DateTimeFormat(lang, {
    hour: "2-digit",
    minute: "2-digit",
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
});

type NumberFormatProps = {
    value: number | string | Date;
};

export const DateTimeFormat: React.FC<NumberFormatProps> = ({value}) => {
    return <>{format.format(new Date(value))}</>;
};
