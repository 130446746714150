import {Market} from "@pages/Market/Market";
import {OrderRequestAction} from "@query/schema";
import * as React from "react";

import {RentLendAction} from "./Market/order/action/RentLendAction";

export const Lend: React.FC = () => {
    return (
        <Market action={OrderRequestAction.Rent}>
            {(order) => <RentLendAction order={order}/>}
        </Market>
    );
};
