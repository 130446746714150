import {Assets} from "@com/assets";
import * as React from "react";
import {useController} from "react-hook-form";

type Props = {
    placeholder?: string;
    defaultValue: string | undefined;
    control: any;
    name: string;
};

const Search: React.FC<Props> = ({placeholder, control, name}) => {
    const {field: {onChange, value}} = useController({name, control, defaultValue: ""});

    return (
        <div className="com-input">
            <input
                type={"text"}
                placeholder={placeholder ?? "Search"}
                onChange={onChange}
                value={value}
            />
            {Assets.IconSearch()}
        </div>
    );
};

export default Search;
