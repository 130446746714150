import "./CheckupImg.scss";

import {Image} from "@app/com/image";
import {FileType} from "@query/schema";
import * as React from "react";

import SkeletonImg from "../../assets/img/components/skeleton/skeleton-thumb.svg";

type CheckupImgProps = {
    size?: number;
    file?: FileType;
};

export const CheckupImg: React.FC<CheckupImgProps> = ({file, size = 200}) => {
    if (file) {
        return <Image file={file} size={size} />;
    }

    return <img className="checkup__img" src={SkeletonImg} alt="" />;
};
