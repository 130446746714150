import * as React from "react";

type CloseProps = {
    close: () => void;
};

export const CloseIcon: React.FC<CloseProps> = ({close}) => (
    <div onClick={close}>
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                // eslint-disable-next-line max-len
                d="M0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12ZM12 1.72127C9.27391 1.72127 6.65948 2.80421 4.73184 4.73184C2.80421 6.65948 1.72127 9.27391 1.72127 12C1.72127 14.7261 2.80421 17.3405 4.73184 19.2682C6.65948 21.1958 9.27391 22.2787 12 22.2787C14.7261 22.2787 17.3405 21.1958 19.2682 19.2682C21.1958 17.3405 22.2787 14.7261 22.2787 12C22.2787 9.27391 21.1958 6.65948 19.2682 4.73184C17.3405 2.80421 14.7261 1.72127 12 1.72127ZM16.2651 7.73668C16.435 7.90656 16.5304 8.13695 16.5304 8.37717C16.5304 8.61739 16.435 8.84778 16.2651 9.01767L13.281 12L16.2651 14.9823C16.4352 15.1524 16.5308 15.3832 16.5308 15.6237C16.5308 15.8643 16.4352 16.095 16.2651 16.2651C16.095 16.4352 15.8643 16.5308 15.6237 16.5308C15.3832 16.5308 15.1524 16.4352 14.9823 16.2651L12 13.281L9.01767 16.2651C8.93344 16.3494 8.83344 16.4162 8.72339 16.4618C8.61334 16.5074 8.49538 16.5308 8.37626 16.5308C8.25715 16.5308 8.13919 16.5074 8.02914 16.4618C7.91909 16.4162 7.81909 16.3494 7.73486 16.2651C7.65063 16.1809 7.58382 16.0809 7.53823 15.9709C7.49265 15.8608 7.46919 15.7429 7.46919 15.6237C7.46919 15.5046 7.49265 15.3867 7.53823 15.2766C7.58382 15.1666 7.65063 15.0666 7.73486 14.9823L10.719 12L7.73486 9.01767C7.56475 8.84756 7.46919 8.61684 7.46919 8.37626C7.46919 8.13569 7.56475 7.90497 7.73486 7.73486C7.90497 7.56475 8.13569 7.46919 8.37626 7.46919C8.61684 7.46919 8.84756 7.56475 9.01767 7.73486L12 10.719L14.9823 7.73486C15.0665 7.6505 15.1665 7.58356 15.2765 7.53789C15.3866 7.49222 15.5046 7.46871 15.6237 7.46871C15.7429 7.46871 15.8609 7.49222 15.9709 7.53789C16.081 7.58356 16.181 7.65231 16.2651 7.73668Z"/>
        </svg>
    </div>
);

export const CheckIcon: React.FC = () => (
    <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            // eslint-disable-next-line max-len
            d="M41.28 56.64L30.84 46.2C29.96 45.32 28.8608 44.9008 27.5424 44.9424C26.2208 44.9808 25.12 45.44 24.24 46.32C23.36 47.2 22.92 48.32 22.92 49.68C22.92 51.04 23.36 52.16 24.24 53.04L37.92 66.72C38.8 67.6 39.92 68.04 41.28 68.04C42.64 68.04 43.76 67.6 44.64 66.72L71.88 39.48C72.76 38.6 73.1808 37.4992 73.1424 36.1776C73.1008 34.8592 72.64 33.76 71.76 32.88C70.88 32 69.76 31.56 68.4 31.56C67.04 31.56 65.92 32 65.04 32.88L41.28 56.64ZM48 96C41.36 96 35.12 94.7392 29.28 92.2176C23.44 89.6992 18.36 86.28 14.04 81.96C9.72 77.64 6.3008 72.56 3.7824 66.72C1.2608 60.88 0 54.64 0 48C0 41.36 1.2608 35.12 3.7824 29.28C6.3008 23.44 9.72 18.36 14.04 14.04C18.36 9.72 23.44 6.2992 29.28 3.7776C35.12 1.2592 41.36 0 48 0C54.64 0 60.88 1.2592 66.72 3.7776C72.56 6.2992 77.64 9.72 81.96 14.04C86.28 18.36 89.6992 23.44 92.2176 29.28C94.7392 35.12 96 41.36 96 48C96 54.64 94.7392 60.88 92.2176 66.72C89.6992 72.56 86.28 77.64 81.96 81.96C77.64 86.28 72.56 89.6992 66.72 92.2176C60.88 94.7392 54.64 96 48 96ZM48 86.4C58.64 86.4 67.7008 82.6608 75.1824 75.1824C82.6608 67.7008 86.4 58.64 86.4 48C86.4 37.36 82.6608 28.2992 75.1824 20.8176C67.7008 13.3392 58.64 9.6 48 9.6C37.36 9.6 28.3008 13.3392 20.8224 20.8176C13.3408 28.2992 9.6 37.36 9.6 48C9.6 58.64 13.3408 67.7008 20.8224 75.1824C28.3008 82.6608 37.36 86.4 48 86.4Z" fill="url(#paint0_linear_11409_184694)"/>
        <defs>
            <linearGradient
                id="paint0_linear_11409_184694"
                x1="-17.194"
                y1="-7.31428"
                x2="44.4718"
                y2="135.399"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#3772FF"/>
                <stop offset="1" stopColor="#769FFF"/>
            </linearGradient>
        </defs>
    </svg>
);

export const ArrowDownIcon: React.FC = () => (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            // eslint-disable-next-line max-len
            d="M8.31153 10.692L12.8812 5.896C12.9574 5.81598 13 5.70966 13 5.5991C13 5.48855 12.9574 5.38223 12.8812 5.30221L12.876 5.29704C12.839 5.25812 12.7945 5.22712 12.7452 5.20594C12.6958 5.18476 12.6427 5.17383 12.589 5.17383C12.5353 5.17383 12.4822 5.18476 12.4328 5.20594C12.3835 5.22712 12.339 5.25812 12.302 5.29704L7.99914 9.81332L3.69801 5.29704C3.66102 5.25812 3.61651 5.22712 3.56717 5.20594C3.51783 5.18475 3.4647 5.17383 3.41101 5.17383C3.35731 5.17383 3.30418 5.18475 3.25484 5.20594C3.2055 5.22712 3.16099 5.25812 3.12401 5.29704L3.11884 5.30221C3.04256 5.38223 3 5.48855 3 5.5991C3 5.70966 3.04256 5.81598 3.11884 5.896L7.68847 10.692C7.72866 10.7341 7.77699 10.7677 7.83054 10.7907C7.88409 10.8136 7.94174 10.8254 8 10.8254C8.05826 10.8254 8.11591 10.8136 8.16946 10.7907C8.22301 10.7677 8.27134 10.7341 8.31153 10.692Z"/>
    </svg>
);

export const Check2Icon: React.FC = () => (
    <svg
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            // eslint-disable-next-line max-len
            d="M4.60039 7.775C4.73372 7.775 4.85872 7.75434 4.97539 7.713C5.09206 7.671 5.20039 7.6 5.30039 7.5L10.9754 1.825C11.1587 1.64167 11.2504 1.41667 11.2504 1.15C11.2504 0.883336 11.1504 0.650003 10.9504 0.450003C10.7671 0.266669 10.5337 0.175003 10.2504 0.175003C9.96706 0.175003 9.73372 0.266669 9.55039 0.450003L4.60039 5.4L2.42539 3.225C2.24206 3.04167 2.01706 2.95 1.75039 2.95C1.48372 2.95 1.25039 3.05 1.05039 3.25C0.867057 3.43334 0.775391 3.66667 0.775391 3.95C0.775391 4.23334 0.867057 4.46667 1.05039 4.65L3.90039 7.5C4.00039 7.6 4.10872 7.671 4.22539 7.713C4.34206 7.75434 4.46706 7.775 4.60039 7.775Z"/>
    </svg>
);


