import "./MarketList.scss";

import {useSearch} from "@com/search";
import * as React from "react";

import More from "./More";

type Props = {
    children: React.ReactNode;
    count: number;
};

export const MarketList: React.FC<Props> = ({children, count}) => {
    const {more, pagination} = useSearch();

    return (
        <div className="market-list">
            {children}
            <More count={count} pagination={pagination} onClick={more} />
        </div>
    );
};
