import {useOperation} from "@app/com/endpoint";
import {useNotify} from "@app/share/notify";
import {useCatalogAtom} from "@com/catalog";
import Btn from "@com/UI/Btn";
import {RemoveOrder} from "@query/config";
import * as React from "react";

type Props = {
    id: string;
};

export const RemoveOrderAction: React.FC<Props> = ({id}) => {
    const [state, {useCallback}] = useOperation();
    const {triggerWipe} = useCatalogAtom("myOrder");
    const notify = useNotify();
    const handle = useCallback(
        async () => {
            RemoveOrder.mutate({id}).then(() => triggerWipe());
        },
        (reason) => notify(reason),
    );

    return (
        <Btn style="border" loading={state.pending} onClick={handle}>Remove order</Btn>
    );
};
