import * as React from "react";
import {Route, Routes} from "react-router-dom";

import {Rental} from "./SubHeader/Rental";

export const SubHeader: React.FC = () => {
    return (
        <Routes>
            <Route path="rent/*" element={<Rental/>} />
            <Route path="lend/*" element={<Rental/>} />
            {/* <Route path="buy/*" element={<Buy/>} /> */}
        </Routes>
    );
};
