const disabledComponents = CLIENT_DISABLED_COMPONENTS
    .split(",")
    .map((s) => s.trim());

export function isDisabledComponent(component: string): boolean {
    return disabledComponents.includes(component);
}

export function isEnabledComponent(component: string): boolean {
    return !isDisabledComponent(component);
}
