import "./Message.scss";

import * as React from "react";

import Btn from "../UI/Btn";

type Props = {
    children: React.ReactNode;
    btnText?: string;
    onClick?: any;
};

export const Message: React.FC<Props> = ({btnText, children, onClick}) => {
    return (
        <div className="com-message">
            <div className="content">
                <span>{children}</span>
                {!!btnText && <Btn onClick={onClick} style={"mini button-order"}>{btnText}</Btn>}
            </div>
        </div>
    );
};
