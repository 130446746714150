import {useWalletAccount} from "@app/share/ethers";
import {CreateOrder} from "@query/config";
import {OrderRequestAction} from "@query/schema";

import {MakerStoreValue} from "../interfaces";
import {IOrderSigner} from "./interfaces";

export type ERC721MakeOrder = (action: OrderRequestAction, value: MakerStoreValue[]) => AsyncGenerator<MakerStoreValue>;

export function useERC721OrderRequest(request: IOrderSigner): ERC721MakeOrder {
    const {blockchain, address} = useWalletAccount();

    return async function *handle(action: OrderRequestAction, values: MakerStoreValue[]) {
        for (const value of values) {
            const {item, payload: {royalty, expire}} = value;
            const {agreement, hash} = await request.sign(value);
            await CreateOrder.mutate({payload: {
                expire,
                action,
                blockchain,
                tokenAddress: item.tokenAddress,
                tokenId: item.tokenId,
                maker: {
                    address,
                    royalty,
                    agreement,
                },
                hash,
            }});

            yield value;
        }
    };
}
