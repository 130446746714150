import {Filter} from "@com/bar/filter";
import {BlockchainFilter} from "@com/bar/filter/BlockchainFilter";
import {useSearchContext} from "@com/search";
import {SearchInput} from "@com/UI/Search";
import {NftFilterInput} from "@query/schema";
import * as React from "react";
import {useParams} from "react-router-dom";

type Props = {
    isMint?: any;
};

export const Bar: React.FC<Props> = () => {
    const search = useSearchContext<NftFilterInput>();
    const {address} = useParams<"address">();
    const {filter} = search.watch();

    return (
        <div className="market-head container">
            <div className="row">
                <Filter reset={search.reset}>
                    {!address && <BlockchainFilter value={filter.blockchain}
                        onChange={(value) => search.set("blockchain", value)}/>}
                </Filter>
                <div>
                    <SearchInput
                        placeholder={"NFT name or ID"}
                        {...search.useInput("search", (v) => v)}
                    />
                </div>
            </div>

        </div>
    );
};
