import {cssx} from "@app/com/css";
import * as React from "react";
import {useController} from "react-hook-form";

import {SelectProps} from "./interfaces";

export const RadioSelect: React.FC<SelectProps> = ({options, name, control, defaultValue}) => {
    const [active, setActive] = React.useState(0);

    const {field: {onChange}} = useController({name, control});
    React.useEffect(() => {
        const activeIndex = options.findIndex((opt) => opt.value === defaultValue);
        setActive(activeIndex >= 0 ? activeIndex : 0);
        onChange(defaultValue);
    }, [defaultValue]);

    return (
        <div className="com-radio">
            {options.map((opt, i) => {
                return (
                    <div
                        key={`${i}_key_${opt}`}
                        className="item"
                        onClick={() => onChange(opt.value)}
                    >
                        <span className={cssx({active: active === i})}>
                            {opt.name}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};
