import * as React from "react";

import {ElementProps, Nullable} from "../interfaces";
import {getImageSrcSet} from "./base";
import {ImageFile, ImageSizeProp} from "./interfaces";

export type ImageProps = ElementProps<"img"> & {
    size: ImageSizeProp;
    file: Nullable<ImageFile>;
};

export const Image: React.FC<ImageProps> = ({size, file, ...props}) => {
    const {src, srcSet} = getImageSrcSet(file, size);

    return <img {...props} src={src} srcSet={srcSet}/>;
};
