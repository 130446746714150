import {cssm} from "@app/com/css";
import {useAtomState} from "@app/com/hooks";
import * as React from "react";
import {useLocation} from "react-router-dom";
import {Transition} from "react-transition-group";

import {Overlay} from "./Overlay";
import {PopUpAtom, usePopUpFlow} from "./PopUpAtom";

export const PopUpContainer: React.FC = () => {
    const node = React.useRef(null);
    const popup = useAtomState(PopUpAtom);
    const {close} = usePopUpFlow();
    const {pathname} = useLocation();
    React.useEffect(() => () => close(), [pathname]);

    return (
        <Transition
            timeout={popup.timeout}
            in={popup.active}
            nodeRef={node}>
            {(state) => (
                <>
                    <Overlay overlayState={state} onClick={close}/>
                    <aside className={cssm("popup active", state, popup.type)} ref={node}>
                        {popup.node}
                    </aside>
                </>
            )}
        </Transition>
    );
};
