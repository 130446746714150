import * as React from "react";
import TagManager from "react-gtm-module";

import {isDisabledComponent} from "../fn";

const ComponentName = "GTM";

type Props = {
    counter?: {
        code?: string;
        status?: boolean;
    };
};

export const GoogleTagManager: React.FC<Props> = ({counter}) => {
    React.useEffect(
        () => {
            if(isDisabledComponent(ComponentName)) {
                return;
            }

            if (counter && counter.status && counter.code) {
                TagManager.initialize({gtmId: counter.code});
            }
        },
        [counter?.code, counter?.status],
    );

    return null;
};
